import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-hour-picker',
  templateUrl: './hour-picker.component.html',
  styleUrls: ['./hour-picker.component.scss']
})
export class HourPickerComponent implements OnInit {
  selectedHour: any;
  selectedMinute: any;
  selectedZone: any;
  timeHour: any = [];
  timeMinutes: any = [];
  timeZone: any = ['AM', 'PM'];
  time: any;
  afterNoon: boolean = false;
  // step:number = 15;
  @ViewChild('customTip', { static: false }) menu: ElementRef;
  @Output() onTimePicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateSelected: string;
  @Input() restrictFuture: boolean;
  @Input() startingHour:number;
  @Input() step: number;
  constructor(
    private renderer: Renderer2,
  ) {

  }

  ngOnInit() {
    if(!this.step){
      this.step = 1;
    }
    if(!this.startingHour){
      this.startingHour = 1;
    }
    for (let i = this.startingHour; i <= 23; i++) {
      this.timeHour.push(i);
    }
    for (let i = 0; i < 60; i += this.step) {
      this.timeMinutes.push(i);
    }

  }

  isTodaysDate() {
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let placeDate = new Date(this.dateSelected);
    return ((placeDate.getDate() === currentTime.getDate())
      && (placeDate.getMonth() === currentTime.getMonth())
      && (placeDate.getFullYear() === currentTime.getFullYear()));
  }

  selectHour(hour) {
    this.selectedHour = hour;
    this.checkClose();
  }


  submitTime() {
    this.time = {
      hour: this.selectedHour
    };
    this.onTimePicked.emit(this.time);
  }

  checkClose() {
    if ((this.selectedHour || this.selectedHour == 0)) {
      setTimeout(() => {
        this.submitTime();
      }, 150);
    }
  }

}
