import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private snackbarSubject = new Subject<any>();
  public snackbarState = this.snackbarSubject.asObservable();

  // constructor(public snackBar: MatSnackBar) { }

  showErrorSnackBar(message:string, action?:string) {
    this.snackbarSubject.next({
      showSnackbar: true,
      message: message,
      type: 'error'
    });
  }
  showSuccessSnackBar(message:string, action?:string) {
    this.snackbarSubject.next({
      showSnackbar: true,
      message: message,
      type: 'success'
    });
  }

  showWarningSnackBar(message:string, action?:string) {
    this.snackbarSubject.next({
      showSnackbar: true,
      message: message,
      type: 'warning'
    });
  }
  
  showAlertInfoSnackBar(message:string, action?:string) {
    this.snackbarSubject.next({
      showSnackbar: true,
      message: message,
      type: 'info-warning'
    });
  }

  showInfoSnackBar(message:string, action?:string) {
    this.snackbarSubject.next({
      showSnackbar: true,
      message: message,
      type: 'information'
    });
  }


}
