<div class="customToolTip">
    <div  class="toolTipView">
        <div class="time-selector">
            <div class="hour">
                <ul class="list">
                    <li 
                        class="list-li"
                        (click)="selectHour(hour)"
                        [ngClass]="{'active': selectedHour === hour}" 
                        *ngFor="let hour of timeHour">
                        {{hour}}
                    </li>
                </ul>
            </div>
           
           
        </div>
        <!-- <div class="submit-button">
            <button 
                [disabled]="!(selectedHour >=0 && selectedMinute>=0 && selectedZone)"
                (click)="submitTime()" class="btn-primary">
                Done  
            </button>
        </div>  -->
    </div>
</div>