import {
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
    name: 'timeHHtohh'
})

export class TimeHHtohh implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';

        let [hours, minutes, seconds] = value.split(':').map(Number);

        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12 AM/PM

        const formattedTime = `${this.padZero(hours)}:${this.padZero(minutes)} ${period}`;
        return formattedTime;
    }

    private padZero(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }
} 