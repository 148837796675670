import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import mapboxgl from 'mapbox-gl';
import { forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdminService } from 'src/app/admin/admin.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { UtilService } from '../utils.service';

@Component({
  selector: 'app-rfq-map-view',
  templateUrl: './rfq-map-view.component.html',
  styleUrls: ['./rfq-map-view.component.scss']
})
export class RfqMapViewComponent implements OnInit {

  orgId: any;
  coloursArray = [];
  filterForm: any;
  origins: any;
  clusterDestinationLanes: any;
  // filters
  showClusterFilter: boolean = false;
  allFilterClusters: any = [];
  showOriginFilter: boolean = false;
  allFilterOrigins: any = [];
  legends:any = [];

  @Input() mapData: any;
  @Input() mapLevel: any;
  @Input() mfgOrgId: any;

  // style = 'mapbox://styles/mapbox/streets-v11';
  style = 'mapbox://styles/sandy-ff/cl4zfbb8j000u14pdr73afvww';
  mapCenter: any = [82.9629, 22.5937];

  //Map Definations
  map: mapboxgl.Map;

  constructor(
    private adminService: AdminService,
    public AuthenticateService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
  ) {
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set(environment.mapBoxApi);
  }

  ngOnInit(): void { }

  ngOnChanges() {
    this.coloursArray = this.utilService.colorPallets;
    this.filterForm = this._formBuilder.group({
      clustersName: [['All Clusters']],
      clusters: [[]],
      originsName: [['All Groups']],
      origins: [[]],
    });
    let user = this.AuthenticateService.getUser();
    if(this.mfgOrgId){
      this.orgId = this.mfgOrgId;
    }else{
      this.orgId = !!user ? user.organization.id : null;
    }
    if (this.mapLevel == 'GROUP') {
      this.patchGroupClusterData();
    } else {
      this.patchRfqData();
    }
  }

  getClusterDestinationLanes(routes, mapLevel, adminService, orgId) {
    return new Promise(resolve => {
      let callArray = [];
      if (mapLevel == 'GROUP') {
        this.allFilterClusters = [];
      }
      for (let [index, route] of routes.entries()) {
        let laneLabel = 'Cluster-' + route.properties.label;
        if (mapLevel == 'GROUP') {
          let originData = {
            key: laneLabel,
            value: laneLabel,
            isSelected: true
          };
          this.allFilterClusters.push(originData);
        }
        let payload = {
          "pageSize": 1000,
          "pageIndex": 1,
          "filters": {
            "laneLabel": [laneLabel]
          }
        }
        callArray.push(adminService.getGroupLane(orgId, payload));
      }
      forkJoin(callArray)
        .pipe(
          catchError((error: any) => {
            resolve(false);
            return throwError(error);
          }),
        )
        .subscribe(resp => {
          resolve(resp);
        });
    });
  }

  filterAction(event, filterType) {
    if (event.applyFilter) {
      if (filterType == 'CLUSTERS') {
        this.allFilterClusters = JSON.parse(JSON.stringify(event.filters));
        let items = [];
        for (let item of this.allFilterClusters) {
          if (item.isSelected) {
            items.push(item.value);
          }
        }
        this.filterForm.get('clusters').patchValue(items);
        switch (items.length) {
          case 0:
          case this.allFilterClusters.length:
            this.filterForm.get('clustersName').patchValue(`All Clusters`);
            break;
          default:
            this.filterForm.get('clustersName').patchValue(`${items.length > 1 ? items.length + ' Clusters' : items[0]}`);
            break;
        }
        this.showClusterFilter = false;
        this.filterGroupClusterMapData();
      }
      if (filterType == 'ORIGINS') {
        this.allFilterOrigins = JSON.parse(JSON.stringify(event.filters));
        let items = [];
        for (let item of this.allFilterOrigins) {
          if (item.isSelected) {
            items.push(item.value);
          }
        }
        this.filterForm.get('origins').patchValue(items);
        switch (items.length) {
          case 0:
          case this.allFilterOrigins.length:
            this.filterForm.get('originsName').patchValue(`All Groups`);
            break;
          default:
            this.filterForm.get('originsName').patchValue(`${items.length > 1 ? items.length + ' Groups' : items[0]}`);
            break;
        }
        this.showOriginFilter = false;
        this.filterRfqMapData();
      }
    } else {
      this.showOriginFilter = false;
      this.showClusterFilter = false;
    }
  }

  async patchGroupClusterData() {
    let laneData = this.mapData['rfqLanes'];
    let origins = [];
    let originClusterRoutes = [];
    for (let lane of laneData) {
      let originCoordinates = [parseFloat(lane['originLongitude']), parseFloat(lane['originLatitude'])];
      let destinationCoordinates = [parseFloat(lane['destinationLongitude']), parseFloat(lane['destinationLatitude'])];
      origins.push({
        'type': 'Feature',
        'properties': {
          'name': lane['originCity'],
          'icon': 'circle',
          'circle_color': '#219653' //green
        },
        'geometry': {
          'type': 'Point',
          'coordinates': originCoordinates
        }
      });
      originClusterRoutes.push({
        'type': 'Feature',
        'properties': {
          'color': '#17709C',
          'width': 1,
          'label': lane['originCity'] + '-' + lane['destinationCity'],
        },
        'geometry': {
          'type': 'LineString',
          'coordinates': [originCoordinates, destinationCoordinates]
        }
      });
    }
    this.origins = [...new Map(origins.map(origin => [origin['properties']['name'], origin])).values()];
    this.mapCenter = this.origins[0]['geometry']['coordinates'];
    // get Cluster Destinations
    this.clusterDestinationLanes = await this.getClusterDestinationLanes(originClusterRoutes, this.mapLevel, this.adminService, this.orgId);
    this.filterGroupClusterMapData();
  }

  async filterGroupClusterMapData() {
    let colorsLength = this.coloursArray.length;
    let selectedCluster = this.filterForm.get('clusters').value;
    let destinations = [];
    let routes = [];
    let legends = [];
    let colourIndex = 0;
    for (let [index, cluster] of this.clusterDestinationLanes.entries()) {
      if(cluster['results'].length > 0){
        let laneLabel = '';
        if (cluster['results'][0]) {
          for(let llb of cluster['results'][0]['laneLabelList']){
            if(llb['name'].includes('Cluster')){
              laneLabel = llb['name'];
            }
          }
        }
        if (selectedCluster.length == 0 || (laneLabel.length > 0 && selectedCluster.includes(laneLabel))) {
          if (colourIndex > colorsLength) {
            colourIndex = 0;
          }
          for (let lane of cluster['results']) {
            let originCoordinates = [parseFloat(lane['originCity']['longitude']), parseFloat(lane['originCity']['latitude'])];
            let destinationCoordinates = [parseFloat(lane['destinationCity']['longitude']), parseFloat(lane['destinationCity']['latitude'])];
            destinations.push({
              'type': 'Feature',
              'properties': {
                'name': lane['destinationCity']['name'],
                'icon': 'circle',
                'circle_color': this.coloursArray[colourIndex],
              },
              'geometry': {
                'type': 'Point',
                'coordinates': destinationCoordinates
              }
            });
            // making Clusters Legends
            legends.push({
              title: lane['laneLabelList'][0]['name'],
              color: this.coloursArray[colourIndex]
            });
            let clusterLabel = '';
            let clusterLabelArr = lane['laneLabelList'][0]['name'].split('-');
            if(clusterLabelArr.length){
              clusterLabel = clusterLabelArr[1]+'-'+clusterLabelArr[2];
            }
            routes.push({
              'type': 'Feature',
              'properties': {
                'color': this.coloursArray[colourIndex],
                'width': 1,
                'label': lane['originCity']['name'] + '-' + lane['destinationCity']['name'],
                'subLabel': clusterLabel
              },
              'geometry': {
                'type': 'LineString',
                'coordinates': [originCoordinates, destinationCoordinates]
              }
            });
          }
          colourIndex += 1;
        }
      }
    }
    this.legends = [...new Map(legends.map(legend => [legend['title'], legend])).values()];
    this.renderMap(this.origins, destinations, routes);
  }

  patchRfqData() {
    for (let group of this.mapData) {
      let groupName = group['name'];
      let originFilterData = {
        key: groupName,
        value: groupName,
        isSelected: true
      };
      this.allFilterOrigins.push(originFilterData);
    }
    this.filterRfqMapData();
  }

  filterRfqMapData() {
    let colorsLength = this.coloursArray.length;
    let selectedOrigins = this.filterForm.get('origins').value;
    let origins: any = [];
    let destinations: any = [];
    let legends: any = [];
    let originClusterRoutes: any = [];
    let colourIndex = 0;
    for (let group of this.mapData) {
      if (colourIndex > colorsLength) {
        colourIndex = 0;
      }
      if (selectedOrigins.length == 0 || selectedOrigins.includes(group['name'])) {
        for (let lane of group['rfqLanes']) {
          let originCoordinates = [parseFloat(lane['originLongitude']), parseFloat(lane['originLatitude'])];
          let destinationCoordinates = [parseFloat(lane['destinationLongitude']), parseFloat(lane['destinationLatitude'])];
          origins.push({
            'type': 'Feature',
            'properties': {
              'name': lane['originCity'],
              'icon': 'circle',
              'circle_color': '#219653' //green
            },
            'geometry': {
              'type': 'Point',
              'coordinates': originCoordinates
            }
          });
          destinations.push({
            'type': 'Feature',
            'properties': {
              'name': lane['destinationCity'],
              'label': lane['originCity'] + '-' + lane['destinationCity'],
              'icon': 'circle',
              'circle_color': this.coloursArray[colourIndex],
            },
            'geometry': {
              'type': 'Point',
              'coordinates': destinationCoordinates
            }
          });
          // making Clusters Legends
          legends.push({
            title: group['name'],
            color: this.coloursArray[colourIndex]
          });
          originClusterRoutes.push({
            'type': 'Feature',
            'properties': {
              'color': this.coloursArray[colourIndex],
              'width': 1,
              'label': lane['originCity'] + '-' + lane['destinationCity'],
              'subLabel': group['name']
            },
            'geometry': {
              'type': 'LineString',
              'coordinates': [originCoordinates, destinationCoordinates]
            }
          });
        }
      }
      colourIndex += 1;
    }
    origins = [...new Map(origins.map(origin => [origin['properties']['name'], origin])).values()];
    if(origins.length == 1){
      this.mapCenter = origins[0]['geometry']['coordinates'];
    }else{
      this.mapCenter = [82.9629, 22.5937];
    }
    this.legends = [...new Map(legends.map(legend => [legend['title'], legend])).values()];
    this.renderMap(origins, destinations, originClusterRoutes);
  }

  renderMap(origins, destinations, routes) {
    let originSource: any = {
      'type': 'FeatureCollection',
      'features': origins
    };
    let destinationSource: any = {
      'type': 'FeatureCollection',
      'features': destinations
    };
    let routeSource: any = {
      'type': 'FeatureCollection',
      'features': routes
    };
    // reset Map
    if (this.map) {
      this.map.remove();
    }
    // setup Map
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      minZoom: 3.5,
      maxZoom: 14,
      zoom: 4,
      center: this.mapCenter
    });

    let getClusterDestinationLanes = this.getClusterDestinationLanes;
    let adminService = this.adminService;
    let orgId = this.orgId;
    let mapLevel = this.mapLevel;
    let map = this.map;
    map.on('load', () => {
      map.resize();
      map.addSource('origins', {
        'type': 'geojson',
        'data': originSource
      });
      map.addSource('destinations', {
        'type': 'geojson',
        'data': destinationSource
      });
      map.addSource('routes', {
        'type': 'geojson',
        'data': routeSource
      });
      map.addLayer({
        'id': 'routes',
        'source': 'routes',
        'type': 'line',
        'layout': {
          'line-cap': 'round',
          'line-join': 'round',
        },
        'paint': {
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0.5,
            5, 0.75,
            6, 1.5,
            7, 2,
          ],
          'line-color': ['get', 'color']
        }
      });
      map.addLayer({
        'id': 'destinations',
        'source': 'destinations',
        'type': 'circle',
        'paint': {
          'circle-color': ['get', 'circle_color'],
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 4,
            5, 4,
            6, 4,
            8, 4,
            9, 5,
          ],
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#ffffff'
        }
      });
      map.addLayer({
        'id': 'destinations-name',
        'type': 'symbol',
        'source': 'destinations',
        'layout': {
          'text-field': [
            'format',
            ['upcase', ['get', 'name']],
            { 'font-scale': 1 },
          ],
          'text-justify': 'left',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0,
            5, 0,
            6, 12,
            7, 12,
            8, 12,
            9, 12,
          ],
          'text-anchor': 'top',
          'text-offset': [2.5, 0.65],
          "text-allow-overlap": true
        },
        'paint': {
          'text-color': '#17709C',
          'text-halo-color': '#ffffff',
          'text-halo-width': 2
        },
      });
      map.addLayer({
        'id': 'origins',
        'source': 'origins',
        'type': 'circle',
        'paint': {
          'circle-color': ['get', 'circle_color'],
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 5,
            5, 5,
            6, 5,
            8, 6,
            9, 8,
          ],
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#ffffff'
        }
      });
      // popup Defination
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });
      // Lane Pop Up
      let subLevelText = mapLevel == 'GROUP' ? 'Cluster' : 'Group';
      map.on('mouseenter', 'routes', function (e) {
        this.getCanvas().style.cursor = 'pointer'
        let laneLabel = e.features[0].properties['label'];
        let subLabel = e.features[0].properties['subLabel'];
        let description =
          `<div style="display: flex; align-items: flex-end; margin: 0 0 5px 0;">`
          + `<h6 style="font-size: 12px; font-weight:normal; margin: 0;">Lane: </h6>`
          + `<h6 style="font-size: 13px; font-weight:bold; margin: 0 0 0 5px;">${laneLabel}</h6></div>`
          + `<div style="display: flex; align-items: flex-end; margin: 0 0 0 0;">`
          + `<h6 style="font-size: 12px; font-weight:normal; margin: 0;">${subLevelText}: </h6>`
          + `<h6 style="font-size: 13px; font-weight:bold; margin: 0 0 0 5px;">${subLabel}</h6></div>`;

        popup.setLngLat(e.lngLat)
          .setMaxWidth('none')
          .setHTML(`${description}\n`)
          .addTo(map);
      });

      map.on('mouseleave', 'routes', function (e) {
        this.getCanvas().style.cursor = '';
        popup.remove();
      });

      if (mapLevel == 'RFQ') {
        // destination Click Event
        map.on('click', 'destinations', async (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['destinations']
          });
          const cluster = [features[0]];
          let clusterDestinationLanes = await getClusterDestinationLanes(cluster, mapLevel, adminService, orgId);
          mapClusterPolygon(clusterDestinationLanes[0]);
        });
      }

      function mapClusterPolygon(clusterLanes) {
        let clusterDestinations:any = [];
        let clusterDestinationCoordinates: any = [];
        let leftPoint:any;
        let rightPoint:any;
        let topPoint:any;
        let bottomPoint:any;
        for (let [index, lane] of clusterLanes['results'].entries()) {
          let lat = parseFloat(lane['destinationCity']['latitude']);
          let long = parseFloat(lane['destinationCity']['longitude']);
          if(index == 0){
            leftPoint = [long, lat];
            rightPoint = [long, lat];
            topPoint = [long, lat];
            bottomPoint = [long, lat];
          }else{
            if(long < leftPoint[0]){
              leftPoint = [long, lat];
            }
            if(long > rightPoint[0] ){
              rightPoint = [long, lat];
            }
            if(lat > topPoint[1] ){
              topPoint = [long, lat];
            }
            if(lat < bottomPoint[1] ){
              bottomPoint = [long, lat];
            }
          }
          clusterDestinations.push({
            'type': 'Feature',
            'properties': {
              'name': lane['destinationCity']['name'],
              'icon': 'circle',
              'circle_color': '#2196F3',
            },
            'geometry': {
              'type': 'Point',
              'coordinates': [long, lat]
            }
          });
          clusterDestinationCoordinates.push([long, lat]);
        }
        let centerCoordinates = [ (leftPoint[0]+rightPoint[0])/2 , (topPoint[1]+bottomPoint[1])/2 ];
        let maxDistance = 0;
        for(let coordinates of clusterDestinationCoordinates){
          let distance = getDistanceBetweenTwoPoints({lon: centerCoordinates[0], lat: centerCoordinates[1]},{lon: coordinates[0], lat: coordinates[1]});
          if(distance > maxDistance){
            maxDistance = parseInt(distance.toFixed(0));
          }
        }
        let polySource: any = createGeoJSONCircle(centerCoordinates, maxDistance);
        let clusterPolySourceName = 'clusterPolygon';
        let clusterPolyLayerName = 'clusterPolygon';
        let clusterDestSourceName = 'clusterDestinations';
        let clusterDestLayerName = 'clusterDestinations';
        let clusterDestNameLayerName = 'clusterDestinationsName';
        if (map.getLayer(clusterPolyLayerName)) {
          map.removeLayer(clusterPolyLayerName);
        }
        if (map.getSource(clusterPolySourceName)) {
          map.removeSource(clusterPolySourceName);
        }
        if (map.getLayer(clusterDestLayerName)) {
          map.removeLayer(clusterDestLayerName);
          map.removeLayer(clusterDestNameLayerName);
        }
        if (map.getSource(clusterDestSourceName)) {
          map.removeSource(clusterDestSourceName);
        }
        map.addSource(clusterPolySourceName, polySource);
        map.addLayer({
            "id": clusterPolyLayerName,
            "type": "fill",
            "source": clusterPolySourceName,
            "layout": {},
            "paint": {
                "fill-color": "#17709C",
                "fill-opacity": 0.2,
                "fill-outline-color": '#ffffff'
            }
        });
        let clusterDestinationSource: any = {
          'type': 'FeatureCollection',
          'features': clusterDestinations
        };
        map.addSource(clusterDestSourceName, {
          'type': 'geojson',
          'data': clusterDestinationSource
        });
        map.addLayer({
          'id': clusterDestLayerName,
          'source': clusterDestSourceName,
          'type': 'circle',
          'paint': {
            'circle-color': ['get', 'circle_color'],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              4, 4,
              5, 4,
              6, 4,
              8, 4,
              9, 5,
            ],
            'circle-stroke-width': 0.5,
            'circle-stroke-color': '#ffffff'
          }
        });
        map.addLayer({
          'id': clusterDestNameLayerName,
          'type': 'symbol',
          'source': clusterDestSourceName,
          'layout': {
            'text-field': [
              'format',
              ['upcase', ['get', 'name']],
              { 'font-scale': 1 },
            ],
            'text-justify': 'left',
            'text-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              4, 0,
              5, 0,
              6, 12,
              7, 12,
              8, 12,
              9, 12,
            ],
            'text-anchor': 'top',
            'text-offset': [2.5, 0.65],
            "text-allow-overlap": true
          },
          'paint': {
            'text-color': '#17709C',
            'text-halo-color': '#ffffff',
            'text-halo-width': 2
          },
        });
      }

      function createGeoJSONCircle(center, radiusInKm) {
        let points = 64;
    
        var coords = {
            latitude: center[1],
            longitude: center[0]
        };
    
        var km = radiusInKm;
    
        var ret = [];
        var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
        var distanceY = km/110.574;
    
        var theta, x, y;
        for(var i=0; i<points; i++) {
            theta = (i/points)*(2*Math.PI);
            x = distanceX*Math.cos(theta);
            y = distanceY*Math.sin(theta);
    
            ret.push([coords.longitude+x, coords.latitude+y]);
        }
        ret.push(ret[0]);
    
        return {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [ret]
                    }
                }]
            }
        };
    };

      function getDistanceBetweenTwoPoints(cord1, cord2) {
        if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
          return 0;
        }
      
        const radlat1 = (Math.PI * cord1.lat) / 180;
        const radlat2 = (Math.PI * cord2.lat) / 180;
      
        const theta = cord1.lon - cord2.lon;
        const radtheta = (Math.PI * theta) / 180;
      
        let dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      
        if (dist > 1) {
          dist = 1;
        }
      
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344; //convert miles to km
        
        return dist;
      }

    });
  }

}
