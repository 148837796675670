import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

//const SecureStorage = require('secure-web-storage');
const SECRET_KEY = 'blacktrunk@4321';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public setItem(key, value, expiryInSec) {
    let obj = {};
    let time = new Date().getTime();
    obj["data"] = value;
    obj["time"] = time
    if (expiryInSec) {
      obj["expiry"] = time + (expiryInSec * 1000);
    }
    localStorage.setItem(key, this.encrypt(JSON.stringify(obj)));
  }

  public  setItemSession(key, value, expiryInSec) {
    let obj = {};
    let time = new Date().getTime();
    obj["data"] = value;
    obj["time"] = time
    if (expiryInSec) {
      obj["expiry"] = time + (expiryInSec * 1000);
    }
    sessionStorage.setItem(key, this.encrypt(JSON.stringify(obj)));
  }

  public getItem(key, withTime) {
    let obj = this.decrypt(localStorage.getItem(key));
    let isExpired = false;
    let result = null;
    if (obj) { 
      try {
        obj = JSON.parse(obj);
      } catch(e) {
        isExpired = true;
        obj = null;
      }
      if (obj && obj.expiry) {
        isExpired = (obj.expiry - ((new Date().getTime())) < 0);
      }

      if (isExpired) {
        this.removeItem(key);
        obj = null;
      }
      if (withTime && obj) {
        result = {
          time: obj.time,
          value: obj.data
        };
      } else if (obj) {
        result = obj.data;
      }
    } else {
      this.removeItem(key);
    }
    return result;
  }

  public removeItem(key) {
    localStorage.removeItem(key);
  }

  public removeSessionItem(key) {
    sessionStorage.removeItem(key);
  }

  getSessionItem(key, withTime) {
    let obj = this.decrypt(sessionStorage.getItem(key));
    let isExpired = false;
    let result = null;
    if (obj) { 
      try {
        obj = JSON.parse(obj);
      } catch(e) {
        isExpired = true;
        obj = null;
      }
      if (obj && obj.expiry) {
        isExpired = (obj.expiry - ((new Date().getTime())) < 0);
      }

      if (isExpired) {
        this.removeItem(key);
        obj = null;
      }
      if (withTime && obj) {
        result = {
          time: obj.time,
          value: obj.data
        };
      } else if (obj) {
        result = obj.data;
      }
    } else {
      this.removeItem(key);
    }
    return result;
  }

  encrypt = (data) => {
    if(SECRET_KEY) {
      data = CryptoES.AES.encrypt(data, SECRET_KEY);
    }
    data = data.toString();
    return data;
  }

  // Decrypt the encrypted data
  decrypt = (data) => {
    if(null != data) {
      if(SECRET_KEY) {
        try {
          data = CryptoES.AES.decrypt(data, SECRET_KEY);
          data = data.toString(CryptoES.enc.Utf8);
        } catch (e) {
          data = null;
        }
      }
    }
    return data;
  }
}
