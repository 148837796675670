<div class="dialog-form">
    <div class="ff_dialog_box">
        <div class="ff_header">
            <div class="ff_flex ff_align_center">
                <div class="ff_title">
                    {{ header }}
                </div>
            </div>
            <img src="/assets/images/cross-button.svg" alt="Close" (click)="close()" class="ff_close_icon">
        </div>
    </div>
        <div class="table-content ff-form-theme">
            <p class="sub-head-text">{{detailText}}</p>
        </div>
        <div class="action-btn">
            <button class="ff_button_32_transparent" (click)="close()">{{secondaryButtonText}}</button>
            <button class="ff_button_32-80 ff_leftM_12"
                (click)="submitForm()">{{submitButtonText}}</button>
        </div>
</div>