import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../storage.service';

declare var $: any;

@Injectable()
export class EnsureAuthenticatedService implements CanActivate {
  currentUrl: any;
  constructor(private router: Router, private storage: StorageService) {
    this.currentUrl = window.location.href;
  }
  canActivate(): boolean {
    if (this.storage.getItem('access-token', false)) {
      return true;
    }
    else {
      if (this.currentUrl.toString().includes("event/closed-bids")) {
        this.router.navigate(['/login'], { state: { downDocLink: this.currentUrl } });
      } else {
        this.router.navigate(['/login'], { state: { downDocLink: this.currentUrl } });
        // this.router.navigateByUrl('/login');
      }
      return false;
    }
  }
}