import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../utils.service';

@Component({
  selector: 'app-combined-filter',
  templateUrl: './combined-filter.component.html',
  styleUrls: ['./combined-filter.component.scss']
})
export class CombinedFilterComponent implements OnInit {

  filterController:any = [];

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() singleSelectFilters:any;
  @Input() multiSelectFilters:any;
  @Input() dateFilters:any;
  showDateFilter:boolean = false;
  maxDate:any = new Date();

  constructor(
    private utilSerivce: UtilService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    for(let filter of this.multiSelectFilters){
      let viewController = {
        "name": this.getFilterName(filter['heading'], filter['dataList']),
        "isInFocus": false
      }
      this.filterController.push(viewController);
    }
    if(this.dateFilters && this.dateFilters['fromDate']){
      this.showDateFilter = true;
    }
  }

  applyFilter(){
    let filterValues = {
      "action": "APPLY_FILTER",
      "singleDataList": this.singleSelectFilters,
      "multiDataList": this.multiSelectFilters,
    };
    if(this.showDateFilter){
      filterValues["dateFilterList"] = {
        fromDate: this.utilSerivce.formatDateyyyymmdd(this.dateFilters['fromDate']),
        toDate: this.utilSerivce.formatDateyyyymmdd(this.dateFilters['toDate'])
      }  
    }
    this.action.emit(filterValues);
  }

  cancelFilter(){
    let filterValues = {
      "action": "CANCEL_FILTER",
    };
    this.action.emit(filterValues);
  }

  setFocusStateTrue(index){
    if(this.filterController[index]['isInFocus'] == true){
      this.filterController[index]['isInFocus'] = false;
    }else{
      this.filterController.forEach(ctrl => ctrl['isInFocus'] = false);
      this.filterController[index]['isInFocus'] = true;
    }
  }

  filterAction(event, index) {
    if (event.applyFilter) {
      this.multiSelectFilters[index]['dataList'] =  JSON.parse(JSON.stringify(event.filters));
      let filter = this.multiSelectFilters[index];
      this.filterController[index]['name'] = this.getFilterName(filter['heading'], filter['dataList']);
    }
  }

  getFilterName(heading, dataList){
    let count = 0;
    for(let item of dataList){
    
      if(item['isSelected']){
        count += 1;
      }
    }
    if(count == 0 || count == dataList.length){
      return 'All '+heading;
    }else if(count == 1){
      return this.getSelectedVal(dataList)
    }

    else{
      return count +' '+ heading;
    }

  }

  getSelectedVal(dataList){
   let data = dataList.find(res => res.isSelected == true);
   return data.key;
  }

}
