import { Injectable, ɵConsole } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { TransporterService } from 'src/app/transporter/transporter.service';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var $: any;

@Injectable()
export class LoginRedirectService implements CanActivate {
  gstin: any;
  constructor(
    private router: Router, 
    private authService: AuthService,
    private transporterService: TransporterService
  ) { }
  canActivate(): boolean {
    if (this.authService.getAuthorizationToken()) {
      let user = this.authService.getUser();
      this.authService.configureApp(user);
      this.gstin = user.organization.id;
      // 1	Super; 2	Shipper; 3	Carrier
      let userType = user.organization.type.id;
      if (userType == 2) {
        let landingModule;
        if (sessionStorage.getItem('onhibernate') == 'true') {
          landingModule = sessionStorage.getItem("defaultLandingModule");
        } else {
          landingModule = localStorage.getItem("defaultLandingModule");
        }
        if (landingModule) {
          this.authService.goToMfgLandingPage(landingModule);
        } else {
          this.router.navigate(["/manufacturer/manage-organization"]);
        }
      }else if (userType == 3) {
        this.getTransporterDetails(this.gstin);
      }else if (userType == 1) {
        this.router.navigate(['/admin/transporters']);
      }else {
        this.router.navigate(['/login']);
      }
      return false;
    }else {
      return true;
    }
  }

  getTransporterDetails(gstin) {
    this.transporterService
      .getOnboardingData(gstin)
      .pipe(
        catchError((error: any) => {
          this.router.navigate(['/transporter/onboarding/' + gstin]);
          return throwError(error);
        }),
        finalize(() => {
        })
      )
      .subscribe(data => {
        if (data === undefined || !data["isApproved"]) {
          this.router.navigate(['/transporter/onboarding/' + gstin]);
        } else {
          let landingModule;
          if (sessionStorage.getItem('onhibernate') == 'true') {
            landingModule = sessionStorage.getItem("defaultLandingModule");
          }else {
            landingModule = localStorage.getItem("defaultLandingModule");
          }
          this.authService.goToTspLandingModule(landingModule);
        }
      });
  }
}
