<div *ngIf="overlayLoader " class="loaderContent">
    <div class="loader"></div>
    <p>Processing</p>
</div>


<div class="body-data ">
        <div *ngIf="mode == 'UPLOAD_FILE'" class="ff-form-theme upload-file-box"
            [ngClass]="{'none-visibilty':overlayLoader}">
            <form [formGroup]="uploadTemplateData">
            <div class="drop-container" *ngIf="selectedSchema"
                [ngClass]="{'drop-container-small': uploadTemplateData.get('files').value}">
                <file-upload formControlName="files" >
                    <ng-template let-file="file" #placeholder>
                        <div class="placeholder">
                            <img class="drop-ico" src="/assets/images/drop-box.svg">
                            <div>
                                <p *ngIf="!uploadTemplateData?.get('files')?.value">Drop a File to Upload OR
                                    Select File</p>
                                <p *ngIf="uploadTemplateData?.get('files')?.value">Re Upload</p>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template let-i="index" let-file="file" let-control="control" #item>
                        <div class="file-info">
                            <div class="file-info-img">
                                <img src="../../../../../assets/images/file.svg">
                            </div>
                            <span class="file-name">{{ file.name }}</span>
                        </div>
                    </ng-template>
                </file-upload>
                <div *ngIf="!uploadTemplateData?.get('files')?.value" class="templ-btn-div">
                    <button class="ff_button_28_transparent templ-btn"
                        (click)="downloadTemplate();$event.stopPropagation()">
                        <img src="../../../../../assets/images/download.svg">
                        Download Template
                    </button>
                </div>
            </div>

        </form>
        </div>
   

    <div class=" contents" *ngIf="mode != 'UPLOAD_FILE' && !overlayLoader">


        <div class="ff_content">
            <div *ngIf="mode == 'VALIDATING_FILE'" class="msg-wrpr">
                <img class='cnfrm-tick' src="/assets/images/error-red-stroke.svg" alt="">

                <p class="failed-rcrds">
                    {{fileIssue}}
                </p>

            </div>

            <div *ngIf="overlayLoader && (mode == 'VALIDATING_RECORDS')" class="loaderContent">
                <div class="loader"></div>
                <p>Validating Records</p>
            </div>

            <div clas="body-data" *ngIf="mode == 'VALIDATED'">
                <div class="counter-row">
                    <div class="ff_counters">
                        <div class="ff_counter pointer" [class]="activeRecords == 'ALL' ? 'active' : ''"
                            (click)="filterData('ALL')">
                            <p class="ff_count">{{rowsData?.totalRows}}
                            </p>
                            <p class="ff_status">Total {{rowsData?.totalRows > 1 ? 'Records' : 'Record'}}</p>
                        </div>
                        <div class="ff_counter pointer" [class]="activeRecords == 'VALID' ? 'active' : ''"
                            (click)="filterData('VALID')">
                            <p class="ff_count valid">{{rowsData?.validRows}}
                            </p>
                            <p class="ff_status">Valid</p>
                        </div>
                        <div class="ff_counter pointer ff_no_border"
                            [class]="activeRecords == 'INVALID' ? 'active' : ''" (click)="filterData('INVALID')">
                            <p class="ff_count invalid">{{rowsData?.invalidRows}}
                            </p>
                            <p class="ff_status">Invalid</p>
                        </div>
                    </div>

                </div>

                <div class="table-data" *ngIf="visibleTableData.length" [ngStyle]="customTableStyleObject">
                    <table class="ff_div_table ff_100_table ff_single_div_table" >
                        <thead>
                            <tr>
                                <th class="index-row"></th>
                                <th *ngFor="let header of selectedSchema.fields">
                                    {{header.header}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of visibleTableData; let rowIndex = index" >
                                <td class="index-row">{{rowIndex+1}}</td>
                                <td [matTooltip]="row[headerIndex]?.valueStatus?.error ? row[headerIndex]?.valueStatus?.error : row[headerIndex]?.valueStatus?.warningMsg"
                                    [matTooltipDisabled]="row[headerIndex]?.valueStatus.status == 'VALID'"
                                    [ngClass]="{'invalid-cell': row[headerIndex]?.valueStatus.status == 'INVALID', 'warning-cell': row[headerIndex]?.valueStatus.status == 'WARNING'}"
                                    *ngFor="let header of selectedSchema.fields; let headerIndex = index">
                                    <span
                                        *ngIf="row[headerIndex]?.valueStatus.status == 'INVALID'">{{row[headerIndex]?.value?row[headerIndex]?.value:''}}</span>
                                    <span
                                        *ngIf="row[headerIndex]?.valueStatus.status == 'VALID'">{{row[headerIndex]?.value?row[headerIndex]?.value:''}}</span>

                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="!visibleTableData.length" class="no-data-class default-dabba bottom-border-dabba">
                    <p>No Data exist!</p>
                    
                </div>

            </div>
        </div>
    </div>
</div>
    <div class="no-padding action_button ff_flex_space_bw" [ngStyle]="customFooterStyleObject">
        <div class="ff_flex" >
            <button  class="ff_button_28_secondary  "
                (click)="goBackToUpload()">
                Close
            </button>
           
            <button *ngIf="rowsData?.invalidRows" class="ff_button_28_secondary remove ff_leftM_12"
                (click)="deleteAllInvalidRecords()">
                <img src="../../../../../assets/images/delete.svg">
                Delete All Invalid Records
            </button>
        </div>
        <button [disabled]="rowsData?.invalidRows || originalData.length == 0" class="ff_button_28 ff_leftM_12" (click)="submitRecords()">
            Proceed
        </button>

    </div>