import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { EnsureAuthenticatedService } from './auth/ensure-authentication/ensure-authentication.service';
import { LoginComponent } from './auth/login/login.component';
import { LoginRedirectService } from './auth/login-redirect/login-redirect.service';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginRedirectService] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'signup', redirectTo: 'login', pathMatch: 'full' },
  { 
    path: 'transporter', 
    loadChildren: () => import('./transporter/transporter.module').then(m=>m.TransporterModule), canActivate: [EnsureAuthenticatedService] 
  },
  { 
    path: 'admin', 
    loadChildren: () => import('./super-admin/super-admin.module').then(m=>m.SuperAdminModule), canActivate: [EnsureAuthenticatedService] 
  },
  { 
    path: 'manufacturer', 
    loadChildren: () => import('./admin/admin.module').then(m=>m.AdminModule), canActivate: [EnsureAuthenticatedService] 
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    preloadingStrategy :PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
