import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ExcelService } from '../excel.service';
import { UtilService } from '../utils.service';


@Component({
  selector: 'app-ff-file-upload',
  templateUrl: './ff-file-upload.component.html',
  styleUrls: ['./ff-file-upload.component.scss']
})
export class FfFileUploadComponent {
  @Input() selectedSchema;
  @Input() headers;
  @Input() bodyData;
  @Input() customTableStyleObject;
  @Input() customFooterStyleObject;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  progress: number = 0;
  template: any;

  overlayLoader: boolean = false;

  visibleTableData: any = [];
  originalData: any = [];

  rowsData: any = {
    totalRows: 0,
    validRows: 0,
    invalidRows: 0,
  };

  fileIssue: any;
  mode:
    | "UPLOAD_FILE"
    | "VALIDATING_FILE"
    | "VALIDATING_RECORDS"
    | "VALIDATED"
    | "CREATED" = "VALIDATING_FILE";
  activeRecords: "INVALID" | "VALID" | "ALL" = "ALL";

  private filesControl = new UntypedFormControl(null, FileUploadValidators.filesLimit(1));
  public uploadTemplateData = new UntypedFormGroup({
    files: this.filesControl
  });
  // allSchema: any = Schema;

  constructor(
    private excelService: ExcelService,
    private utilsFunctions: UtilService
  ) {}

  async ngOnInit(): Promise<void> {

    
  }

  ngOnChanges() {
    this.onFileUpload();
  }

  downloadTemplate() {
    let excelData: any = {};
    let excelFinalData: any = [];
    for (let templateHeader of this.selectedSchema["fields"]) {
      excelData[templateHeader["header"]] = "";
    }
    excelFinalData.push(excelData);
    let fileName = this.selectedSchema.name;
    this.excelService.exportAsExcelFile(excelFinalData, fileName);
  }

  async onFileUpload() {
    
    this.bodyData = this.bodyData.filter((obj) => {
      // Check if all properties in the object have null values
      const allEmptyValues = Object.values(obj).every((value) => {
        return value === null || value === undefined || value === "";
      });
      return !allEmptyValues; // Keep the object in the array if not all values are null
    });
   
    // this.headers = this.headers;
    // this.bodyData = this.bodyData;
    this.validateFileData();
  }

  async validateFileData() {
    this.mode = "VALIDATING_FILE";
    for (let index = 0; index < this.selectedSchema.fields.length; index++) {
      let result = this.headers.find((ele) => { return ele == this.selectedSchema.fields[index].header });
      if (!result) {
        this.fileIssue = `Invalid Template! Header: ${this.selectedSchema.fields[index].header} is Not Matching`;
        break;
      }
    }

    if (!this.fileIssue) {
      await this.validateRecords();
     
    } else {
      this.overlayLoader = false;
    }
  }

  validateRecords() {
    this.mode = "VALIDATING_RECORDS";
    
    for (let index = 0; index < this.bodyData.length; index++) {
      let dataRow = [];

      this.selectedSchema.fields.forEach((header) => {
        let value;

        if (header.fieldType == "date") {
          let incomingDate = this.bodyData[index][header.header];

          if (incomingDate) {
            let regex = new RegExp("^[0-9]+$");
            if (
              !regex.test(incomingDate) &&
              (incomingDate.includes("-") || incomingDate.includes("/"))
            ) {
              value = this.utilsFunctions.formatDateddmmyyyy(incomingDate);
            } else {
              let hours = Math.floor((incomingDate % 1) * 24);
              let minutes = Math.floor(((incomingDate % 1) * 24 - hours) * 60);
              let date = new Date(
                Date.UTC(0, 0, incomingDate, hours - 17, minutes)
              );
              value =
                date.getFullYear() +
                "-" +
                (date.getMonth() + 1).toString().padStart(2, "0") +
                "-" +
                date.getDate().toString().padStart(2, "0");
            }
          }
        }
        if (header.fieldType != "date") {
          value = this.bodyData[index][header.header]
            ? this.applyPreProcessor(header,this.bodyData[index][header.header])
            : "";
        }
        
        let item = {
          headerName: header.header,
          value: value,
          valueStatus: value? this.checkValidity(header, value):this.getValueStatus(header),
        };
        dataRow.push(item);
      });
      this.originalData[index] = dataRow;
      this.progress = Math.floor(
        (this.originalData /this.bodyData.length) * 100 || 0
      );
    }
    this.mode = "VALIDATED";
    this.updateCounters();
    this.visibleTableData = this.originalData.slice();
    this.overlayLoader = false;
  }

  // Counters
  updateCounters() {
    this.rowsData.totalRows = this.originalData.length;
    this.rowsData.validRows = 0;
    this.rowsData.invalidRows = 0;
    for (let row of this.originalData) {
      let rowStatus = row.find((ele) => ele.valueStatus.status == "INVALID");
      if (rowStatus) {
        this.rowsData.invalidRows += 1;
      }
    }
    this.rowsData.validRows =
      this.rowsData.totalRows - this.rowsData.invalidRows;
  }

  // Check Validity of cell
  checkValidity(header, value) {
    let valueStatus = {};
    if (header && value) {
      if (header.validator) {
        valueStatus = this.checkWithValidatorType(header.validator.type, header, value);
      } else {
        valueStatus['status'] = Status.Valid;
      }
    } 
    return valueStatus;
  }

  applyPreProcessor(header,value){
    if(header.preProcessors && header.preProcessors.length){
      for (const processor of header.preProcessors) {
        switch (processor) {
          case "stringTrimmer":
            value = this.stringTrimmer(value);
            break;
          case "capitalizeData":
            value = this.capitalizeData(value);
            break;
            case "allCaps":
              value = this.allCaps(value);
              break;
          // Add more cases for other preprocessors if needed
          default:
            value = value;
        }
      }
    }
   
    return value;
  }

  stringTrimmer(value) {
    if (typeof value === 'string') {
      return value.trim();
    } else {
      return value;
    }
  }
  capitalizeData(value) {
    if (typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return value;
    }
  }

  allCaps(value) {
    if (typeof value === 'string') {
      return value.toUpperCase();
    } else {
      return value;
    }
  }
  
  getValueStatus( header){
    let valueStatus = {};
    const isMandatory = this.selectedSchema.mandatoryFeilds.includes(header.name);

    if (!isMandatory) {
      valueStatus['status'] = Status.Warning;
      valueStatus['warningMsg'] = "Empty Field! But Non-Mandatory";
    } else {
      valueStatus['status'] = "INVALID";
      valueStatus['error'] = "This Field is Mandatory";
    }
    return valueStatus;
  }
  

  checkWithValidatorType(validatorType, header, value) {
    let valueStatus = {};
    switch (validatorType) {
      case "regexValidator":
        valueStatus = this.validateMultipleRegex(header, value);
        return valueStatus;
      case "numberValidator":
        valueStatus = this.numberValidation(header, value);
        return valueStatus;
      case "constraintValidator":
        valueStatus = this.constraintValidation(header, value);
        return valueStatus;
      case "dateValidator":
        valueStatus = this.dateValidation(header, value);
        return valueStatus;
      default:
        valueStatus["status"] = Status.Warning;
        valueStatus[
          "error"
        ] = `Validator Type is not Defined for (${header.header})}`;
        return valueStatus;
    }
  }

  validateSingleRegex(header, value) {
    let valueStatus = {};
    let regex = new RegExp(header.validator.regexFormat);
    if (regex.test(value)) {
      valueStatus["status"] = Status.Valid;
    } else {
      valueStatus["error"] = header.validator.errorMessage?header.validator.errorMessage:"Invalid Format";
      valueStatus["status"] = Status.Invalid;
    }
    return valueStatus;
  }

  validateMultipleRegex(header, value) {
    let valueStatus = {};
    for (let regexData of header.validator.regexFormat) {
      let regex = new RegExp(regexData);
      if (regex.test(value)) {
        valueStatus["status"] = Status.Valid;
        break;
      } else {
        valueStatus["error"] = header.validator.errorMessage?header.validator.errorMessage:"Invalid Format";
        valueStatus["status"] = Status.Invalid;
      }
    }
    return valueStatus;
  }

  numberValidation(header, value) {
    const valueStatus = {};

    const regex = new RegExp(header.validator.regexFormat);
    const isValidFormat = regex.test(value);
    if (isValidFormat) {
      valueStatus['status'] = Status.Valid;
  
      
    }
     else {
      if (header.validator.min && header.validator.max) {
        if (value < header.validator.min || value > header.validator.max) {
          valueStatus['status'] = Status.Invalid;
          valueStatus['error'] = `Value should be between ${header.validator.min} and ${header.validator.max}`;
        }
      } else if (header.validator.min && value < header.validator.min) {
        valueStatus['status'] = Status.Invalid;
        valueStatus['error'] = `Value should be more than ${header.validator.min}`;
      } else if (header.validator.max && value > header.validator.max) {
        valueStatus['status'] = Status.Invalid;
        valueStatus['error'] = `Value should be less than ${header.validator.max}`;
      }
      else {
        valueStatus['status'] = Status.Invalid;
        valueStatus['error'] = header.validator.errorMessage || "Invalid Format";
      }
    }
  
    return valueStatus;
  
  }

  constraintValidation(header, value) {
    let valueStatus = {};
    for (let constraint of header.validator.constraints) {
      if (constraint.feildValue == value) {
        valueStatus["status"] = Status.Valid;
        break;
      } else {
        valueStatus["error"] = header.validator.errorMessage?header.validator.errorMessage:"Invalid Format";
        valueStatus["status"] = Status.Invalid;
      }
    }
    if (valueStatus["status"] == Status.Invalid) {
      let constraintValues = "";
      header.validator.constraints.forEach((constraintData) => {
        if (constraintValues.length > 0) {
          constraintValues =
            constraintValues + ", " + constraintData.feildValue;
        } else {
          constraintValues = constraintData.feildValue;
        }
      });
      valueStatus["error"] =
        "Invalid Format! Excepted values : " + constraintValues;
    }
    return valueStatus;
  }

  dateValidation(header, value) {
    let valueStatus = {};

    if (value) {
      let actualDate = new Date(value).setHours(0, 0, 0, 0);
      valueStatus["status"] = Status.Valid;
      let minDate = header.validator.minDate
        ? this.convertDate(header.validator.minDate)
        : "";
      let maxDate = header.validator.maxDate
        ? this.convertDate(header.validator.maxDate)
        : "";

      if (header.validator.minDate && minDate > actualDate) {
        valueStatus["status"] = Status.Invalid;
        valueStatus[
          "error"
        ] = `Date should be more than ${header.validator.minDate}`;
      }
      if (header.validator.maxDate && maxDate < actualDate) {
        valueStatus["status"] = Status.Invalid;
        valueStatus[
          "error"
        ] = `Date should be less than ${header.validator.maxDate}`;
      }
    }
    return valueStatus;
  }

  convertDate(date) {
    const dateString = date;
    const [day, month, year]: any = dateString.split("-");
    let actualDate: any = new Date(year, month - 1, day);
    actualDate.setHours(0, 0, 0, 0);
    return actualDate;
  }

  // check if a Row is Invalid
  checkIfInvalid(row) {
    let status = false;
    let rowStatus = row.find((ele) => ele.valueStatus.status == Status.Invalid);
    if (rowStatus) {
      status = true;
    }
    return status;
  }

  // Delete Record
  removeRow(row) {
    // this.originalData.splice(index, 1);
    this.originalData = this.originalData.filter((innerArr) => {
      // Check if all objects in array1 are present in the current innerArr of array2
      const isMatching = row.every((obj1) => {
        return innerArr.some((obj2) => this.isObjectMatching(obj1, obj2));
      });
    
      return !isMatching; // Return true to keep the current innerArr in filteredResults
    });
    
    this.updateCounters();
    this.filterData(this.activeRecords);
  }

  isObjectMatching(obj1, obj2) {
    return (
      obj1.headerName === obj2.headerName &&
      obj1.valueStatus.status === obj2.valueStatus.status
    );
  }

  // Download All Invalid Records
  downloadInvalidRecords() {
    let filteredData = [];

    let excelFinalData = [];
    for (let row of this.originalData) {
      let rowStatus = row.find(
        (ele) => ele.valueStatus.status == Status.Invalid
      );
      if (rowStatus) {
        filteredData.push(row);
      }
    }

    filteredData.forEach((row) => {
      let excelData = {};
      for (let templateHeader of this.headers) {
        excelData[templateHeader] = this.getInvalidRowData(row, templateHeader);
      }
      excelFinalData.push(excelData);
    });
    let fileName = "Invalid Records";
    this.excelService.exportAsExcelFile(excelFinalData, fileName);
  }

  getInvalidRowData(row, header) {
    let value;
    let rowData = row.find((ele) => ele.headerName == header);
    if (rowData) {
      value = rowData.value;
    }
    return value;
  }

  // Delete All Invalid Records
  deleteAllInvalidRecords() {
    this.downloadInvalidRecords();
    for (let index = this.originalData.length - 1; index >= 0; index--) {
      let rowStatus = this.originalData[index].find(
        (ele) => ele.valueStatus.status == Status.Invalid
      );
      if (rowStatus) {
        this.originalData.splice(index, 1);
      }
    }

    this.updateCounters();
    this.filterData(this.activeRecords);
  }

  // Filter Records on status
  filterData(type) {
    this.activeRecords = type;
    switch (type) {
      case Status.Invalid:
        this.visibleTableData = this.originalData.filter((row) =>
          row.some((ele) => ele.valueStatus.status === Status.Invalid)
        );
        break;
      case Status.Valid:
        this.visibleTableData = this.originalData.filter((row) =>
          !row.some((ele) => ele.valueStatus.status === Status.Invalid)
        );
        break;
      case "ALL":
        this.visibleTableData = this.originalData;
        break;
    }
  }

  submitRecords() {
    let excelFinalData: any = [];

    this.originalData.forEach((row) => {
      let excelData: any = {};
      for (let templateHeader of this.selectedSchema["fields"]) {
        excelData[templateHeader["name"]] = this.getData(row, templateHeader);
      }
      excelFinalData.push(excelData);
    });
    
    this.action.next({
      status:'FILTERED_DATA',
      filteredData:excelFinalData
    });

  }

  goBackToUpload(){
    this.action.next({
      status:'CANCELLED'
    });
  }

  getData(row, header) {
    let data;
    let result = row.find((ele) => ele.headerName == header["header"]);
    if (result) {
      if (header.validator && header.validator.type == "constraintValidator") {
        let constraintValue = header.validator.constraints.find(
          (ele) => ele.feildValue == result.value
        );
        if (constraintValue) {
          data = constraintValue.mappedValue;
        }
      } else {
        data = result.value;
      }
    }
    return data;
  }
}

const enum Status {
  Valid = "VALID",
  Invalid = "INVALID",
  Warning = "WARNING",
}