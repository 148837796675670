import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public infoMessage: any,
  ) { }

  ngOnInit(): void {
  }

  closeDialogBox(): void {
    let returnData = {
      status: 'CLOSED',
    }
    this.dialogRef.close(returnData);
  }

}
