  <div class="map_wrapper">
    <div class="map" id="map"></div>
    <div class="legends">
      <p class="header">Legends</p>
      <div class="leg-container">
        <div class="leg-wrapper" *ngFor="let legend of legends">
          <div class="marker" [style.background-color]="legend.color"></div>
          <p class="title">{{legend.title}}</p>
        </div>
      </div>
    </div>
    <form [formGroup]="filterForm">
      <div class="reset-button ff-form-theme">
        <div *ngIf="mapLevel == 'GROUP'" #tspFilter class="pointer">
          <div>
            <mat-form-field (click)="showClusterFilter = true;" appearance="outline" class="col-13-width">
              <img matPrefix class="ff_mat-prefix-icon" src="../../././../../assets/images/location-marker-clickable-blue.svg">
              <input #dateInput matInput class="pointer" placeholder="Select Clusters" readonly
                formControlName="clustersName" required>
                <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
            </mat-form-field>
            <div class="relative">
              <app-ff-filter *ngIf="showClusterFilter" [inputItemList]="allFilterClusters"
                (action)="filterAction($event, 'CLUSTERS')">
              </app-ff-filter>
            </div>
          </div>
        </div>
        <div *ngIf="mapLevel == 'RFQ'" #originFilter class="pointer">
          <div>
            <mat-form-field (click)="showOriginFilter = true;" appearance="outline" class="col-13-width">
              <img matPrefix class="ff_mat-prefix-icon" src="../../././../../assets/images/location-marker-clickable-blue.svg">
              <input #dateInput matInput class="pointer" placeholder="Select Origins" readonly
                formControlName="originsName" required>
                <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
            </mat-form-field>
            <div class="relative">
              <app-ff-filter *ngIf="showOriginFilter" [inputItemList]="allFilterOrigins"
                (action)="filterAction($event, 'ORIGINS')">
              </app-ff-filter>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
