
import { Component, ElementRef, Inject, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { AdminService } from 'src/app/admin/admin.service';
import { SnackbarService } from 'src/app/snackbarService/snackbar.service';
import { UtilService } from '../utils.service';
import { AuthService } from 'src/app/auth/auth.service';
declare var google:any;

@Component({
  selector: 'app-add-new-warehouse',
  templateUrl: './add-new-warehouse.component.html',
  styleUrls: ['./add-new-warehouse.component.scss']
})
export class AddNewWarehouseComponent implements OnInit {
  geocoder = new google.maps.Geocoder();
  orgId:any;
  datasetId:any ;

  address: string;
  addWarehouseForm: any;
  selected: any;
  selectedAddressDetails: any = '';
  stateList: any;
  similarCitiesData: any = [];
  ShowMatchingCities: boolean = false;

  localityData: any = {};
  showInfoData: boolean = false;
  marginTop: any = 0;
  listenerFn: () => void;
  public stateSearchmattag: UntypedFormControl = new UntypedFormControl();
  public stateFilteredTags: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('customMatchingCity', { static: false }) additionalDetails_ele: ElementRef;
  warehouseCallType: 'SPOT-BID' | 'TRIP' = 'SPOT-BID';
  suggestions: Array<any> = [];
  destinationWhTypes: {}[] = [
    {
      key: 'Distribution Warehouse',
      value: 'DISTRIBUTION_WAREHOUSE'
    },
    {
      key: 'Customer Address',
      value: 'CUSTOMER'
    },
    {
      key: 'Vendor Address',
      value: 'VENDOR'
    },
  ];
  pointOfCreation: 'Origin' | 'Destination' = 'Destination'; 
  // by default new warehouse creation will be for destination
  // it is not type of warehouse, it is to represent if warehouse is created for origin or destination 
  // warehouse will be of type destination only

  private autocompleteService: google.maps.places.AutocompleteService;
  constructor(
    private authService: AuthService,
    private adminService: AdminService, 
    private renderer: Renderer2,
    private snackbar: SnackbarService,
    private _formBuilder: UntypedFormBuilder, 
    private utilService: UtilService,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<AddNewWarehouseComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.listenerFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.additionalDetails_ele.nativeElement.contains(e.target)) {
        this.ShowMatchingCities = false;
      }

      const googleSuggestionsInputDiv = this.elementRef.nativeElement.querySelector('#googleSuggestionsInput');
      if(googleSuggestionsInputDiv){
        if(!googleSuggestionsInputDiv.contains(e.target)){
          this.suggestions = [];
        }
      }
    });
    this.addWarehouseForm = this._formBuilder.group({
      name: ['', Validators.required],
      externalCode: [''],
      city: ['', Validators.required],
      cityLocality: [''],
      state: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', Validators.pattern('^[1-9][0-9]{5}$')],
      type: ['', Validators.required],
      gstin: ['', [Validators.pattern('^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}(z|Z)[0-9A-Za-z]{1}$')]],
      operationType: [''],
      dealerType: ['LO']
    });
  }

  ngOnInit(): void {
    let user = this.authService.getUser();
    if(user.roleDatasetPermissions && user.roleDatasetPermissions.length){
     let result = user.roleDatasetPermissions.find(role => role['name'] == "INVOICE");
     if(result){
      this.datasetId = result.datasetId;
     }
    }

    this.warehouseCallType = this.data.warehouseCallType ? this.data.warehouseCallType : 'TRIP';
    if(this.data.pointOfCreation){
      switch(this.data.pointOfCreation){
        case 'DESTINATION':
          this.pointOfCreation = 'Destination';
          break;
        case 'ORIGIN':
          this.pointOfCreation = 'Origin';
          break;
      }
    }
    this.autocompleteService = new google.maps.places.AutocompleteService();
    if(this.warehouseCallType == 'TRIP'){
      this.orgId = this.data.orgId;
      let invoiceNumber = this.data.invoiceNumber;
      let customerCode = this.data.customerCode;
      this.addWarehouseForm.get('type').patchValue('CUSTOMER');
      this.addWarehouseForm.get('operationType').patchValue('CUSTOMER');
      this.addWarehouseForm.get('externalCode').patchValue(customerCode);

      this.addWarehouseForm.get('cityLocality').setValidators(Validators.required);
      this.addWarehouseForm.controls["cityLocality"].updateValueAndValidity();
      this.addWarehouseForm.get('dealerType').setValidators(Validators.required);
      this.addWarehouseForm.controls["dealerType"].updateValueAndValidity();
      this.getInvoiceData(invoiceNumber);
    }else if(this.warehouseCallType == 'SPOT-BID'){
      this.destinationWhTypes = [
        {
          key: 'Customer Address',
          value: 'CUSTOMER'
        },
        {
          key: 'Vendor Address',
          value: 'VENDOR'
        },
      ];
    }

    this.getMetaData();
  }

  handleSearchInput(event: any) {
    const keyword = event.target.value;
    const searchOptions: google.maps.places.AutocompletionRequest = {
      input: keyword,
      componentRestrictions: { country: 'IN' }
    };

    this.autocompleteService.getPlacePredictions(searchOptions, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.suggestions = results.map((result) => {
          return {
            id: result.place_id,
            text: result.description
          };
        });        
        // Render the search suggestions
      } else {
        this.suggestions = []
        // Handle the error
      }
    });
  }

  selectedAddress(place){
    this.suggestions = []
    this.addWarehouseForm.get('pincode').reset();
    this.addWarehouseForm.get('state').reset();
    this.addWarehouseForm.get('city').reset();
    this.addWarehouseForm.get('address').reset();
    this.addWarehouseForm.get('address').patchValue(place.text);
    this.geocoder.geocode({ placeId: place.id }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const addressComponents = results[0].address_components;
       
        let city;
        for (const location of addressComponents) {
            if (location.long_name && location.types.includes("locality") && location.types.includes("political")) {
                city = location.long_name;
                break;
            } else if (location.long_name && location.types.includes("administrative_area_level_3")) {
                city = location.long_name;
                break;
            } else if (location.long_name && location.types.includes("administrative_area_level_2")) {
                city = location.long_name;
                break;
            }
        }

        const state = addressComponents.find(component =>
          component.types.includes('administrative_area_level_1')
        )?.long_name;

        const postalCode = addressComponents.find(component =>
          component.types.includes('postal_code')
        )?.long_name;
      
        if(postalCode){
          this.addWarehouseForm.get('pincode').patchValue(postalCode)
        }
        if(city){
          this.addWarehouseForm.get('city').patchValue(city);
        }
        if(state){
          this.addWarehouseForm.get('state').patchValue(state)
        }
       
      } else {
     
      }
    });
  }
  getInvoiceData(invoiceNumber){
    let enableViewSetting = true;
    let listingPayload = {
      "pageSize": 30,
      "pageIndex": 1,
      "sortMap" : {
        "recordMap->>'invoiceDate'" : "ASC"
      },
      "filters": {
        querySearch:[{
          'fieldName': 'invoiceNumber',
          'searchValue': invoiceNumber
        }]
      }
    }
    this.adminService.getFlouderRecordsForDatasetId(this.orgId, listingPayload,this.datasetId, enableViewSetting)
    .pipe(
      catchError((error: any) => {
        this.snackbar.showErrorSnackBar('Failed to load invoice details.');
        return throwError(error);
      }),
      )
      .subscribe( resp => {
        let invoice = resp['records'][0];
        let whName = invoice['recordMap']['customerName'];
        this.addWarehouseForm.get('name').patchValue(whName);
      });
  }

  dealerTypeChange(){
    if(this.addWarehouseForm.get('dealerType').value == 'UP'){
      let city = this.addWarehouseForm.get('city').value;
      this.addWarehouseForm.get('cityLocality').patchValue(city);
    }else{
      this.addWarehouseForm.get('cityLocality').patchValue('');
    }
  }

  mouseHover(status, event) {
    if (status === 'ENTER') {
      this.marginTop -= 60;
      this.showInfoData = true;
    } else {
      this.showInfoData = false;
      this.marginTop = -110;
    }
  }

  getMetaData() {
    this.adminService.getMetadata()
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        }),
      )
      .subscribe(data => {
        this.stateList = data.States
        this.stateFilteredTags.next(this.stateList.slice());
      });
  }

  patchFormData() {
    this.similarCitiesData = [];
    this.localityData = '';
    this.addWarehouseForm.get('address').patchValue(this.selectedAddressDetails.formatted_address);

    this.selectedAddressDetails.address_components.forEach(addressData => {
      if (addressData.types.includes("postal_code")) {
        this.addWarehouseForm.get('pincode').patchValue(addressData.long_name)
      }
      if (addressData.types.includes("administrative_area_level_2")) {
        this.addWarehouseForm.get('city').patchValue(addressData.long_name);
      }
      if (addressData.types.includes("administrative_area_level_1")) {
        let state = this.stateList.find(state => {
          return state == addressData.long_name;
        });
        if (state) {
          this.addWarehouseForm.get('state').patchValue(state)
        }
      }

    });
    this.selectedAddressDetails.address_components.forEach(addressData => {
      if (addressData.types.includes("locality")) {
        if (this.addWarehouseForm.get('city').value != addressData.long_name) {

          this.localityData = {
            'city': addressData.long_name,
            'fromLocalityList': true
          }
        }
      }
    });
    this.getSimilarCities();
  }

  setCityData(city) {
    this.ShowMatchingCities = false;
    this.addWarehouseForm.get('city').patchValue(city);

  }

  getSimilarCities() {
    this.similarCitiesData = [];
    if (this.addWarehouseForm.get('city').value && this.addWarehouseForm.get('state').value) {
      let bodyData = {
        "name": this.addWarehouseForm.get('city').value,
        "state": this.addWarehouseForm.get('state').value,
        "distributionType": "DESTINATION",
        "country": "India"
      }
      this.adminService.getSimilarCitiesData(bodyData)
        .pipe(
          catchError((error: any) => {
            return throwError(error);
          })
        )
        .subscribe(data => {
          data.cities.forEach(city => {
            this.similarCitiesData.push({
              'city': city,
              'fromLocalityList': false
            });
          });
          if (this.localityData) {
            this.similarCitiesData.push(this.localityData);
          }

          if (this.similarCitiesData.length) {
            this.ShowMatchingCities = true;
          }
          if (this.localityData.city == this.addWarehouseForm.get('city').value) {
            this.ShowMatchingCities = false;
          }
        })
    }
  }

  destinationModelChangedTags() {
    this.destinationFilterTags();
  }

  private destinationFilterTags() {
    if (!this.stateList) {
      return;
    }
    // get the search keyword
    let search = this.stateSearchmattag.value;
    if (!search) {
      this.stateFilteredTags.next(this.stateList.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }
    this.stateFilteredTags.next(
      this.stateList.filter(state => state.toLowerCase().indexOf(search) > -1)
    );
  }

  confirmOperation(): void {
    let postData = this.addWarehouseForm.value;
    postData.operationType = this.addWarehouseForm.value.type == 'CUSTOMER' ? 'Customer' : 'Outsourced (3PL)';
    if (!postData.gstin) {
      delete postData.gstin;
    }else{
      postData.gstin = postData.gstin.toUpperCase();
    }
    if(postData.cityLocality){
      postData.cityLocality = this.utilService.getStringToSentanceCase(postData.cityLocality);
    }

    let returnData = {
      status: 'CONFIRMED',
      warehouseData: postData
    }
    this.dialogRef.close(returnData);
  }

  closeDialogBox(): void {
    let returnData = {
      status: 'CLOSED',
    }
    this.dialogRef.close(returnData);
  }

}
