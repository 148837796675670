import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { environment} from '../../environments/environment'
import { ActivatedRoute, Router } from '@angular/router';
@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,private activatedRoute: ActivatedRoute,
        private router: Router,) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.isLoggedIn()) {
            const authToken = this.authService.getAuthorizationToken();
                
                req = req.clone({
                    setHeaders:
                        { Authorization: authToken }
                    }
                );
           
        }
        
        return next.handle(req);
    }
}