import { 
    Pipe, 
    PipeTransform 
 } from '@angular/core';  
 
 @Pipe ({ 
    name: 'indianCurrency' 
 }) 
 
 export class IndianCurrency implements PipeTransform { 
    transform(value: any) { 
      let toChange = value.toString();
      let changed = toChange.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
      changed = '₹ ' + changed;
      return changed;
    } 
 } 