import {
   Pipe,
   PipeTransform
} from '@angular/core';

@Pipe({
   name: 'sentenceCase'
})

export class SentenceCase implements PipeTransform {
   transform(value: any) {
      if (!value) return '';
      let changed = this.convertToSentenceCaseAndRemoveUnderscore(value)
      return changed;
   }
   convertToSentenceCaseAndRemoveUnderscore(sentence) {
      return sentence.replace(/\b[A-Z]+(?:_[A-Z]+)*\b/g, function (match) {
         return match.toLowerCase().replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase());
      });
   }
} 