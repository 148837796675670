<div *ngIf="itemList.length > 0" class="item_listing filter_component">
    <div class="search-wrapper">
        <img class="search-glass" src="../../../assets/images/search.svg" alt="Search"
        (click)="search()">
        <input id="searchInput" type="text" placeholder="Search" 
        (click)="search()" (keyup)="search()" [(ngModel)]="searchText">
        <img *ngIf="searchText.length > 0" class="search-close" src="../../../assets/images/cross-button.svg" 
        alt="clear"
        (click)="clearSearch()">
    </div>
    <div class="select-all">
        <mat-checkbox [(ngModel)]="select_all" [indeterminate]="checkSelections()"
            (ngModelChange)="onSelectAll($event)">Select all</mat-checkbox>
    </div>
    <div class="component_size">
            <div class="group-data" *ngFor="let item of itemList;index as i">
                <mat-checkbox *ngIf="item.isVisible" [(ngModel)]="item.isSelected" (ngModelChange)="onCheckedOption($event,i)">
                    {{item.key}}
                </mat-checkbox>
            </div>
            <p *ngIf="!isSearchFound" class="div_0">No search results found</p>
    </div>
    <div class="action_button">
            <button class="ff_button_28_transparent" (click)="cancel()">Cancel</button>
            <button type="button" class="ff_button_28 leftM_6" (click)="submit()">Apply</button>
    </div>
</div>
<div *ngIf="itemList.length <= 0" class="item_listing component_size filter_component">
    <p class="div_0">No Data Available</p>
</div>
