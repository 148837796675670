
<div class="alert custom-form">
    <span class="material-icons dailog-close-icon" (click)="onClick('NO')">clear</span>
    <h2 class="heading-data" *ngIf="heading"> {{heading}}</h2>
    <div class="col-md-12 message no-padding">
        <p>{{message}}</p>
    </div>
   
</div>
<div *ngIf="type === 'confirm'" class="col-md-12 text-end top-margin-10 action-btn  ">
    <div class="no-padding-left top-margin-20 bottom-margin-20 flex-end">
       
        <button class="ff_button_32_transparent float-md-right margin-right-alert" (click)="onClick('NO')" >{{heading ==='Confirmation' ? 'Cancel' : 'No'}}</button>
        <button class="ff_button_32-80 float-md-right " (click)="onClick('YES')" >{{heading ==='Confirmation' ? 'Confirm' : 'Yes'}}</button>
    </div>
</div>
<div *ngIf="type === 'alert'" class="col-md-12 text-end top-margin-10 action-btn ">
    <button class="ff_button_32-80  two-border-radius width-full top-margin-20 bottom-margin-20" (click)="onClick('OK')" >Ok</button>
</div>