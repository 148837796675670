<div [ngClass]="[minifilter ? 'filter-box hide' : 'filter-box']" class="col-md-12 no-padding">
    <div class="filter-box">
        <section class="filter">
            <h3>Filters</h3>
            {{selectedFilter}}
            <div class="clear-all" *ngIf="selectedFilter != ''" (click)="clearFilter()"><span class="pointer">Clear
                    all</span></div>
            <div class="filter-list">
                <div class="filter-value" *ngFor="let element of selectedFilter">
                    <span class="filter-data">{{element.value.displayName}} <img class="cross pointer"
                            (click)="removeFilter(element.key, true);" src="/assets/images/clear-filter.svg"> </span>

                </div>
            </div>
        </section>
        <div class="ff_content ff-form-theme">
            <form>
                <div class="sub-section sec_topM bottom-margin-xs" *ngFor="let filter of filters">
                    <div class="lg-field full-width">
                        <label class="ff-label">{{filter.name}}</label>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-select placeholder="Select {{filter.name}}" multiple
                                (selectionChange)="filterSelected($event, filter)" [(value)]="filter.value">
                                <mat-option *ngFor="let value of filter.values; let i = index"
                                    [value]="value.dimension">{{value.dimension}} <span
                                        class="data-count">({{value.measure}})</span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>