<div class="ff_dialog_box">
    <div class="ff_header">
        <div class="ff_title">
            Unloading Point Observations
        </div>
        <img src="/assets/images/cross-button.svg" alt="Close" (click)="closeDialogBox()" class="ff_close_icon">
    </div>
    <div class="ff_content">
        <div class="particulars_content">
            <table class="particulars_table">
                <thead>
                    <tr class="sticky_header">
                        <th class="col-15-width"></th>
                        <th class="col-2-width"></th>
                        <th class="col-5-width tar">Damage</th>
                        <th class="col-5-width tar">Shortage</th>
                        <th class="col-5-width tar">Leakage</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lineItem of requestParticular">
                        <td>
                            {{lineItem.particular}}
                            <div class="sub_text">
                                {{lineItem.sku}}
                            </div>
                        </td>
                        <td><img class="unit_image" [src]="lineItem.image" alt=""></td>
                        <td class="tar">{{lineItem.damage}}</td>
                        <td class="tar">{{lineItem.shortage}}</td>
                        <td class="tar">{{lineItem.leakage}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="remarks">
            <div class="ff_key_value">
                <p class="ff_key">Repacking Charges</p>
                <p class="ff_value">{{repackageCharge | indianCurrency}}</p>
            </div>
            <div class="ff_key_value ff_leftM_24">
                <p class="ff_key">Remarks</p>
                <p class="ff_value">{{remarks}}</p>
            </div>
        </div>
    </div>
</div>