import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from '../excel.service';

@Component({
  selector: 'app-import-error-model',
  templateUrl: './import-error-model.component.html',
  styleUrls: ['./import-error-model.component.scss']
})
export class ImportErrorModelComponent implements OnInit {
  
  fileName: any;
  excelData: any;
  rejectedPayloadLength:number;
  payloadLength:number;
  confirmedOperationsLength:number;

  constructor(
    public dialogRef: MatDialogRef<ImportErrorModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private excelService: ExcelService,
  ) { }

  ngOnInit() {
    this.fileName = this.data.fileName;
    this.excelData = this.data.excelData;
    this.rejectedPayloadLength = this.data.rejectedPayloadLength;
    this.payloadLength = this.data.payloadLength;
    this.confirmedOperationsLength = this.payloadLength - this.rejectedPayloadLength;
  }

  confirmOperation(): void {
    let returnData = {
      status: 'CONFIRMED'
    }
    this.dialogRef.close(returnData);
  }

  closeDialogBox(): void {
    let returnData = {
      status: 'CLOSED',
    }
    this.dialogRef.close(returnData);
  }

  exportFailedRecords() {
    this.excelService.exportAsExcelFile(this.excelData, this.fileName);
  }

}
