<div class="ff_dialog_box">
    <div class="ff_header">
        <div class="ff_title">
            Observations as per Receiving Party
        </div>
        <img src="/assets/images/cross-button.svg" alt="Close" (click)="closeDialogBox()" class="ff_close_icon">
    </div>
    <div class="ff_content">
            <div class="ff_key_value">
                <p class="ff_key">Destination Reached Date & Time</p>
                <p class="ff_value">{{inTime | date : 'EEE, dd MMM yyyy / hh:mm a'}}</p>
            </div>
            <div class="ff_key_value top-margin-20">
                <p class="ff_key">Destination Released Date & Time</p>
                <p class="ff_value">{{outTime | date : 'EEE, dd MMM yyyy / hh:mm a'}}</p>
            </div>
    </div>
</div>