import { 
    Pipe, 
    PipeTransform 
 } from '@angular/core';  
 
 @Pipe ({ 
    name: 'vehicleNumber' 
 }) 
 
 export class VehicleNumber implements PipeTransform { 
    transform(value: any) { 
    //    let mul = parseFloat(multiply); 
    //    return mul * value 
    let vehNumber;
    switch(value.length){
      case 8:
         vehNumber = value.replace(/^(.{2})(.{2})(.{0})(.*)$/, "$1 $2 $3 $4");
         break;
      case 9:
         vehNumber = value.replace(/^(.{2})(.{2})(.{1})(.*)$/, "$1 $2 $3 $4");
         break;
      case 10:
         vehNumber = value.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4");
         break;
      case 11:
         vehNumber = value.replace(/^(.{2})(.{2})(.{3})(.*)$/, "$1 $2 $3 $4");
         break;
      default:
         vehNumber = value;
         break;
      }
    return vehNumber.toUpperCase();
    } 
 } 