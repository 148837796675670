<div class="dialog-box-pop">
    <div class="left-right-ends">
        <div class="head-text-dialog">Select Transporters</div>
        <div>
            <img class="pointer" src="/assets/images/cross-button.svg" (click)="cancel()" />
        </div>
    </div>

    <div *ngIf="!showLoader" class="top-margin-10 flex-spacebetween">
        <!-- All Lanes Data -->
        <!-- All Lanes Data -->
        <div class="all-border section-padding width-46-p">
            <div class="heading-16 flex-spacebetween ">
                <div class="tspHeading top-margin-5">
                    All Transporters 
                    ({{completeTspList?.length + nonEditiableTsps?.length}})
                </div>
                <div>
                    <form [formGroup]="searchInput">
                        <div>
                            <div class="search-bar">
                                <img src="../../../../assets/images/search.svg">
                                <input class="search-input" matInput placeholder="Search" formControlName="inputValue">
                                <img *ngIf="searchInput.get('inputValue').value.length >=1" class="cross_search"
                                    (click)="cancelSearch('LEFT')" src="../../../../assets/images/cross-button.svg">
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="top-margin-5">
                <div class="select-all">
                    <mat-checkbox [(ngModel)]="select_all_setting"  [indeterminate]="checkSelections()" [disabled]="checkListStatus()"
                        (ngModelChange)="onSelectAll($event,'originallistSide')">Select all</mat-checkbox>
                </div>
                <div class="scroll-box-500">
                    <div *ngFor="let tsp of leftVisibleTspList" class="display-flex">
                        <p class="checkbox-place col-16-width customCheckBox" *ngIf="!tsp.alreadyGrouped">
                            <mat-checkbox [(ngModel)]="tsp.isSelected" >
                                <div  class="lanes-text">
                                    {{tsp?.companyName}}
                                </div>
                            </mat-checkbox>
                        </p>
                        <p *ngIf="tsp.alreadyGrouped" class="readonly-text-lane no-margin margin-left-25 lanes-text">{{tsp?.companyName}}</p>
                    </div>
                    <div  *ngFor="let tsp of nonEditiableTsps" class="display-flex">
                        <p  class="readonly-text-lane no-margin margin-left-25 lanes-text">{{tsp?.tspPartnerName || tsp?.companyName}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Action Button  -->
        <!-- Action Button  -->

        <div class="margin-auto">
            <div>
                <img (click)="addToGroup()" [ngClass]="{'opacity-5': checkTspDisability()}"  class="pointer width-35"
                    src="/assets/images/add-right-arrow.svg">
            </div>
            <div class="top-margin-24">
                <img (click)="removeFromGroup()" [ngClass]="{'opacity-5': checkShortlistedDisability()}"
                    class="pointer width-35" src="/assets/images/add-left-arrow.svg">
            </div>
        </div>


        <div class="all-border section-padding width-46-p">
            <div class="flex-spacebetween">
                <div class="tspHeading top-margin-5">
                    Shortlisted Transporters 
                    ({{selectedTspList?.length ? selectedTspList?.length : '0'}})
                </div>
                <div>
                    <form [formGroup]="searchInputGroup">
                        <div>
                            <div class="search-bar">
                                <img src="../../../../assets/images/search.svg">
                                <input class="search-input" matInput placeholder="Search" formControlName="inputValue">
                                <img *ngIf="searchInputGroup.get('inputValue').value.length >=1" class="cross_search"
                                    (click)="cancelSearch('RIGHT')" src="../../../../assets/images/cross-button.svg">
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="top-margin-5">
                <div class="select-all">
                    <mat-checkbox *ngIf="selectedTspList.length"  [indeterminate]="checkShorlistedSelections()" [(ngModel)]="select_all"
                        (ngModelChange)="onSelectAll($event,'shortlistSide')">Select all</mat-checkbox>
                </div>
                <div class="scroll-box-500" *ngIf="selectedTspList.length">
                    
                    <div *ngFor="let tsp of selectedTspList" class="display-flex">
                        <div class="checkbox-place col-16-width customCheckBox" *ngIf="!tsp.nonEditable">
                            <mat-checkbox [(ngModel)]="tsp.moveToOriginalList" class="top-margin-5">
                                <div class=" lanes-text">
                                    {{tsp?.companyName}}
                                </div>
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="box-500 default-selection-box"  *ngIf="!selectedTspList.length">
                    <p>Move Transporters from left Panel</p>
                </div>
            </div>
        </div>

    </div>

    <div class="col-md-12 center display-flex" *ngIf="showLoader">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>


</div>

<div *ngIf="!showLoader" class="dialog-box-pop top-border display-flex align-right">
    <div class="display-flex margin-left-auto">
        <button class="ff_button_32-130_secondary" 
            (click)="cancel()">Cancel</button>
        <button [disabled]="(selectedTspList?.length < 1)"
            class="ff_button_32-130 ff_leftM_24" (click)="submit()">Save</button>
    </div>
</div>