import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { waitForAsync } from '@angular/core/testing';
import * as turf from '@turf/turf';

@Component({
  selector: 'app-route-map',
  templateUrl: './route-map.component.html',
  styleUrls: ['./route-map.component.scss']
})
export class RouteMapComponent implements OnInit {

  // style = 'mapbox://styles/mapbox/dark-v10';
  // style = 'mapbox://styles/sandy-ff/cl4zasnr9001q14mqaf1p101i';
  style = 'mapbox://styles/sandy-ff/cl4zfbb8j000u14pdr73afvww';
  map: mapboxgl.Map;
  mapBoxApi: string = '';

  @Input() vehicleLocationData: any;

  constructor() {
    this.mapBoxApi = environment.mapBoxApi;
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken")?.set(this.mapBoxApi);
  }

  ngOnInit(): void {
    // this.renderMap();
  }

  ngOnChanges() {
    this.renderMap();
  }

  async renderMap() {
    let tmpColors = [
      '#219653', '#219653', '#F18C0C', '#219653', '#EB5757', '#EB5757'
    ];
    let start = this.vehicleLocationData['originCoordinates'];
    let end = this.vehicleLocationData['destinCoordinates'];
    let vehicleLocation = this.vehicleLocationData['vehicleCoordinates'];
    let wayPoints = this.vehicleLocationData['vehicleWayPoints'];
    let wayPointsCollection: any = {
      type: 'FeatureCollection',
      features: []
    };
    let vehicleAngle = this.vehicleLocationData.vehicleAngle;
    let routeCoordinates = [];
    routeCoordinates.push(start);
    for (let wayPoint of wayPoints) {
      let lat = parseFloat(wayPoint['coordinates'][0]);
      let long = parseFloat(wayPoint['coordinates'][1]);
      let color;
      let opacity = 1;
      let strokeOpacity = 0.2;
      if(wayPoint.speed > 10){
        color = tmpColors[0]
      }else if(wayPoint.speed >0 && wayPoint.speed <= 10){
        color = tmpColors[3]
      }else if(wayPoint.speed == 0 || wayPoint.speed == null){
        color = tmpColors[5];
        // opacity = 0;
        // strokeOpacity = 0;
      }
      wayPointsCollection.features.push({
        type: 'Feature',
        properties: {
          color: color,
          opacity: opacity,
          strokeOpacity: strokeOpacity,
          address: wayPoint.address,
        },
        geometry: {
          type: 'Point',
          coordinates: [lat, long]
        }
      });
      routeCoordinates.push([lat, long]);
    }
    let routeCollection:any = {
      type: 'FeatureCollection',
      features: [
        {
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: routeCoordinates
          }
        }
      ]
    };
    let originCollection: any = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {
          name: this.vehicleLocationData['origin']
        },
        geometry: {
          type: 'Point',
          coordinates: start
        }
      }]
    };
    let destinationCollection: any = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {
          name: this.vehicleLocationData['destination']
        },
        geometry: {
          type: 'Point',
          coordinates: end
        }
      }]
    };
    let vehicleLocationCollection: any = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        properties: {
          name: this.vehicleLocationData['vehicleNumber'],
          rotation: vehicleAngle
        },
        geometry: {
          type: 'Point',
          coordinates: vehicleLocation
        }
      }]
    };

    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      minZoom: 3.5,
      maxZoom: 18,
      zoom: 5,
      center: start,
    });

    let map = this.map;

    await loadImageFunction('cargoTruck.png', 'truck-icon');
    await loadImageFunction('dest_icon.png', 'dest-icon');

    function loadImageFunction(imageName, varName) {
      return new Promise(resolve => {
        map.loadImage('assets/images/' + imageName, (error, loadedImage) => {
          if (error) {
            resolve(false);
            throw error;
          }
          map.addImage(varName, loadedImage);
          return resolve(true);
        });
      });
    }

    map.on('load', () => {

      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      map.addSource('originSource', {
        'type': 'geojson',
        'data': originCollection
      });
      map.addSource('destinationSource', {
        'type': 'geojson',
        'data': destinationCollection
      });
      map.addSource('vehicleLocationSource', {
        'type': 'geojson',
        'data': vehicleLocationCollection
      });
      map.addSource('wayPointsSource', {
        'type': 'geojson',
        'data': wayPointsCollection
      });
      map.addSource('routeSource', {
        'type': 'geojson',
        'data': routeCollection
      });
      // Add Route
      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'routeSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#3887be',
          'line-width': 3,
          'line-opacity': 1
        }
      });
      // Add origin point to the map
      map.addLayer({
        id: 'point',
        type: 'circle',
        source: 'originSource',
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 1,
            5, 2,
            6, 3,
            7, 4,
          ],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#17709C',
          'circle-stroke-opacity': 1
        }
      });
      // Origin Name
      map.addLayer({
        'id': 'id-places',
        'type': 'symbol',
        'source': 'originSource',
        'layout': {
          'text-field': [
            'format',
            ['upcase', ['get', 'name']],
            { 'font-scale': 1 },
          ],
          'text-justify': 'left',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 10,
            5, 10,
            6, 12,
          ],
          // 'text-anchor': 'top',
          'text-transform': 'uppercase',
          'text-letter-spacing': 0.05,
          'text-offset': [0, 1.2],
          "text-allow-overlap": false
        },
        'paint': {
          'text-color': '#3E3E3E',
          'text-halo-color': '#ffffff',
          'text-halo-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 4,
            9, 4.5,
            10, 4.5,
          ]
        },
      });
      // destination point
      map.addLayer({
        id: 'destPoint',
        type: 'symbol',
        source: 'destinationSource',
        'layout': {
          'icon-image': 'dest-icon',
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0.4,
            5, 0.4,
            6, 0.4,
            7, 0.5,
          ],
          'icon-allow-overlap': true,
          'icon-offset': [0, -20],
        }
      });
      // destination name
      map.addLayer({
        'id': 'dest-places',
        'type': 'symbol',
        'source': 'destinationSource',
        'layout': {
          'text-field': [
            'format',
            ['upcase', ['get', 'name']],
            { 'font-scale': 1 },
          ],
          'text-justify': 'left',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 10,
            5, 10,
            6, 12,
          ],
          // 'text-anchor': 'top',
          'text-transform': 'uppercase',
          'text-letter-spacing': 0.05,
          'text-offset': [0, 1.2],
          "text-allow-overlap": false
        },
        'paint': {
          'text-color': '#3E3E3E',
          'text-halo-color': '#ffffff',
          'text-halo-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 4,
            9, 4.5,
            10, 4.5,
          ]
        },
      });
      // truck Radar
      map.addLayer({
        'id': 'truckPoint3',
        'type': 'symbol',
        'source': 'vehicleLocationSource',
        'layout': {
          'icon-image': 'pulsing-dot',
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 1,
            5, 1,
            6, 1,
            7, 1.5,
          ],
          'icon-allow-overlap': true,
          'icon-offset': [0, 0],
        }
      });

      // Way Points
      map.addLayer({
        id: 'wayPoints',
        type: 'circle',
        source: 'wayPointsSource',
        paint: {
          'circle-color': ['get', 'color'],
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0,
            5, 0,
            6, 0,
            7, 4,
            8, 5,
            9, 6,
          ],
          'circle-opacity': ['get', 'opacity'],
          'circle-stroke-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0,
            5, 0,
            6, 0,
            7, 1,
          ],
          'circle-stroke-color': ['get', 'color'],
          'circle-stroke-opacity': ['get', 'strokeOpacity']
        }
      });

      // truck image
      map.addLayer({
        id: 'truckPoint2',
        type: 'symbol',
        source: 'vehicleLocationSource',
        layout: {
          'icon-image': 'truck-icon',
          'icon-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            4, 0.1,
            5, 0.1,
            6, 0.1,
            7, 0.2,
          ],
          'icon-allow-overlap': true,
          'icon-offset': [0, 0],
          "icon-rotate": ['get', 'rotation']
        }
      });

      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      //way point pop up
      map.on('mouseenter', 'wayPoints', function (e) {
        this.getCanvas().style.cursor = 'pointer'
        var coordinates = e.features[0].geometry['coordinates'];
        var description = e.features[0].properties['address'];
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        popup.setLngLat(coordinates).setHTML(description).addTo(this);
      });

      map.on('mouseleave', 'wayPoints', function (e) {
        this.getCanvas().style.cursor = '';
        popup.remove();
      });
      
    });


    const size = 100;
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 4) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(255, 200, 200, ${1.5 - t})`;
        context.fill();

        // Draw the inner circle.
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        map.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      }
    };

    // async function getRoute(end) {
    //   // make a directions request using driving profile
    //   const query = await fetch(
    //     `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${environment.mapBoxApi}`,
    //     { method: 'GET' }
    //   );
    //   const json = await query.json();
    //   const data = json.routes[0];
    //   const route = data.geometry.coordinates;
    //   const geojson: any = {
    //     type: 'Feature',
    //     properties: {},
    //     geometry: {
    //       type: 'LineString',
    //       coordinates: route
    //     }
    //   };
    //   // if the route already exists on the map, we'll reset it using setData
    //   if (map.getSource('route')) {
    //     const source: mapboxgl.GeoJSONSource = map.getSource('route') as mapboxgl.GeoJSONSource
    //     source.setData(geojson);
    //   }
    //   // otherwise, we'll make a new request
    //   else {
    //     map.addLayer({
    //       id: 'route',
    //       type: 'line',
    //       source: {
    //         type: 'geojson',
    //         data: geojson
    //       },
    //       layout: {
    //         'line-join': 'round',
    //         'line-cap': 'round'
    //       },
    //       paint: {
    //         'line-color': '#3887be',
    //         'line-width': 3,
    //         'line-opacity': 1
    //       }
    //     });
    //   }
    // }

  }

}
