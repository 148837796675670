import { Component, OnInit } from '@angular/core';
import { ScriptService } from './shared/script.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'freightfox-ui';

  constructor(
    private scriptService: ScriptService,
  ){}

  ngOnInit(): void {
    this.scriptService.load('googleMaps')
      .then(data => {})
      .catch(error => {});
  }

}
