import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unloading-detention-view',
  templateUrl: './unloading-detention-view.component.html',
  styleUrls: ['./unloading-detention-view.component.scss']
})
export class UnloadingDetentionViewComponent implements OnInit {

  inTime: any;
  outTime: any;

  constructor(
    public dialogRef: MatDialogRef<UnloadingDetentionViewComponent>,
    @Inject(MAT_DIALOG_DATA) public obsData: any,
  ) { }

  ngOnInit(): void {
    this.inTime = this.obsData.destReportedTime;
    this.outTime = this.obsData.unloadingTime;
  }

  closeDialogBox(){
    let returnData = {
      status: 'CLOSED',
    }
    this.dialogRef.close(returnData);
  }

}
