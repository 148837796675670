import { Component } from '@angular/core';
import { ToasterService } from '../toaster.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {

  showToaster:boolean = false;
  toasterType:String ;
  type:any;
  heading:any;
  message:String = 'Success message';
  headerImg:any;
  navigateToUrl:any;
  buttonName:any;
  toasterSubscription: Subscription;

  constructor(
    public router: Router,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.toasterSubscription = this.toasterService.toasterState
    .subscribe( state => {
      this.toasterType = state.toasterType;
      this.type = state.type
      switch(this.type){
        case 'success':
          this.heading = 'Success';
          this.headerImg = '/assets/images/success-snack.svg';
          break;
        case 'error':
          this.heading = 'Error';
          this.headerImg = '/assets/images/error-snackbar.svg';
          break;
        case 'information':
          this.heading = 'Information';
          this.headerImg = '/assets/images/info-snackbar.svg';
          break;
        case 'info-warning':
          this.heading = 'Information';
          this.headerImg = '/assets/images/info-snackbar.svg';
          break;
        case 'warning':
          this.heading = 'Warning';
          this.headerImg = '/assets/images/warning-icon.svg';
          break;
      }
      this.message = state.message;
      this.showToaster = state.showToaster;
      this.navigateToUrl = state.navigateToUrl;
      this.buttonName = state.buttonName;
      setTimeout(() => {
        this.showToaster = false;
      }, 7000);
    })
  }

  ngOnDestroy(){
    this.toasterSubscription.unsubscribe();
  }

  closeToaster(){
    this.showToaster = false;
  }

  goToLink(){
    this.router.navigateByUrl(this.navigateToUrl);
    this.showToaster = false;
  }
}

