import { Input, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";

@Component({
  selector: 'ff_toolTip',
  templateUrl: './ff-tooltip.component.html',
  styleUrls: ['./ff-tooltip.component.scss']
})
export class FfTooltipComponent implements OnInit , OnDestroy{


  @Input() ff_message: string;
  @Input() ff_mousePosition: any;
  @Input() useExtendedWidth: any;

  popupXPosition = 10;
  popupYPosition = 10;
  
  
  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.ff_mousePosition){
        this.assignPopupPosition(this.ff_mousePosition);
      }
    }, 0);
  }

  assignPopupPosition(e) { 
    this.popupXPosition = e.x - e.leftPosition ; // 5 is offset from hover over div
    this.popupYPosition = e.y + 5; // 5 is offset from hover over div
  }

  ngOnDestroy(){
    this.ff_message = '';
  }

}
