<div class="form-wrapper">
  <div class="login-card-wrapper">
    <img class="logo-ring" src="/assets/images/logo-ring.svg" alt="">
    <div class="comp-logo">
      <img class="logo" src="assets/images/login-logo.svg">
      <p class="tag-line">Making Logistics your competitive Advantage!</p>
    </div>
    <div class="form-data">
      <div class="form-box">
        <div class="ff_header_tabs ">
          <ul class="ff_tab">
            <li [class]="activecls == 'login' ? 'active' : ''" (click)="switchTab('login')">
              <a>
                Login
              </a>
            </li>
            <li [class]="activecls == 'signup' ? 'active' : ''" (click)="switchTab('signup')">
              <a>
                Sign Up
              </a>
            </li>
          </ul>
        </div>
        <div class="" *ngIf="activecls == 'login'">
          <div *ngIf="!completed " class="loader-div">
            <div class="row">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
          <form class="login-form" [formGroup]="loginForm" *ngIf="completed" (submit)="submitLogin($event)">
            <div class="form-input-field" *ngIf="!usernameSubmitted">
              <mat-form-field class="full-width">
                <input id="emailInput" matInput placeholder="Enter E-Mail/Mobile Number"
                  formControlName="username" autocomplete="disabled">
                  <mat-error *ngIf="loginForm.get('username').errors?.required && loginForm.get('username').touched" class="text-danger">
                    E-Mail/Mobile Number is
                    required</mat-error>
                  <mat-error *ngIf="loginForm.get('username').errors?.pattern && loginForm.get('username').touched" class="text-danger">
                    E-Mail/Mobile Number is
                    invalid</mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="usernameSubmitted ">
              <p class="email-send-text"
                *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'otp'">Please enter
                the OTP sent to
                <span class="email">{{loginForm.get('username').value}} </span>
                <a class="change-email pointer" (click)="goBack()">CHANGE</a>
              </p>

              <p class="email-send-text"
                *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'password'">Please
                enter password for
                <span class="email">{{loginForm.get('username').value}} </span>
                <a class="change-email pointer" (click)="goBack()">CHANGE</a>
              </p>

              <div class="login-using"
                *ngIf="userData['loginModes']?.length && userData['loginModes']?.includes('OTP') && userData['loginModes']?.includes('PASSWORD')">
                <mat-radio-group aria-label="Select an option" formControlName="loginUsing"
                  (change)="loginUsingChanged()">
                  <mat-radio-button class="padding-left-0" value="otp">Generate OTP </mat-radio-button>
                  <mat-radio-button value="password">I've Password</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="top-margin-20"
                *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'otp'">
                <label class="otp-label">Enter OTP</label>
                <ng-otp-input id="otpInput" (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
                <div class="ff_flex otp-verfication-screen" *ngIf="otpSent && completed">
                  <span class="timer" *ngIf="!!otpInterval"> {{remainingTime?remainingTime:'00:00'}}</span>
                  <a [ngClass]="!otpInterval ?'resend-active':'resend-inactive'" class="pointer "
                    (click)="!otpInterval ?resendOTP():''">Resend OTP</a>
                </div>
              </div>

              <div class="top-margin-20"
                *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'password'">
                <mat-form-field class="full-width">
                  <input id="encyInput" matInput [type]="show ? 'text' : 'password'" placeholder="Enter Password"
                    formControlName="password">
                  <span matSuffix class="pass-show-hide pointer" (click)="show = !show"
                    [attr.aria-label]="'Show password'" [attr.aria-pressed]="show">{{!show?'Show':'Hide'}}</span>
                    <mat-error *ngIf="loginForm.get('password').errors?.required && loginForm.get('password').touched" class="text-danger">
                      Password is
                      required</mat-error>
                </mat-form-field>
              </div>
    
              <div class="ff_flex otp-verfication-screen"
                *ngIf="loginForm?.get('username')?.value && loginForm?.get('loginUsing')?.value == 'password'">
                <a class="pointer reset-account" (click)="reset()">Forgot password?</a>
              </div>
            </div>
            <button class="btn login-via-otp-btn top-margin-20" type="submit"
              (click)="!usernameSubmitted? usernameChange() : dataObject()">Continue </button>
            <p class="switch-to-signup">Don’t have an account? <a (click)="switchTab('signup')"
                class="pointer create-account">Create</a></p>
          </form>
        </div>
        <div class="" *ngIf="activecls == 'signup'" ngClass="signup-div">
          <div *ngIf="!completed" class="loader-div">
            <div class="row">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>

          <form class="login-form" [formGroup]="signUpForm" autocomplete="off" *ngIf="completed" (submit)="submitSignUp($event)">
            <div class="form-input-field">
              <mat-form-field class="full-width">
                <input matInput class=" placeholder" placeholder="Enter E-Mail" formControlName="email">
                <mat-error *ngIf="signUpForm.get('email').errors?.required && signUpForm.get('email').touched" class="text-danger">
                  E-Mail is
                  required</mat-error>
                <mat-error *ngIf="signUpForm.get('email').errors?.pattern && signUpForm.get('email').touched" class="text-danger">
                  E-Mail is
                  invalid</mat-error>
              </mat-form-field>
            </div>

            <div class="form-input-field">
              <mat-form-field class="full-width">
                <input matInput class=" placeholder" placeholder="Enter Mobile Number" formControlName="mobile">
                <mat-error *ngIf="signUpForm.get('mobile').errors?.required && signUpForm.get('mobile').touched" class="text-danger">
                  Mobile Number is
                  required</mat-error>
                <mat-error *ngIf="signUpForm.get('mobile').errors?.pattern && signUpForm.get('mobile').touched" class="text-danger">
                  Mobile Number is
                  invalid</mat-error>
              </mat-form-field>
            </div>

            <div class="form-input-field">
              <mat-form-field class="full-width">
                <input matInput autocomplete="false" class=" placeholder caps-data" placeholder="Enter Company PAN"
                  formControlName="pan">
                  <mat-error *ngIf="signUpForm.get('pan').errors?.required && signUpForm.get('pan').touched" class="text-danger">
                    PAN is
                    required</mat-error>
                  <mat-error *ngIf="signUpForm.get('pan').errors?.pattern && signUpForm.get('pan').touched" class="text-danger">
                    PAN is
                    invalid</mat-error>
              </mat-form-field>
            </div>

            <div class="form-input-field">
              <mat-form-field class="full-width">
                <input matInput autocomplete="false" [type]="show ? 'text' : 'password'" placeholder="Enter Password"
                  formControlName="password">
                <span matSuffix class="pass-show-hide pointer" (click)="show = !show"
                  [attr.aria-label]="'Show password'" [attr.aria-pressed]="show">{{!show?'Show':'Hide'}}</span>
                  <mat-error *ngIf="signUpForm.get('password').errors?.required && signUpForm.get('password').touched" class="text-danger">
                    Password is
                    required</mat-error>
              </mat-form-field>
            </div>

            <div class="form-input-field">
              <mat-form-field class="full-width">
                <input matInput autocomplete="off" [type]="showRepassword ? 'text' : 'password'"
                  placeholder="Re-Enter Password" formControlName="repassword">
                <span matSuffix class="pass-show-hide pointer" (click)="showRepassword = !showRepassword"
                  [attr.aria-label]="'Show password'"
                  [attr.aria-pressed]="showRepassword">{{!showRepassword?'Show':'Hide'}}</span>
                  <mat-error *ngIf="signUpForm.get('repassword').errors?.required && signUpForm.get('repassword').touched" class="text-danger">
                    Re-Password is
                    required</mat-error>
                  <mat-error
                    *ngIf="signUpForm.get('password').value && (signUpForm.get('password').value != signUpForm.get('repassword').value)"
                    class="text-danger">
                    Password and Re-Password should be same
                  </mat-error>
              </mat-form-field>
            </div>
            <button class="btn login-via-otp-btn top-margin-40" (click)="registerUser()">Register</button>
            <p class="switch-to-signup">Already Registered?<a (click)="switchTab('login')"
                class="pointer create-account">Login</a></p>
          </form>
        </div>
      </div>
    </div>
  </div>
