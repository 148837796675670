import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appReadonlyCheckbox]'
})
export class ReadonlyCheckboxDirective {

  constructor() { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    // Prevent the default click behavior
    event.preventDefault();
  }

}
