<div class="ff_dialog_box">
    <div class="ff_header">
        <div class="ff_title">
            Import Status
        </div>
        <img src="/assets/images/cross-button.svg" alt="Close" (click)="closeDialogBox()" class="ff_close_icon">
    </div>
    <div class="ff_content">
        <div class="msg-wrpr">
            <img *ngIf="rejectedPayloadLength == 0" class='cnfrm-tick' src="../../../../../assets/images/tick-green-stroke.svg" alt="">
            <img *ngIf="rejectedPayloadLength > 0" class='cnfrm-tick' src="../../../../../assets/images/error-red-stroke.svg" alt="">
            <p class="success-rcrds">{{confirmedOperationsLength}} {{confirmedOperationsLength > 1 ? 'records' : 'record'}} successfully imported</p>
            <p *ngIf="rejectedPayloadLength > 0"
                class="failed-rcrds">
                {{rejectedPayloadLength}} {{rejectedPayloadLength > 1 ? 'records' : 'record'}} failed to import
            </p>
            <button *ngIf="rejectedPayloadLength > 0" class="ff_button_32_secondary"
                (click)="exportFailedRecords()">
                <img src="../../../../../assets/images/download.svg" alt="">
                Failed Records
            </button>
        </div>
    </div>
    <div class="ff_footer">
        <div class="ff_action_buttons">
            <button (click)="closeDialogBox()" class="ff_button_32">
                Close
            </button>
        </div>
    </div>
</div>