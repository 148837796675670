<div class="dialog-box-pop">
  <div class="left-right-ends">
    <div class="head-text-dialog">Transporter Partners</div>
    <div>
      <img
        class="pointer"
        src="/assets/images/cross-button.svg"
        (click)="cancel()"
      />
    </div>
  </div>
  
  <div class="ff_flex_space_bw sub-headers top-margin-5">
      <div>{{allTsp?.length}} Transporters</div>
      <button  *ngIf="data?.addTspMode"
      class="ff_button_32_transparent" (click)="addNewTsps()">
      <img class="add-tsp-icon" src="../../../../assets/images/add-icon.svg" alt="">
      Add Transporters
  </button>
  </div>
  <!-- <form [formGroup]="searchInput">
      <div class="top-margin-5 ff-form-theme">
        
          <span matPrefix class="search-sign"><img src="../../../../assets/images/search.svg"></span>
                <input matInput  class="search-input"  placeholder="Search a Transporter" formControlName="inputValue" ngDefaultControl>
       
      </div>
  </form> -->
  <form [formGroup]="searchInput">
    <div class="top-margin-5">
            <div class="search-bar">
              <img style="margin-left: 7px;" src="../../../../assets/images/search.svg">
              <input class="search-input" matInput placeholder="Search a Transporter" formControlName="inputValue">
            </div>
    </div>
</form>
  <div class="top-margin-5 scroll-box-500" >
      <div *ngFor="let tspdata of tsp ;let i = index" class="ff_flex_space_bw">
       <p class="ff_flex tsp-data">  {{tspdata?.companyName}}</p>
       <img class="width-17 pointer" *ngIf="data?.deleteMode && tsp.length != 1" (click)="deleteTsp(tspdata)" src="/assets/images/delete.svg">
      </div>
  </div>
</div>

<div class="action-btn top-border display-flex align-right">
<div class="display-flex margin-left-auto">
  <div>
    <button  class="ff_button_32_secondary" (click)="cancel()">Close</button>
  </div>
</div>
</div>
