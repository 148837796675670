

<div class="form-wrapper">
    <div class="text-data">
      <div class="welcome-data">
        <p class="welcome">Welcome To</p>
        <img class="img-responsive " src="assets/images/login-logo.svg">
        <p class="tag-line">Making Logistics your competitive Advantage!</p>
      </div>
    </div>
    <div class="form-data">
      <div class="form-box">
          <h2 class="heading-data">Reset Password</h2>
          <form [formGroup]="resetPasswordForm" (submit)="submitResetPassword($event)">
            <div class="ff-sm-field login-form top-margin-20">
                <mat-form-field class="col-md-12 no-padding height-50">
                  <input matInput class=" placeholder"
                    placeholder="Enter E-Mail" formControlName="email">
                </mat-form-field>
                <mat-error *ngIf="resetPasswordForm.get('email').errors?.required && formSubmitted" class="text-danger">
                  E-Mail is required</mat-error>
                <mat-error *ngIf="resetPasswordForm.get('email').errors?.pattern && formSubmitted" class="text-danger">
                  E-Mail is invalid</mat-error>
              </div>
              <button class="btn login-via-otp-btn top-margin-40" (click)="sendOtp()">Continue</button>
            <p class="switch-to-signup">Remember your password? <a (click)="goTologin()"
                class="pointer create-account">Continue</a></p>
        </form>
        </div>
    </div>
  </div>





