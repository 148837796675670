import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Chart } from 'chart.js'

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.scss']
})
export class LinechartComponent implements OnInit {
  @ViewChild('canvas', { static: true }) ctx: ElementRef;
  viewInit: any = false;
  highestTime; any = {};
  chart: any;
  chartData: any = { datasets: [] };
  backgroundColor: any = [
    'rgba(241, 14, 82, 0.2)',
    'rgba(19, 185, 236, 0.2)',
    'rgba(207, 241, 14, 0.2)',
    'rgba(41, 241, 14, 0.2)',
  ];
  borderColor: any = [
    'rgba(241, 14, 82, 1)',
    'rgba(19, 185, 236, 1)',
    'rgba(207, 241, 14, 1)',
    'rgba(41, 241, 14, 1)',
  ];
  @Input() title: any = "";
  @Input() fill: any = false;
  @Input('line-tension') lineTension: any = 0;
  @Input('border-width') borderWidth: any = 2;
  @Input('border-width') hieght: any = 100;   
  @Input() datasets: any = [];
  @Output() chartchanged = new EventEmitter<any>();

  dataChanged(items) {
    if (Array.isArray(items) && items.length > 0) {
      if(this.chart) {
        this.chart.destroy();
        this.chart = null;
        this.chartData = { datasets: [] };
      }
      if (!this.chart) {
        items.forEach((dat, index) => {
          let dataset = {
            "label": dat.label,
            "backgroundColor": dat.backgroundColor ? dat.backgroundColor : [this.backgroundColor[index % 4]],
            "borderColor": dat.borderColor ? dat.borderColor : [this.borderColor[index % 4]],
            "borderWidth": this.borderWidth,
            "lineTension": this.lineTension,
            "fill": this.fill,
            "pointRadius": 2,
            "data": dat.data
          };
          this.chartData.datasets.push(dataset);
          /*if(!this.highestTime) {
            this.highestTime = {};
          }
          if(!this.highestTime[dat.label]) {
            let highest = dat.data[0].x;
            this.highestTime[dat.label] = highest;
            dat.data.forEach(element => {
              if(element.x > highest) {
                this.highestTime[dat.label] = highest;
              }
            });
          }*/
        });
        this.drawChart();
      } 
      /*else {
        items.forEach(dat => {
          dat.data.forEach(element => {
            if(this.highestTime[dat.label] && this.highestTime[dat.label] < element.x) {
              let set = this.chartData.datasets.find(item => {return dat.label === item.label});
              if(set) {
                set.data.push(element);
                this.highestTime[dat.label] = element.x;
              }
            }
          });
        });
        this.chart.update();
      } */
      this.chartchanged.emit(this.chartData);
    }
  }

  /*
  testData: any = {
    "datasets": [
      {
        "label": "18 MT - Open",
        "backgroundColor": [
          "rgba(241, 14, 82, 0.2)"
        ],
        "borderColor": [
          "rgba(241, 14, 82, 1)"
        ],
        "borderWidth": 1,
        "lineTension": 0,
        "fill": false,
        "data": [
          {
            "x": 1591148660857,
            "y": 40100
          },
          {
            "x": 1591148960857,
            "y": 32600
          },
          {
            "x": 1591149260857,
            "y": 32600
          },
          {
            "x": 1591149560857,
            "y": 32600
          },
          {
            "x": 1591149860857,
            "y": 29700
          }
        ]
      },
      {
        "label": "21 MT - Open",
        "backgroundColor": [
          "rgba(207, 241, 14, 0.2)"
        ],
        "borderColor": [
          "rgba(207, 241, 14, 1)"
        ],
        "borderWidth": 1,
        "lineTension": 0,
        "fill": false,
        "data": [
          {
            "x": 1591148360857,
            "y": 50000
          },
          {
            "x": 1591148660857,
            "y": 43000
          },
          {
            "x": 1591148960857,
            "y": 32900
          },
          {
            "x": 1591149260857,
            "y": 32900
          },
          {
            "x": 1591149560857,
            "y": 32900
          }
        ]
      }
    ]
  }
  */

  constructor() { }

  ngOnInit() {
    if (this.datasets) {
      this.datasets.subscribe(data => {
        this.dataChanged(data);
      });
    }
  }

  ngAfterViewInit() {
    this.viewInit = true;
  }

  drawChart() {
    if (this.viewInit) {
      this.chart = new Chart(this.ctx.nativeElement, {
        type: 'line',
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        data: this.chartData,
        options: {
          responsive: true,
          animation: {
            duration: 0
          },
          title: {
            display: true,
            text: this.title,
          },
          tooltips: {
            mode: 'x',
            intersect: false,
            position: 'nearest',
            displayColors: false,
            callbacks: {
              label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                      label += ' : ';
                  }
                  label += tooltipItem.yLabel;
                  return label;
              }
            }
          },
          layout: {
            padding: {
                left: 0,
                right: 20,
                top: 0,
                bottom: 0
            }
          },
          scales: {
            xAxes: [{
              type: 'time',
              stepSize: 5,
              time: {
                unit: 'minute',
                displayFormats: {
                  minute: 'h:mm a'
                }
              }
            }]
          }
        }
      });
    }
  }

}
