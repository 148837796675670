import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  current_page:any = 1;
  @Input() page_size:any = 10;
  @Input() count:any = 0;
  last_page: number;
  @Output() pagechange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.pageSize(this.count);
    this.pagechange.emit({pageIndex: 1, pageSize: this.page_size});
  }

  ngOnChanges() {
    this.pageSize(this.count);
  }

  reset() {
    this.current_page = 1;
    this.pagechange.emit({pageIndex: 1, pageSize: this.page_size});
  }

  emitPageChange() {
    this.pagechange.emit({pageIndex: this.current_page, pageSize: this.page_size});
  }

  nextPage(){
    this.current_page = this.current_page + 1;
    this.emitPageChange();
  }
  previousPage(){
    this.current_page = this.current_page - 1;
    this.emitPageChange();
  }

  goToFirstPage(){
    this.current_page = 1;
    this.emitPageChange();
  }
  GoToLastPage(){
    this.current_page = this.last_page;
    this.emitPageChange();
  }

  pageSize(total_count){
    this.last_page = Math.ceil(total_count/this.page_size);
  }

}
