import { 
    Pipe, 
    PipeTransform 
 } from '@angular/core';  
 
 @Pipe ({ 
    name: 'wordCount' 
 }) 
 
 export class WordCount implements PipeTransform { 
    transform(value: any) { 
        let count = 0;
        if(value){
            count = (value.match(/\S+/g) || []).length;
            return count;
        }

        return count;
    } 
 } 