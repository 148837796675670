import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'src/app/admin/admin.service';

@Component({
  selector: 'app-ff-confirmation',
  templateUrl: './ff-confirmation.component.html',
  styleUrls: ['./ff-confirmation.component.scss']
})
export class FfConfirmationComponent implements OnInit {

  header:any;
  submitButtonText:any;
  detailText:String;
  secondaryButtonText:any;

  constructor(
    public dialogRef: MatDialogRef<FfConfirmationComponent>,
    private adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.header = this.data.header;
    this.submitButtonText = this.data.submitButtonText ? this.data.submitButtonText : 'Confirm';
    this.secondaryButtonText = this.data.secondaryButtonText ? this.data.secondaryButtonText : 'Cancel';
    this.detailText = this.data.detailText;
  }

  submitForm(){
    this.dialogRef.close('CONFIRM');
  }

  close(): void {
    this.dialogRef.close('CLOSE');
  }

}
