import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface item {
  key: string | number;
  value: string | number;
  isSelected: boolean;
  subItems: any;
}


@Component({
  selector: 'app-single-group-multi-select',
  templateUrl: './single-group-multi-select.component.html',
  styleUrls: ['./single-group-multi-select.component.scss']
})
export class SingleGroupMultiSelectComponent implements OnInit {

  select_all: boolean = false;
  itemList: item[];
  searchText: string = '';
  isSearchFound: boolean = true;
  activeGroupIndex:number = undefined;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputItemList: item[];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.itemList = [];
    this.itemList = JSON.parse(JSON.stringify(this.inputItemList));
    this.checkAndSetActiveGroup();
  }

  checkAndSetActiveGroup(){
    for(let [index, item] of this.itemList.entries()){
      if(item.isSelected){
        this.activeGroupIndex = index;
        break;
      }
    }
  }

  setActiveGroup(index){
    this.itemList.forEach(group => {
      group.isSelected = false;
      group.subItems.forEach(subItem => {
        subItem['isSelected'] = false;
      });
    });
    this.activeGroupIndex = index;
    this.itemList[index].isSelected = true;
  }

  submit() {
    this.action.next(
      {
        applyFilter: true,
        filters: this.itemList
      }
    );
  }

  cancel() {
    this.action.next(
      {
        applyFilter: false
      }
    );
  }

}
