import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e840b42c1d27478781a1d13d8568bbeb@sentry.freightfox.ai/6",
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["freightfox.ai/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.production ? 'production' : 'dev',
  release: 'taurus-1.0',
  tracesSampleRate: environment.production ? 0.1 : 0.01,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
}).catch(err => {});
