<div *ngIf="last_page && last_page > 1" class="col-md-12 pagination-margin display-flex">

    <span class="btn-groups auto-margin">
        <button class="material-icons pagination-btn" (click)="goToFirstPage()"
            [disabled]="current_page == '1'" matTooltip="First page" matTooltipPosition="above">
            skip_previous
        </button>
        <button class="material-icons pagination-btn" (click)="previousPage()"
            [disabled]="current_page == '1'" matTooltip="Previous" matTooltipPosition="above">
            keyboard_arrow_left
        </button>

        &nbsp; &nbsp;&nbsp; &nbsp;
        <button class="material-icons pagination-btn" [disabled]="last_page == current_page"
            (click)="nextPage()" matTooltip="Next" matTooltipPosition="above">
            keyboard_arrow_right
        </button>
        <button class="material-icons pagination-btn" [disabled]="last_page == current_page"
            (click)="GoToLastPage()" matTooltip="Last Page" matTooltipPosition="above">
            skip_next
        </button>
    </span>
</div>