import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-minutes-picker',
  templateUrl: './minutes-picker.component.html',
  styleUrls: ['./minutes-picker.component.scss']
})
export class MinutesPickerComponent implements OnInit {

  selectedHour: any;
  selectedMinute: any;
  selectedZone: any;
  timeHour: any = [];
  timeMinutes: any = [];
  timeZone: any = ['AM', 'PM'];
  time: any;
  afterNoon: boolean = false;
 
  // step:number = 15;
  @ViewChild('customTip', { static: false }) menu: ElementRef;
  @Output() onTimePicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() dateSelected: string;
  @Input() restrictFuture: boolean;
  @Input() step: number;
  @Input() startingHour;
  constructor(
    private renderer: Renderer2,
  ) {

  }

  ngOnInit() {
    if(!this.step){
      this.step = 1;
    }
    for (let i = this.startingHour; i <= 23; i++) {
      this.timeHour.push(i);
    }
    for (let i = 0; i < 60; i += this.step) {
      this.timeMinutes.push(i);
    }

  }

  isTodaysDate() {
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let placeDate = new Date(this.dateSelected);
    return ((placeDate.getDate() === currentTime.getDate())
      && (placeDate.getMonth() === currentTime.getMonth())
      && (placeDate.getFullYear() === currentTime.getFullYear()));
  }

  selectHour(hour) {
    this.selectedHour = hour;
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let hrs = currentTime.getHours();
    let mins = currentTime.getMinutes();


    if (this.selectedMinute) {
      this.selectMin(this.selectedMinute);
    }
    this.checkClose();
  }

  selectMin(minutes) {
    this.selectedMinute = minutes;
    // let currentTime = new Date(2021, 7, 12, 13, 30, 30, 0);
    let currentTime = new Date();
    let hrs = currentTime.getHours();
    let mins = currentTime.getMinutes();


    this.checkClose();
  }


  submitTime() {
    this.time = {
      minute: this.selectedMinute
    };
    this.onTimePicked.emit(this.time);
  }

  checkClose() {
    if ((this.selectedMinute || this.selectedMinute == 0)) {
      setTimeout(() => {
        this.submitTime();
      }, 150);
    }
  }

}
