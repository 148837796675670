import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/internal/operators';
import { of } from 'rxjs/observable/of';
import { StorageService } from '../auth/storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransporterService {
  prodUrl = environment.apiBaseUrl;
  turingUrl = environment.turingUrl;
  s3Url = environment.s3baseUrl;
  papyrusUrl = environment.papyrusUrl;
  errorData: {};
  bidmetadata:any;
  public isApproved: any;
  orgName: any; //To update Top Nav in TSP onboarding
  currentuserdetails:any ;
  state_url = '/assets/json/States.json';
  metadata;

  topNavOrgName = new Subject<any>();

  public allextension: any = {
    basicprofile: '/profile/basic',
    contactprofile:'/profile/contact',
    operationDetails:'/profile/operational',
    financialprofile:'/profile/financial',
    technicalprofile:'/profile/technical',
    referenceprofile:'/profile/reference'
   
  };
  constructor(private router: Router, private http: HttpClient, private storage: StorageService) { }

  getBidList(pageSize, pageIndex, sortOrder, sortColumn, filters,gstin): Observable<string> {
    return this.getAllBidList(pageSize, pageIndex, sortOrder, sortColumn, filters, false,gstin);
  }

  getAllBidList(pageSize, pageIndex, sortOrder, sortColumn, filters,all,gstin): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      let data = {};
      if(all) {
          data = {
          "pageSize": pageSize
        }
      }
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ gstin +'/auctions/search', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getspotAuctionList(data,gstin): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ gstin +'/spot-auctions/search', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getspotAuctionsummary(data,gstin): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ gstin +'/spot-auctions/summary', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  downloadDoc(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/auction/'+ id +'/auction/'+ 'terms', {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
           
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getConsent(gstin, id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/consent/transporter/'+ gstin +'/auction/'+ id, {
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
           
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getSpotConsent(data,gstin): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken || sessiontoken != '') {
     
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/consent/transporter/'+ gstin +'/spot-auctions/search',data,
        {
          headers: options,
        })
        .pipe(
          map(data => {
            let dataToCache = data;
           
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  postSpotConsent(data,gstin): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/consent/transporter/'+ gstin +'/spot-auctions',data,
        {
          headers: options,
        })
        .pipe(
          map(data => {
            let dataToCache = data;
           
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getDetails(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http
        .get<any>(this.prodUrl +'onboarding/api/v1/auction/' + id, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getSubmittedBids(id, searchData) : Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(`${this.prodUrl}onboarding/api/v1/auction/${id}/org/${gstin}/share/search`, searchData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getStatsData(id):Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
      if (sessiontoken || sessiontoken != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .get<any>(this.prodUrl +'onboarding/api/v1/auction/'+id+'/stats', {
            headers: options,
          })
          .pipe(
              map(data => {
              return data;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
  }


  getBidFilterMetadata(): Observable<any> {
    if(!this.bidmetadata) {
      var sessiontoken = this.storage.getItem('access-token', false);
      if (sessiontoken || sessiontoken != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .get<any>(this.prodUrl +'onboarding/api/v1/transporters/auctions/filters/metadata', {
            headers: options
          })
          .pipe(
              map(data => {
              this.bidmetadata = data;
              return Object.assign({}, this.bidmetadata);
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
    } else {
      return of(Object.assign({}, this.bidmetadata));
    }
  }

  getTspIndentFilters(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let orgId = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/transporter/${orgId}/indents/filters/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentAlternateVeh(indentId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let orgId = user.organization.id;
      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/${indentId}/alternate-vehicle-suggestions`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  alterVehicleProposal(indentId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let orgId = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${indentId}/alternate-vehicle-suggestion`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  submitBid(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/spot-auction/'+ id +'/bid', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }

  getspotGroupsSubmittedbids(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ gstin +'/spot-auctions/bids/_search', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }

  reportTripCompletion(vehicleId, postData): Observable<string>{
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http   
        .post<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ vehicleId +'/completion', postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }

  uploadEpodCompletion(bodydata,id): Observable<string>{
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http   
        .put<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ id +'/epod', bodydata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }

  reportIncidenceCompletion(bodydata,id): Observable<string>{
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ id +'/incident', bodydata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }

  editVehicleDetail(data,id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ id , data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  tspMarkVehicleReachedToDestination(id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ id + '/reached', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  tspMarkVehicleUnloadedAtDestination(id, data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl +'lmnop/api/v1/indent/vehicle/'+ id + '/unloaded', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addVehicleDetail(data,id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl +'lmnop/api/v1/indent/'+ id + '/vehicle' , data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addConsent(gstin,id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http   
        .post<any>(this.prodUrl +'onboarding/api/v1/consent/transporter/'+ gstin +'/auction/'+id, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  getMetadata(): Observable<any> {
   
      var sessiontoken = this.storage.getItem('access-token', false);
      if (sessiontoken || sessiontoken != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .get<any>(this.prodUrl +'onboarding/api/v1/metadata', {
            headers: options
          })
          .pipe(
              map(data => {
              this.metadata = data;
              return this.metadata;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
  }

  getFilteredMetadata(payload): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/metadata/search', payload, {
          headers: options
        })
        .pipe(
          map(data => {
            this.metadata = data;
            return this.metadata;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getEpodListing(data): Observable<any> {
      var sessiontoken = this.storage.getItem('access-token', false);
      let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
      if (sessiontoken || sessiontoken != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .post<any>(this.prodUrl +'lmnop/api/v1/transporter/'+ user.organization.id +'/indent/vehicle/epod/_search',data, {
            headers: options
          })
          .pipe(
              map(data => {
              let dataToCache = data;
              return dataToCache;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
    }

  // get Detention Rates 
  getDetentionRates(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `kremlin/api/v1/transporter/${orgId}/detention-charge-groups/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get Detention Rates 
  getLoadingDetentionCuttoffTime(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `kremlin/api/v1/manufacturer/${orgId}/detention/cut-off/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  // get breakage allowance 
  getMfgBreakageAllowance(mfgOrgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.papyrusUrl + `/papyrus/api/v1/org/${mfgOrgId}/breakageLeakageAllowance/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getMfgContractRate(mfgOrgId, freightCode): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.papyrusUrl + `/papyrus/api/v1/org/${mfgOrgId}/freight/${freightCode}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getApplicableTdsRate(mfgOrgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.papyrusUrl + `/papyrus/api/v1/org/${mfgOrgId}/tds/_search?isPivoted=false`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getTspTotalInvoiceValueForTds(tspOrgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/transporter/${tspOrgId}/total-invoice-value`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getInvoiceSettings(mfgOrgId): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${mfgOrgId}/invoice/settings`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getEpodDetails(epodId): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.turingUrl + `/turing-teller/api/v1/indent/vehicle/epod/${epodId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTspInvoiceFilters(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/transporter/${orgId}/indent/vehicle/epod/filters/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTspInvoiceCounters(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/transporter/${orgId}/indent/vehicle/epod/_counters`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getTspInvoiceListing(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/transporter/${orgId}/indent/vehicle/epod/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createInvoiceEpod(vehicleId, payload){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/indent/vehicle/${vehicleId}/epod`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // generateIndentInvoice
  generateIndentInvoice(epodId, data): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/indent/epod/${epodId}/invoice`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get EPOD Details 
  getInvoiceDetails(invoiceId): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.turingUrl + `/turing-teller/api/v1/indent/invoice/${invoiceId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // update invoice Details 
  updateInvoiceByTsp(invoiceId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.turingUrl + `/turing-teller/api/v1/indent/invoice/${invoiceId}`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  saveTransporter(data,gstin,service): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ gstin + this.allextension[service] , data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //add transporter details..
  add_BSID_RFI(data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '' ) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let id = '1';
      // http://{{onboarding_host}}/onboarding/api/v1/rfi/:id/operation-info
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/rfi/${id}/operation-info`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // EDIT BSID LANE
  edit_BSID_LANE(data,id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfi/operation-info/:id
      return this.http
        .put<any>(this.prodUrl +'onboarding/api/v1/rfi/operation-info/'+ id , data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //DELETE BSID_LANE
  delete_BSID_LANE(id): Observable<any>{
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      // onboarding/api/v1/rfi/operation-info/:id
      return this.http
      .delete<any>(this.prodUrl +'onboarding/api/v1/rfi/operation-info/'+ id , {
        headers: options
      })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //add transporter details..
  get_RFI_INFO(GSTIN): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let id = '1';
      // onboarding/api/v1/transporter/:orgId/rfi/:id
      // .get<any>(this.prodUrl + 'onboarding/api/v1/auction/'+ id +'/auction/'+ 'terms', 
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/transporter/' + GSTIN +'/rfi/' + id , {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTransportersBidding(data, gstin): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '' ) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturers/${gstin}/auctions/share/search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getOnboardingData(data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/transporters/'+ data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            this.isApproved = dataToCache.isApproved;
            this.orgName = dataToCache.basicInfo.companyName;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  callTopNavUpdate(){
    this.topNavOrgName.next(this.orgName);
  }

  allStates(): Observable<any>{
    return this.http.get(this.state_url);
  }
  
  fileUpload(type,file,gstin): Observable<any>{
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      options.append("Content-Type", "multipart/form-data");
      var formdata = new FormData();
      formdata.append(type, file, file.name);
      return this.http
      .put<any>(this.prodUrl +'onboarding/api/v1/org/'+ gstin + '/profile/documents' ,formdata, {
        headers: options
      })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadfile(key, file) {
    // let sessiontoken = this.storage.getItem('access-token', false);
    // let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    // if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders()
    options.append("Content-Type", "multipart/form-data");
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    return this.http
      .post<any>(`${this.s3Url}doraemon/api/v1/document`, formdata)
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
  
  deleteDocData(name,gstin): Observable<any>{
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      return this.http
      .delete<any>(this.prodUrl +'onboarding/api/v1/org/'+ gstin + '/profile/documents/' + name , {
        headers: options
      })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  downloadOnboardingDoc(gstin: string, name: string): Observable<Blob> {
    const sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken) {
      const headers = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http.get(this.prodUrl + 'onboarding/api/v1/org/' + gstin + '/profile/documents/' + name, {
        headers: headers,
        responseType: 'blob'  // Set response type to blob
      }).pipe(
        catchError(this.handleError.bind(this))
      );
    } else {
      return throwError(() => new Error('No session token available'));
    }
  }

  //for deleteing the vehicle from manufacture side
  deleteIndentVehicle(vehId, data): Observable<any>{
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);      
      return this.http
      .post<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${vehId}/reject`, data, {
        headers: options
      })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get In-Transit Vehicle
  getVehicleInTransitData(data): Observable<string>{
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '' ) {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http   
        .post<any>(`${this.prodUrl}lmnop/api/v1/transporter/${gstin}/inward-vehicles/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;          
            return dataToCache;
          }),
          catchError(this.handleErrorWithResponse.bind(this))
        ) as Observable<any>;
    }
  }


  private handleError(error: any) {
    this.isValidToken(error);
    if (error.status === 500) {
      return throwError('Internal Server Error');
    }else{
      return throwError(error);
    }
  }

  private handleErrorWithResponse(error: HttpErrorResponse) {
    this.isValidToken(error);
    return throwError(error);
  }

  private isValidToken(error: HttpErrorResponse) {
    if(error.status === 401) {
      this.storage.removeItem('access-token');
      this.storage.removeItem('user');
      this.router.navigateByUrl('/login');
    }
  }

  //GET RFQ RESPONSE LISTING FOR TSP
  getRfqResponseListing(orgId, postData){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${orgId}/rfq/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getGroupAuction(status,id){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let bodydata = {
          "pageSize": 100,
          "pageIndex": 1,
          "sortPath": "startTime",
          "sortOrder": "ASC",   
            "filters": {
                "status": [status]
            }
      }
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${id}/contract-auction-groups/_search`,bodydata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createCellingAcceptance(Id,bodydata){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/accept/odvt-ceiling-price/${Id}`,bodydata, {
          
          headers: options 
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  tspRfqNoticeAcceptance(orgId,noticeId,bodydata){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${orgId}/rfq-notice/${noticeId}/submit`, bodydata, {
          headers: options 
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  acceptRfqGroupCeilingPrices(orgId, rfqGroupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/accept/transporter/${orgId}/rfqGroup/${rfqGroupId}/ceiling-prices`, {
          headers: options 
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getLaneData(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getgroupSubmittedbids(groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/transporter/${user.organization.id}/contract-auction-group/${groupId}/bids`, {}, {
          headers: options 
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTspsAuctionBids(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${user.organization.id}/contract-auction/target-price/group/${id}/bids`, {}, {
          headers: options 
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getGroupConsent(orgId,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/consent/transporter/${orgId}/contract-auction-group/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  postGroupConsent(orgId,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/consent/transporter/${orgId}/contract-auction-group/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  submitLaneBid(bodydata,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-lane/${id}/bid`,bodydata, {
     
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addRFQResponses(rfqID,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqID}/response-document`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET RFQ details
  getRfqDetails(rfqId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  checkRfqValidation(gstin,rfqId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/transporter/${gstin}/rfq/${rfqId}/version-response-summary`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

    //GET RFQ details
    getRfqDetailsForTsp(rfqId){

      let sessiontoken = this.storage.getItem('access-token', false);
      let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
      if (sessiontoken && sessiontoken != '' && user && user != '') {
  
        let options = new HttpHeaders().set('Authorization', sessiontoken);
  
        return this.http
          .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}`, {
            headers: options
          })
          .pipe(
              map(data => {
              let dataToCache = data;
              return dataToCache;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
    
    }

    // get Tsp RFQ Response Document
    getTspRfqResponseDoc(tspOrgId, rfqId, isFetchLatest:boolean){
      let sessiontoken = this.storage.getItem('access-token', false);
      let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
      if (sessiontoken && sessiontoken != '' && user && user != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .get<any>(`${this.prodUrl}kremlin/api/v1/transporter/${tspOrgId}/rfq/${rfqId}/response-document?fetchLatest=${isFetchLatest}`, {
            headers: options
          })
          .pipe(
              map(data => {
              let dataToCache = data;
              return dataToCache;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
    }

  //GET RFQ Vehicles
  getRfqVehicles(rfqId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicles`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  //GET RFQ Lane LISTING DATA FOR TSP
  getRfqLaneLIstingTSP(rfqId, filters){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/rfq/${rfqId}/lane-reqs`, filters, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET RFQ Lane Labels
  getRfqLabelsTsp(rfqId, filters){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfq/:id/lane-reqs/filters/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/rfq/${rfqId}/lane-reqs/filters/_search`, filters, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET RFQ Lane Labels with monthlyvolume  GetRFQLaneReqLaneLabels
  getRFQLaneReqLaneLabels(rfqId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfq/:id/lane-labels
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/rfq/${rfqId}/lane-labels`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create RFQ lane Response
  createRfqLaneResponse(rfqId, data){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/response`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
//Create RFQ lane Responses
  createRfqLaneResponses(rfqId, data){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfq/:id/lane-response
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/responses`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  //Create RFQ lane Response
  editRfqLaneResponse(rfqId, data){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfq/lane-response/:id
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/response`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create RFQ lane Response
  rfqLaneResponseListing(orgId, rfqId, data){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/transporter/:orgId/rfq/:id
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${orgId}/rfq/${rfqId}/responses/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  //Create RFQ lane Response
  getRfqResponseCounters(groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq-group/${groupId}/rfq-responses/submission-summary`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Submit RFQ lane Response
  submitRFQ(orgId, rfqId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // /onboarding/api/v1/submit/transporter/:orgId/rfq/:id
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/submit/transporter/${orgId}/rfq/${rfqId}`,{}, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //delete RFQ lane response
  deleteRfqLaneResponse(rfqId,id): Observable<any>{
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      
      //onboarding/api/v1/rfq/lane-response/:id
      return this.http
      .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/response/${id}` , {
        headers: options
      })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get odvt Ceiling range listing 
  ODVT_Ceiling_Range(orgId, rfqId, data){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${orgId}/rfq/${rfqId}/odvt-ceiling-prices`, data, {
         
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  ODVTCellingPriceWithAuctionId(orgId, data,auctionId){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/transporter/${orgId}/contract-auction/${auctionId}/contract-auction-ceiling-prices`, data, {
         
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  auctionGroupStartPrices(orgId,groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/transporter/${orgId}/contract-auction-group/${groupId}/start-prices`, {
         
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionGroupDetails(groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/contract-auction-group/${groupId}/summary`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionList(orgId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/transporter/${orgId}/contract-auctions/_search`,body, {
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionTermsAndConditions(mfgOrgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${mfgOrgId}/contract-auction/settings/terms`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get dutch auction rating prices 
  forward_auction_bids_list(orgId, filters){

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/transporter/:orgId/dutch-auction-rating-prices/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/transporter/${orgId}/dutch-auction-rating-prices/_search`, filters, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }



  saveRFIOperationalInfo(body,id){
    
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(environment.apiBaseUrl + 'onboarding/api/v1/rfi/3/operation-info',body ,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  saveTopCustomerInfo(body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(environment.apiBaseUrl +'onboarding/api/v1/rfi/3/top-customer',body ,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateTopCustomerInfo(body,id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(environment.apiBaseUrl +'onboarding/api/v1/rfi/top-customer/'+id,body ,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  updateMajorVolData(body,id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(environment.apiBaseUrl +'onboarding/api/v1/rfi/operation-info/'+id,body ,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getRFIdetails(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(environment.apiBaseUrl +'onboarding/api/v1/transporter/'+id+'/rfi/3' ,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  deleteTopCustomerInfo(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(environment.apiBaseUrl +'onboarding/api/v1/rfi/top-customer/'+id,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteMajorVolInfo(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(environment.apiBaseUrl +'onboarding/api/v1/rfi/operation-info/'+id,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  submitQuery(orgId,rfqId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.apiBaseUrl}kremlin/api/v1/transporter/${orgId}/rfq/${rfqId}/support-query`,body,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionGroupBroadCastMsg(groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.apiBaseUrl}kremlin/api/v1/contract-auction-group/${groupId}/broadcast-message`,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTotalBusinessVal(groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.rubixUrl}/rubix/api/v1/contract-auction/group/${groupId}/spend`,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getRfqConsent(tspId,rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.apiBaseUrl}kremlin/api/v1/transporter/${tspId}/rfq/${rfqId}/consent`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  postRFQConsent(tspId,rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.apiBaseUrl}kremlin/api/v1/transporter/${tspId}/rfq/${rfqId}/consent`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionDetails(auctionId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.apiBaseUrl}onboarding/api/v1/transporter/contract-auction/${auctionId}`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  saveAbiRfiOtherDetails(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.apiBaseUrl}onboarding/api/v1/rfi/6/business-info`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  saveOtherBusinessDetails(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.apiBaseUrl}onboarding/api/v1/rfi/5/business-info`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getCeatRfiDetails(userID){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.apiBaseUrl}onboarding/api/v1/transporter/${userID}/rfi/5 `,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getAbiRfiDetails(userID){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.apiBaseUrl}onboarding/api/v1/transporter/${userID}/rfi/6 `,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  editOtherBusinessDetails(body,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${environment.apiBaseUrl}onboarding/api/v1/rfi/business-info/${id}`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTSPIndentReport(platform,body,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/transporter/${id}/indent/report?platform=${platform}`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentV2MFGReports(orgId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/transporter/${orgId}/indent-acceptance`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }



  addMfgSegmentData(body,orgId){
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ orgId + '/profile/service' , body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);
          
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteIndustrySegmentInfo(orgId,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(this.prodUrl +'onboarding/api/v1/transporter/'+ orgId + '/profile/service/segment/'+id,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTSPInvoiceReport(body,id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/transporter/${id}/invoice/report`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


}
