<div class="customToolTip">
    <div  class="toolTipView">
        <div class="time-selector">
           
            <div class="minutes">
                <ul class="list">
                    <li 
                        class="list-li"
                        (click)="selectMin(min)"
                        [ngClass]="{'active': selectedMinute === min}"
                        *ngFor="let min of timeMinutes">
                        {{min}}
                    </li>
                </ul>
            </div>
           
        </div>
        <!-- <div class="submit-button">
            <button 
                [disabled]="!(selectedHour >=0 && selectedMinute>=0 && selectedZone)"
                (click)="submitTime()" class="btn-primary">
                Done  
            </button>
        </div>  -->
    </div>
</div>