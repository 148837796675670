import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-alert-model',
  templateUrl: './alert-model.component.html',
  styleUrls: ['./alert-model.component.scss']
})
export class AlertModelComponent implements OnInit {
  selected:any;
  message:any;
  type:any = 'confirm';
  heading:any;
  constructor(public dialogRef: MatDialogRef<AlertModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(typeof this.data === 'string') {
      this.message = this.data;
    } else {
      this.message = this.data.message;
      this.type = this.data.type;
      if(this.data.heading){
        this.heading = this.data.heading;
      }
    }
  }

  onClose(): void {
    this.dialogRef.close({data: this.selected});
  }

  onClick(value): void {
    this.selected = value;
    this.onClose();
  }

}
