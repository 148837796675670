<div *ngIf="itemList.length > 0" class="item_listing filter_component">
    <div class="component_size">
            <div class="group-data" *ngFor="let item of itemList;index as groupIndex">
                <p class="group-name" (click)="setActiveGroup(groupIndex)">
                    {{item.key}}
                </p>
                <div class="sub-items-list" *ngIf="activeGroupIndex == groupIndex">
                    <mat-checkbox *ngFor="let subItem of item['subItems'];index as subItemIndex"  [(ngModel)]="subItem.isSelected">
                        {{subItem.key}}
                    </mat-checkbox>
                </div>
            </div>
    </div>
    <div class="action_button">
            <button class="ff_button_28_transparent" (click)="cancel()">Cancel</button>
            <button class="ff_button_28 leftM_6" (click)="submit()">Apply</button>
    </div>
</div>
<div *ngIf="itemList.length <= 0" class="item_listing component_size filter_component">
    <p class="div_0">No Data Available</p>
</div>
