import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, forwardRef, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule }   from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module'
// import { HttpModule } from '@angular/http';
import { LoginRedirectService } from './auth/login-redirect/login-redirect.service';
import { httpInterceptorProviders } from './http-interceptors/index';
import { NgxsModule } from '@ngxs/store';
import { EnsureAuthenticatedService } from './auth/ensure-authentication/ensure-authentication.service';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatMenuModule} from '@angular/material/menu';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SharedModule } from './shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { SnackbarComponent } from './snackbarService/snackbar/snackbar.component';
import { NgOtpInputModule } from 'ng-otp-input';
import "@angular/common/locales/global/en-IN";
import * as Sentry from "@sentry/angular";
import { ToasterComponent } from './toasterSerivce/toaster/toaster.component';

@NgModule({
  declarations: [
    AppComponent,
    SnackbarComponent,
    ToasterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    AuthModule,
    NgxsModule.forRoot([]),
    MatNativeDateModule,
    MatSnackBarModule,
    MatMenuModule,
    FileUploadModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatDatepickerModule,
    MatFormFieldModule,
    NgOtpInputModule,
  ],
  providers: [
    httpInterceptorProviders,
    LoginRedirectService,
    EnsureAuthenticatedService,
    { 
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: { subscriptSizing: 'dynamic' } 
    },
    { 
      provide: LOCALE_ID, 
      useValue: "en-IN" },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas:  [ CUSTOM_ELEMENTS_SCHEMA ]
  
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) localeId){
  }
 }
