import { 
    Pipe, 
    PipeTransform 
 } from '@angular/core';  
 
 @Pipe ({ 
    name: 'capatiliseWord' 
 }) 
 
 export class CapatiliseWord implements PipeTransform { 
    transform(stringValue: any) { 
        return stringValue
         .split(/[_ ]+/)  // Split by underscores or spaces
         .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
         .join(' ');
    } 
 } 