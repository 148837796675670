import { Component, OnInit, Output, Input, ViewChild, EventEmitter, ElementRef } from '@angular/core';

interface item {
  key: string | number;
  value: string | number;
  isSelected: boolean;
  isVisible?: boolean;
}
@Component({
  selector: 'app-ff-filter-without-submit',
  templateUrl: './ff-filter-without-submit.component.html',
  styleUrls: ['./ff-filter-without-submit.component.scss']
})
export class FfFilterWithoutSubmitComponent implements OnInit {

  select_all: boolean = false;
  itemList: item[];
  searchText: string = '';
  isSearchFound: boolean = true;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputItemList: item[];

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.itemList = [];
    this.itemList = JSON.parse(JSON.stringify(this.inputItemList));
    this.checkSelections();
    this.makeAllListItemVisible();
    setTimeout(() => {
      if(document.getElementById('searchInput')){
        let searchInput = document.getElementById('searchInput').focus();
      }
    }, 300);
  }

  search() {
    if (this.searchText.length) {
      this.filterItemList();
    } else {
      this.makeAllListItemVisible();
    }
  }

  clearSearch() {
    this.searchText = '';
    this.isSearchFound = true;
    this.makeAllListItemVisible();
  }

  filterItemList() {
    let searchItem = this.searchText.toLowerCase();
    this.isSearchFound = false;
    this.itemList.forEach(item => {
      if (item.key.toString().toLowerCase().includes(searchItem)) {
        item.isVisible = true;
        this.isSearchFound = true;
      } else {
        item.isVisible = false;
      }
    });
  }

  makeAllListItemVisible() {
    this.itemList.forEach(item => {
      item.isVisible = true;
    });
  }

  onSelectAll(e: any): void {
    for (let i = 0; i < this.itemList.length; i++) {
      const item = this.itemList[i];
      item.isSelected = e;
    }
    this.submit();
  }

  onCheckedOption(e: any, i) {
    const item = this.itemList[i];
    item.isSelected = e;
    let data = this.itemList.find(lane => lane.isSelected == false);
    if (data) {
      this.select_all = false;
    }
    else {
      this.select_all = true;
    }
    this.submit();
  }

  checkSelections(): boolean {
    if (this.itemList == null) {
      return false;
    }
    let selectedItems = this.itemList.filter(t => t.isSelected).length;
    if (selectedItems == this.itemList.length) {
      this.select_all = true;
    }
    return selectedItems > 0 && !this.select_all;
  }

  submit() {
    this.action.next(
      {
        applyFilter: true,
        filters: this.itemList
      }
    );
  }

  cancel() {
    this.action.next(
      {
        applyFilter: false
      }
    );
  }

}
