<div *ngIf="showSnackbar"
    [ngClass]="['snackbar-wrap', type]">
    <div class="header_bar">
        <div class="heading">
            <img [src]="headerImg">
            <p>{{heading}}</p>
        </div>
        <img (click)="closeSnackbar()" class="cross" src="../../../assets/images/cross-button.svg">
    </div>
    <div class="message">
        {{message}}
    </div>
</div>