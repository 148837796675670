import { 
    Pipe, 
    PipeTransform 
 } from '@angular/core';  
 
 @Pipe ({ 
    name: 'convertNewLine' 
 }) 
 
 export class ConvertNewLine implements PipeTransform { 
    transform(value: any) { 
    //    let mul = parseFloat(multiply); 
    //    return mul * value 
      if(value){
         let convertData = value.replace(/\u21B5/g,'<br>')
         return convertData;
      }
      return value;
    } 
 } 