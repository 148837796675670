import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private toasterSubject = new Subject<any>();
  public toasterState = this.toasterSubject.asObservable();

  showSuccesstoaster(type,message:string,buttonName,url, action?:string) {
    this.toasterSubject.next({
      showToaster: true,
      toasterType:type,
      message: message,
      buttonName:buttonName,
      navigateToUrl:url,
      type: 'success'
    });
  }

  showErrorSnackBar(type,message:string,buttonName,url, action?:string) {
    this.toasterSubject.next({
      showToaster: true,
      toasterType:type,
      message: message,
      buttonName:buttonName,
      navigateToUrl:url,
      type: 'error'
    });
  }
  
  showWarningSnackBar(type,message:string,buttonName,url, action?:string) {
    this.toasterSubject.next({
      showToaster: true,
      toasterType:type,
      message: message,
      buttonName:buttonName,
      navigateToUrl:url,
      type: 'warning'
    });
  }
  
  showAlertInfoSnackBar(type,message:string,buttonName,url, action?:string) {
    this.toasterSubject.next({
      showToaster: true,
      toasterType:type,
      message: message,
      buttonName:buttonName,
      navigateToUrl:url,
      type: 'info-warning'
    });
  }

  showInfoSnackBar(type,message:string,buttonName,url, action?:string) {
    this.toasterSubject.next({
      showToaster: true,
      toasterType:type,
      message: message,
      buttonName:buttonName,
      navigateToUrl:url,
      type: 'information'
    });
  }

}

