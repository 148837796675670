<div *ngIf="showToaster"
    class="snackbar-wrap success">
    <div class="header_bar">
        <div class="heading">
            <img src="/assets/images/success-snack.svg">
            <p>{{heading}}</p>
        </div>
        <img (click)="closeToaster()" class="cross" src="../../../assets/images/cross-button.svg">
    </div>
    <div class="message">
        {{message}}
    </div>
    
    <div class="action-btn ">
        <!-- <button class="ff_button_32_transparent" (click)="closeToaster()">Cancel</button> -->
        <a class="action-link" (click)="goToLink()">{{buttonName}}</a>
    </div>
</div>