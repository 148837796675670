import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModelComponent } from './alert-model/alert-model.component';
import { LinechartComponent } from './charts/linechart/linechart.component';
import { PaginationComponent } from './pagination/pagination.component';
import { VehicleNumber } from './vehicleNumber.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConvertNewLine } from './convertNewLine.pipe';
import { WordCount } from './wordCount.pipe';
import { TimePickerComponent } from './utils/time-picker/time-picker.component';
import { IndianCurrency } from './indianCurrency.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FfTooltipComponent } from './ff-tooltip/ff-tooltip.component';
import { UnloadingObservationsViewComponent } from './common-popups/unloading-observations-view/unloading-observations-view.component';
import { UnloadingDetentionViewComponent } from './common-popups/unloading-detention-view/unloading-detention-view.component';
import { FfFilterComponent } from './ff-filter/ff-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSliderModule} from '@angular/material/slider';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { ImportErrorModelComponent } from './import-error-model/import-error-model.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { CombinedFilterComponent } from './combined-filter/combined-filter.component';
import { SingleGroupMultiSelectComponent } from './single-group-multi-select/single-group-multi-select.component';
import { FfConfirmationComponent } from './ff-confirmation/ff-confirmation.component';
import { RouteMapComponent } from './route-map/route-map.component';
import { RfqMapViewComponent } from './rfq-map-view/rfq-map-view.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FfFilterWithoutSubmitComponent } from './ff-filter-without-submit/ff-filter-without-submit.component';
import { HourPickerComponent } from './utils/tfh-time-picker/hour-picker/hour-picker.component';
import { MinutesPickerComponent } from './utils/tfh-time-picker/minutes-picker/minutes-picker.component';
import { AddNewWarehouseComponent } from './add-new-warehouse/add-new-warehouse.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FfFileUploadComponent } from './ff-file-upload/ff-file-upload.component';
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { FfTimePickerComponent } from './utils/ff-time-picker/ff-time-picker.component';
import { ReadonlyCheckboxDirective } from './readonly-checkbox.directive';
import { CapatiliseWord } from './capatiliseWord.pipe';
import { TwoDigitPrecision } from './twoDigitPrecision.pipe';
import { TimeHHtohh } from './timeHHtohh.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SentenceCase } from './sentenceCase.pipe';

@NgModule({
    declarations: [
        FilterComponent,
        AlertModelComponent,
        LinechartComponent,
        PaginationComponent,
        VehicleNumber,
        TwoDigitPrecision,
        IndianCurrency,
        CapatiliseWord,
        TimeHHtohh,
        SentenceCase,
        ConvertNewLine,
        WordCount,
        TimePickerComponent,
        FfTooltipComponent,
        UnloadingObservationsViewComponent,
        UnloadingDetentionViewComponent,
        FfFilterComponent,
        ImportErrorModelComponent,
        InfoDialogComponent,
        CombinedFilterComponent,
        SingleGroupMultiSelectComponent,
        FfConfirmationComponent,
        RouteMapComponent,
        RfqMapViewComponent,
        FfFilterWithoutSubmitComponent,
        HourPickerComponent,
        MinutesPickerComponent,
        AddNewWarehouseComponent,
        FfFileUploadComponent,
        FfTimePickerComponent,
        ReadonlyCheckboxDirective
    ],
    exports: [
        FilterComponent,
        AlertModelComponent,
        LinechartComponent,
        PaginationComponent,
        VehicleNumber,
        TwoDigitPrecision,
        ConvertNewLine,
        TimeHHtohh,
        IndianCurrency,
        CapatiliseWord,
        SentenceCase,
        WordCount,
        NgxMatSelectSearchModule,
        MatSelectModule,
        FormsModule,
        TimePickerComponent,
        MatSlideToggleModule,
        FfTooltipComponent,
        FfFilterComponent,
        MatSliderModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        CombinedFilterComponent,
        SingleGroupMultiSelectComponent,
        FfConfirmationComponent,
        RouteMapComponent,
        RfqMapViewComponent,
        HourPickerComponent,
        MinutesPickerComponent,
        AddNewWarehouseComponent,
        FfFilterWithoutSubmitComponent,
        FfFileUploadComponent,
        FileUploadModule,
        MatStepperModule,
        MatChipsModule,
        FfTimePickerComponent,
        ReadonlyCheckboxDirective,
        NgxMaterialTimepickerModule
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatSliderModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        MatDatepickerModule,
        MatDialogModule,
        FileUploadModule,
        MatTooltipModule,
        MatStepperModule,
        MatChipsModule,
        NgxMaterialTimepickerModule
    ]
})
export class SharedModule { }