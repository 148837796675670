<div class="filter-wrapper">
    <h1 class="heading">
        Filters
    </h1>
    <div class="filter-column ff-form-theme">
        <div *ngIf="showDateFilter" class="btm-m">
            <label class="ff-label">From Date</label>
            <br>
            <mat-form-field appearance="outline" class="custom-datepicker">
                <input matInput required readonly 
                    [(ngModel)]="dateFilters['fromDate']"
                    placeholder="From Date" 
                    [matDatepicker]="fromDatePicker" [max]="maxDate"
                    (click)="fromDatePicker.open()">
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker">
                    <img matDatepickerToggleIcon class="blue" src="/assets/images/calender-icon.svg">
                </mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngIf="showDateFilter" class="btm-m">
            <label class="ff-label">To Date</label>
            <br>
            <mat-form-field appearance="outline" class="custom-datepicker">
                <input matInput required readonly
                    [(ngModel)]="dateFilters['toDate']" 
                    placeholder="To Date" 
                    [matDatepicker]="toDatePicker" [max]="maxDate" [min]="dateFilters['fromDate']"
                    (click)="toDatePicker.open()">
                <mat-datepicker-toggle matSuffix [for]="toDatePicker">
                    <img matDatepickerToggleIcon class="blue" src="/assets/images/calender-icon.svg">
                </mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngFor="let filter of multiSelectFilters; let filterIndex = index;"  class="btm-m">
            <label class="ff-label">{{filter?.heading}}</label>
            <br>
            <mat-form-field (click)="setFocusStateTrue(filterIndex)" appearance="outline" >
                <mat-select placeholder="{{filterController[filterIndex]['name']}}"
                readonly>
                </mat-select>
                <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
            </mat-form-field>
            <div class="inter-filter">
                <app-ff-filter-without-submit *ngIf="filterController[filterIndex]['isInFocus']" class="location_filter" [inputItemList]="filter?.dataList"
                    (action)="filterAction($event, filterIndex)">
                </app-ff-filter-without-submit>
            </div>
        </div>

        <div *ngFor="let filter of singleSelectFilters"  class="btm-m">
            <label class="ff-label">{{filter?.heading}}</label>
            <br>
            <mat-form-field appearance="outline" >
                <mat-select placeholder="Select Fields" [(ngModel)]="filter.value">
                    <mat-option *ngFor="let option of filter.dataList" [value]="option.value">{{option.key}}</mat-option>
                </mat-select>
                <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
            </mat-form-field>
        </div>
        <div class="action-buttons">
            <button class="ff_button_28_transparent" (click)="cancelFilter()">Cancel</button>
            <button class="ff_button_28 ff_leftM_6" (click)="applyFilter()">Apply</button>
        </div>
    </div>
    <div class="closer" (click)="cancelFilter()">
        <img src="/assets/images/accordian-2px.svg">
    </div>
</div>