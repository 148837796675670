import { Injectable, EventEmitter } from '@angular/core';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/internal/operators';
import { StorageService } from '../auth/storage.service';
import { environment } from '../../environments/environment';

export interface Trip {
  tripId: String;
  trackingUuid: String;
  vehicleNumber: String;
  tsp: String;
  origin: String;
  destination: String;
  distance: Number;
  lastLocation: String;
  lastLocationTime: number;
  percCovered: any;
  status: String;
  angle: number;
  latitude: number;
  longitude: number;
  originLatitude: number;
  originLongitude: number;
  destinationLatitude: number;
  destinationLongitude: number;
  isETABreached: boolean;
  isTripDelayed: boolean;
  isGpsAvailable: boolean;
} 

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  unsubscribe() {
    throw new Error("Method not implemented.");
  }
  RfqData: any;
  is_RFQ_Publishable: any;
  $rfqData = new EventEmitter();
  prodUrl = environment.apiBaseUrl;
  turingUrl = environment.turingUrl;
  takshUrl = environment.takshUrl;
  s3Url = environment.s3baseUrl;
  octopusUrl = environment.octopusBaseUrl;
  rubixUrl = environment.rubixUrl;
  flounderUrl = environment.flounderUrl;
  papyrusUrl = environment.papyrusUrl;
  errorData: {};
  metadata: any;
  currentuserdetails: any;
  public allextension: any = {
    basicprofile: '/profile/basic',
  };
  public editExtension: any = {
    material: 'materials',
    plant: 'plants',
    warehouse: 'warehouses',
    lane: 'lanes',
    vehicle: 'transport-vehicles',
    vehicle_capacity: 'vehicle-capacities',
  };

  constructor(
    private router: Router, 
    private http: HttpClient, 
    private storage: StorageService,
    private handler: HttpBackend,
  ) { }

  setData(data: any, isPublishable: any) {
    this.clearData();
    this.RfqData = data;
    this.is_RFQ_Publishable = isPublishable;
  }

  getData() {
    let temp = {
      RFQData: this.RfqData,
      is_RFQ_Publishable: this.is_RFQ_Publishable
    }
    return temp;
  }

  clearData() {
    this.RfqData = undefined;
    this.is_RFQ_Publishable = undefined;
  }


  getTransporterFilterData(page_size, page_number, filterdata, searchText, sortBy): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let bodydata = {
        "pageSize": page_size,
        "pageIndex": page_number,
        'filters': filterdata
      }
      if (sortBy && sortBy.trim().length > 0) {
        bodydata["sortColumn"] = sortBy;
      }
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/transporters/search', bodydata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getFilteredTransporterData(data): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/transporters/search', data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getSpotAuctionDetails(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  fileUpload(type, file, id): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      options.append("Content-Type", "multipart/form-data");
      var formdata = new FormData();
      formdata.append(type, file, file.name);
      return this.http
        .put<any>(this.prodUrl + 'onboarding/api/v1/auction/' + id + '/documents', formdata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturersData(id, data, service): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${id}/${this.editExtension[service]}/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerData(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/manufacturer/' + id, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerInfo(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + `onboarding/api/v1/manufacturer/${id}/basic-info`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }



  startBid(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/start', {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  stopBid(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/stop', {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  extendBid(id, time): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/extend/' + time, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addTspsToSpotAuction(id, postData): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `onboarding/api/v1/manufacturer/spot-auction/${id}/transporter`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addTspsToContractAuction(id, postData): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `onboarding/api/v1/manufacturer/contract-auction/${id}/transporter`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addTspsToPublishedRfq(id, postData): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `kremlin/api/v1/manufacturer/rfq/${id}/transporters`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getTransporterProfilerData(gstin): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/transporters/' + gstin, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMetadata(): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/metadata', {
          headers: options
        })
        .pipe(
          map(data => {
            this.metadata = data;
            return this.metadata;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }

  }

  getFilteredMetadata(payload): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/metadata/search', payload, {
          headers: options
        })
        .pipe(
          map(data => {
            this.metadata = data;
            return this.metadata;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }

  }

  getFilterMetadata(data): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + 'onboarding/api/v1/transporters/filters/_search', data, {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBidFilterMetadata(data): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    let gstin = user.organization.id;
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${gstin}/spot-auctions/filters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBidTransporterFilter(data): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    let gstin = user.organization.id;
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/transporter/${gstin}/auctions/filters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  notifyBidders(id): Observable<string> {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/notify', {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBidList(postData): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${gstin}/spot-auctions/search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Delete spot bid
  deleteSpotBid(bidId): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      //onboarding/api/v1/spot-auction/:id
      return this.http
        .delete<any>(`${this.prodUrl}onboarding/api/v1/spot-auction/${bidId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Delete Contract bid
  deleteContract(contractId): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      //onboarding/api/v1/spot-auction/:id
      return this.http
        .delete<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${contractId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getStatsData(id): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/stats', {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getSpotBidTspFirstBid(orgId, spotBidId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.prodUrl + `onboarding/api/v1/manufacturer/${orgId}/spot-auction/${spotBidId}/first-bids`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getChartData(id): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.prodUrl + 'onboarding/api/v1/spot-auction/' + id + '/trend', {
          headers: options
        })
        .pipe(
          map(data => {
            return data;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAllBidData(pageSize, pageIndex, sortOrder, id, searchAll): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let data = {
        "pageSize": pageSize,
        "pageIndex": pageIndex,
        "sortOrder": sortOrder
      }

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/spot-auction/${id}/share/search?showAll=${searchAll}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            /**Save Data */
            //this.container.set("hc.brodcast.all." + userID, dataToCache);

            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTargetPriceValues(bodyData): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/freight-rate`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAgriTip(bodyData): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/agri-commodity-ratio`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBidLiveData(pageSize, pageIndex, sortOrder, id): Observable<string> {
    return this.getAllBidData(pageSize, pageIndex, sortOrder, id, false);
  }

  getLane(laneId): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${gstin}/lanes/${laneId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerProfile(): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${gstin}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerVehicleGroups(): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${gstin}/mfg-vehicles`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // remove this after development
  getMaterialMovementBetween(): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${user.organization.id}/movement-types`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgOriginWarehouses(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/applicable-origin-warehouses/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgWarehouseLabels(orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/warehouse-labels`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgDestinationWarehouses(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/applicable-destination-warehouses/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentLaneVehicles(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/applicable-vehicles/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  alterIndentProposalAction(indentId, action: 'reject' | 'accept'){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/${action}/indent/${indentId}/alternate-vehicle-suggestion`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentExpDeliveryDate(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/applicable-delivery-time`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentTspList(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let orgId = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/applicable-tsp-partners/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getManufacturerProfileId(id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createBid(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${gstin}/auction`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  editBid(data, bidid): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .patch<any>(`${this.prodUrl}onboarding/api/v1/auction/${bidid}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createIndent(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/indent`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateIndent(data, id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentDetails(id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgInwardList(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicles/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgReportedVehicleList(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  attachVehicleToIndent(indentId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${indentId}/attach-vehicle`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgInwardfilters(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicles/filters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getGateSecurityFilters(payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/filters/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentMfgList(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/indents/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getIndentMfgListLite(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/indents/_search?lite=true`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getIndentMfgListCounters(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/indents/_search/counters`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentTransporterListCounters(data,gstin) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/transporter/${gstin}/indents/_search/counters`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentTransporterList(data,gstin) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/transporter/${gstin}/indents/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getWarehouseSettings(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/warehouse/settings/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getIndentTspDistributor(payload,platform) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${gstin}/indent/tsp-response?platform=${platform}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentTspDistributorReportFile(payload,platform) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/report/manufacturer/${gstin}/indent/tsp-acceptance?platform=${platform}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentReportFile(payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/report/manufacturer/${gstin}/indent`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addVehicleDetails(id, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/vehicle`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  acceptRejectIndent(id, reject, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let type = (reject) ? 'rejection' : 'acceptance';
      let first = true;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/tsp/${type}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateVehicle(id, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${id}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  initiateQC(id, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${id}/quality-check`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  inwardNewVehicle(payload, isLinkedVehicle:boolean) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle?linkedVehicle=${isLinkedVehicle}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  confirmDocPass(vehicleId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/${vehicleId}/docs-check`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateInwardVehicleStatus(vehicleId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/${vehicleId}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  markVehicle(id, vid, arrived, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let type = (arrived) ? 'arrival' : 'no-show';
      let first = true;
      let params = '';
      for (let key in data) {
        params += (first) ? '?' : '&';
        params += key + '=' + data[key];
        first = false;
      }

      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/vehicle/${vid}/${type}${params}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  revokeIndent(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let first = true;
      let params = '';
      for (let key in data) {
        params += (first) ? '?' : '&';
        params += key + '=' + data[key];
        first = false;
      }

      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/revoke${params}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  initiateSpotIndent(id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/spot-proc`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  markIndentAsNoShow(indentId, payload): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${indentId}/mfg/no-show`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  carryForwardIndent(indentId, payload): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${indentId}/mfg/carry-forward`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  confirmDispatchMaterial(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${id}/materials`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  generateIndentLrDocument(dropPointId, payload): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/drop-point/${dropPointId}/lorry-receipt/generate`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  confirmVehDispatch(vehId, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/${vehId}/dispatch`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  markInwardedVehicleReturn(vehId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/${vehId}/return`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  confrimGateIn(vehId, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/inward-vehicle/${vehId}/gate-in`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  acceptQcFailedVehicle(vehId, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${vehId}/accept-qc-failed`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Modify response by MFG
  modifyResponse(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/mfg/acceptance`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Reject response by MFG
  rejectIndent(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/mfg/rejection`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Reject response by MFG
  revokeIndentMfg(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/mfg/revocation`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Vehicle noShow in indent
  vehicleNoShow(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${id}/no-show`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Vehicle search in indent
  vehicleSearch(component, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && null != user) {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      let gstin = user.organization.id;
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/${component}/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Upadate Indent Material
  updateIndentMaterial(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/indent/material/${id}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //add material to Indent
  addMaterialToIndent(id, data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/indent/${id}/material`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //delete material from Indent
  deleteMaterialFromIndent(id): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let gstin = user.organization.id;
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}lmnop/api/v1/indent/material/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  private handleError(error: HttpErrorResponse) {
    this.isValidToken(error);
    if (error.status === 500) {
      return throwError('Internal Server Error');
    }else{
      return throwError(error);
    }
  }

  private isValidToken(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.storage.removeItem('access-token');
      this.storage.removeItem('user');
      this.router.navigateByUrl('/login');
    }
  }

  createRFQ(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/rfq`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createRFQLaneReq(rfqId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/lane-req`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateRFQLaneReq(laneId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/rfq/lane-req/${laneId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRFQLaneReq(rfqId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/rfq/:id/lane-reqs
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/lane-reqs`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  editRFQ(orgId, RFQid, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/rfq/${RFQid}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createRfqVehicleMapping(rfqId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicles`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Get Consolidated RFQ Vehicle List
  getRfqVehicleMapping(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicle-mapping`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Delete Consolidated RFQ Vehicle Mapping
  deleteRfqVehicleMapping(rfqId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicle-mapping`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Create MFG ODVT Ceiling Price
  createMfgOdvtCeilingPrice(rfqId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/ceiling-price-bench-mark`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  setRfqApplicableTspListForCpGeneration(rfqGroupId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq-group/${rfqGroupId}/lane/ceiling-price-generation-param`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  setRfqCeilingPriceGenerationBasis(rfqId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/ceiling-price-generation-basis`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createODVGThresholdPrice(auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/thresholds`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createIncumbentTspsRates(orgId, auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/contract-auction/${auctionId}/exisitng-contract-rates`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  bidReduction(orgId, auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/contract-auction/${auctionId}/bid-reduction`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  createLtlAuctionExistingRates(orgId, auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/contract-auction/${auctionId}/thresholds`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  createAuctionStartingPrices(orgId, auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/start-price`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createTspsAuctionRankings(auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/rankings`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadContractAuctionDoc(auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/documents`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  generateRfqGroupCp(rfqId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/generate/rfq/${rfqId}/odvt-ceiling-prices`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  hideRfqGroupCp(rfqGroupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/hide/rfq-group/${rfqGroupId}/ceiling-prices`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupsCpSchedule(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/groups/ceiling-price-acceptance`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // Generate ODVT Ceiling Price
  generateRfqCeilingPrice(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/generate/rfq/${rfqId}/groups/odvt-ceiling-prices`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getGroupLane(orgId, laneLabel) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane/_search`, laneLabel, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET MFG CITIY LANE DATA 
  getCityLaneData(orgId, data, type?: 'LITE' | 'HARD') {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane/${type == 'LITE' ? '_lite-search' : '_search'}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //getMfgLanes 
  getMfgLanes(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lanes`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  //getMfgLanes 
  getMfgClusters(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane-clusters`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addRfqGroup(rfqId, laneData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/group`, laneData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteDocument(id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq-document/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupsData(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${id}/rfq-groups/_search`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqResponses(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${id}/responses/_search`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupedResponses(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${id}/responses/_search?groupResponses=true`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqOdvgBellCurveData(rfqId, laneId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${rfqId}/lane/${laneId}/normalized-quotes`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupBiVsViData(rfqId, rfqGroupId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${rfqId}/group/${rfqGroupId}/lane-attractiveness`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqResponsesFilters(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${id}/responses/filters`, bodyData, {
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupLane(id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq-group/${id}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET RFQ lane label
  getRfqLaneLabel(orgId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane-labels`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Delete RFQ Group
  deleteRfqGroup(groupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq-group/${groupId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Get RFQ Lanes
  getRfqLanes(rfqId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/lanes/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Get RFQ Specific Vehicle Groups
  getRfqLanesVehGroups(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicle-groups`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create RFQ Lanes vehicles 
  createRfqLanesReq(rfqId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/lane`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create RFQ vehicles 
  createRfqVehicles(rfqId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/vehicle-groups`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET RFQ listing MFG
  getRfqListingMFG(orgId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/rfq/_search`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET single RFQ  MFG
  getRfqDetails(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  deletRfqResponsesSubmissionOfTheTsp(rfqId, tspId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${rfqId}/transporter/${tspId}/enable-resubmit`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  releaseRfqNotice(rfqId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/notice`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getLtlOthersTspsResonses(rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/tsp-response-documents`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqTspPartners(rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/submit/tsp-partners`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Close RFQ
  closeRFQ(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/close/rfq/${rfqId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //publish RFQ
  publishRFQ(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken && sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/publish/rfq/${rfqId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgEpodListing(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${user.organization.id}/indent/vehicle/epod/_search`, data, {

          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgInvoiceFilters(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${orgId}/indent/vehicle/epod/filters/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgInvoiceCounters(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${orgId}/indent/vehicle/epod/_counters`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get Invoice Listing 
  getMfgInvoiceListing(orgId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${orgId}/indent/vehicle/epod/_search`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get invoice Details 
  getInvoiceListSearch(orgId, payload): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${orgId}/indent/invoice/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // get invoice Details 
  getMfgInvoiceDetails(invoiceId): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.turingUrl + `/turing-teller/api/v1/indent/invoice/${invoiceId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getInvoiceSettings(mfgOrgId): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${mfgOrgId}/invoice/settings`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  addManufacturerInvoiceSettings(mfgOrgId, payload): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${mfgOrgId}/invoice/settings`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateManufacturerInvoiceSettings(mfgOrgId, payload): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.turingUrl + `/turing-teller/api/v1/manufacturer/${mfgOrgId}/invoice/settings`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // update invoice Details 
  updateInvoiceByMfg(invoiceId, postData): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.turingUrl + `/turing-teller/api/v1/indent/invoice/${invoiceId}`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  // approve invoice 
  approveInvoice(invoiceId, postData, status: ('accept' | 'accept-with-deviations')): Observable<any> {
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(this.turingUrl + `/turing-teller/api/v1/indent/invoice/${invoiceId}/${status}`, postData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET MFG CITIES getCitiesData
  getCitiesData(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET MFG CITIY LANE Filters 
  getCityLaneFilters(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane/filters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET MFG CITIY LANE DATA Filtered
  getCityLaneDataFiltered(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/mfg-city-lane-contracts/filters`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getOrgContractRatesFilters(orgId, contractType, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.papyrusUrl}/papyrus/api/v1/org/${orgId}/${contractType}/filters`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //CREATE MFG CITY LANE DATA 
  createCityLane(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createNewCity(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //EDIT  MFG CITY 
  editMFGCity(cityId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/city/${cityId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //EDIT  MFG CITY-Lane
  editMFGCityLane(orgId, laneId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/city-lane/${laneId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadfile(key, file,privacyTypeParam?) {
    // let sessiontoken = this.storage.getItem('access-token', false);
    // let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    // if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders()
    options.append("Content-Type", "multipart/form-data");
    var formdata = new FormData();
    formdata.append("file", file, file.name);
    return this.http
      .post<any>(`${this.s3Url}doraemon/api/v1/document${privacyTypeParam?privacyTypeParam:''}`, formdata)
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }


  viewDocument(id, key) {
    var sessiontoken = this.storage.getItem('access-token', false);
    this.currentuserdetails = JSON.stringify(this.storage.getItem('current-user', false));
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(this.s3Url + 'doraemon/api/v1/document/' + id + '/' + key + '?view=false', {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  createRfqDoc(element, id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(this.s3Url + 'kremlin/api/v1/rfq/' + id + '/document', element, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadExcelRfqDoc(element, id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .post<any>(`${this.prodUrl}` + 'kremlin/api/v1/rfq/' + id + '/document', element, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateCrn(id, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}` + 'lmnop/api/v1/indent/' + id, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  editRfqDoc(element, id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(this.s3Url + 'kremlin/api/v1/rfq-document/' + id, element, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  deleteRfqDoc(id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(this.s3Url + 'kremlin/api/v1/rfq-document/' + id, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //CREATE Auction 
  createContractAuction(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/contract-auction`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //EDIT Contract  Auction 
  editContractAuction(auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/contract-auction/${auctionId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //EDIT Auction Group
  editContractAuctionGroup(auctionGroupId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${auctionGroupId}`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  cancelAuctionGroupSchedule(auctionGroupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${auctionGroupId}/cancel`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //CREATE Auction 
  getAuctionDetails(orgId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/contract-auction/${orgId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //CREATE Auction Group 
  createAuctionGroup(auctionId, groupData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/group`, groupData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Contract Auction Vehicle Group Listing
  auctionVehicleGroupListing(auctionId, groupData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/lanes/_search`, groupData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Contract Auction Group's Vehicle Listing
  auctionGroupVehicles(auctionId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/vehicle_groups`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  checkVehicleMaterialMappingPresent(orgId, vehicleGroupList){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/validate/manufacturer/${orgId}/vehicle-capacity`, vehicleGroupList, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Publish Contract Auction
  publishContractAuction(auctionId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/publish`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Create Contract Auction lane Vehicle Group
  createAuctionLaneVehGroup(auctionId, laneData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/lane/vehicle_groups`, laneData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //CREATE auction Vehicle Allocation 
  auctionVehicleAllocation(auctionId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/vehicle_group`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //DELETE Auction Group lane
  deleteAuctionGroupLane(laneId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-lane/${laneId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //DELETE Auction Group lane
  createAuctionGroupLane(groupId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${groupId}/lane`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  //Contract auction group start
  startAuctionGroup(groupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${groupId}/start`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //Contract auction group stop
  stopAuctionGroup(groupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${groupId}/stop`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractBidListingMFG(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${user.organization.id}/contract-auctions/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET Auction Vehicle Group lane
  auctionVehicleGroup(auctionId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${auctionId}/vehicle_groups`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //GET TSP Partner Group
  getPartnerTspGroup(orgId, originName) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/partner-group/${originName}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createContractCityLane(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/mfg-city-lane-contract`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractData(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/mfg-city-lane-contracts/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createMultiSpotBids(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/spot-auctions`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMaterialData(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:id/materials/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/materials/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get spot auctions live bid status
  spotAuctionLiveBids(orgId, auctionId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:orgId/spot-auctions/bids/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/spot-auction/${auctionId}/bids/_search`, data, {

          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get Forward spot auctions live bid status
  spotForwardAuctionLiveBids(orgId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:orgId/dutch-auctions/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/dutch-auctions/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  //get spot auctions live bid status
  getSpotAuctionLiveBids(orgId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:orgId/dutch-auctions/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/spot-auctions/bids/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get spot auctions live bid status
  contractAuctionLiveBids(orgId, groupId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:orgId/contract-auction-group/:id/bids/_search
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/contract-auction-group/${groupId}/bids/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAuctionReport(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // onboarding/api/v1/manufacturer/:orgId/spot-auctions/bids/_search
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/${user.organization.id}/query/lane-wise/results/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }



  getAuctionFilters(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/${user.organization.id}/query/auction/filters`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getfreightSpreadData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/${user.organization.id}/query/freight-spread/results/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getanalyticsCounterData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/${user.organization.id}/query/auction/results`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentAuctionReport(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/counters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentdropdownData(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-analytics/filters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  validateIndentCreation(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${orgId}/indent-payload/validation`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createBulkRowIndent(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${orgId}/indent/generate`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  sendBulkIndentCreationNotification(batchCode) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/create/indents/batch/${batchCode}/notification`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  dispatchSummaryCounter(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/dispatched-summary/counters/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentContractVsSpot(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/vehicle_split-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractIndentFreightRateRsperKm(data){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/lane-freight-per-km/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getContractIndentFreightRateRsPerCasePerKm(data){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/lane-freight-per-case-per-km/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentsummaryTspWise(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/status-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentedVsActualTspWise(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/placement-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentReasonForDiffTspWise(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/placement-failure-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentvehPolliutionNorms(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/norms-type-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentvehTypeSplitSummary(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/vehicle-type-split-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getfuelTypeSummary(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/fuel-type-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getindentVehicleAgeSummary(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/age-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentInvalidVehicleSummary(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/validity-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getindentFitnessPermitSummary(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent-vehicle/fitness-permit-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }



  getindentcostPerLanePerCase(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/cost-per-case_per_lane_summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAvPlacementLeadTime(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/lead-time-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  gettspResponseTime(data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${user.organization.id}/indent/response-time-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getdesielPriceData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/diesel-trend/aggregate`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getCrudeOilData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/crude-oil-trend/aggregate`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getdieselPredictionData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/diesel-trend/prediction`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getVehicleLocationData(data): Observable<string> {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      // onboarding/api/v1/manufacturer/:id/partner-group/:gName
      return this.http
        .post<any>(`${environment.polarisUrl}/polaris/api/v1/route`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //get Transporter Scorecard
  getTransporterScorecard(orgId, tspId, data) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      // rubix/api/v1/:orgId/transporter/:tspId/scorecard
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/${orgId}/transporter/${tspId}/scorecard`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentsMapViewData(orgId, data) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/indent/dispatch-material-summary/_search`, data, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  createInvoicingComment(body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${environment.kiwiUrl}/kiwi/api/v1/comment`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getInvoicingCommentList(entity, entityId) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.kiwiUrl}/kiwi/api/v1/comment/${entity}/${entityId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMaterialShippingData(orgId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/mfg-material`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  contractAuctionLowestBidsData(orgId, groupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/contract-auction/group/${groupId}/lowest-bids`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getSimilarCitiesData(bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${user.organization.id}/city/_validate`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  createNewSecondaryWh(bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${user.organization.id}/warehouse?primaryCreation=false`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getOrgWarehouseData(bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${user.organization.id}/warehouses/_search`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  fetchOriginWarehouses(bodyData,userId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${userId}/warehouses/_search`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getWarehouseDetailsWithExternalCode(orgId, externalCode) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/warehouse/${externalCode}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getIndentAnalyticsWhList(orgId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/indent/warehouse/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  extendSpotBid(id, time) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}onboarding/api/v1/spot-auction/${id}/extend/${time}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createIndentsFromSpotBid(orgId, auctionId, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/${auctionId}/create-indent`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadRfqBasePrice(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${id}/base-quote`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  submitRfqNextStep(id, bodyData) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/upgrade/rfq/${id}/version`, bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  rfqLaneResponseSummary(id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${id}/version-response-summary`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqNextRoundCounter(id, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${id}/responses/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getMfgIndentFilters(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    let gstin = user.organization.id;
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${gstin}/indents/filters/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getVehiclesWayPoints(vehicleId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    let gstin = user.organization.id;
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}lmnop/api/v1/indent/vehicle/${vehicleId}/waypoints`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractBidsFilters(orgId, groupId, body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/contract-auction-group/${groupId}/bids/filters`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  //FTL-SPOT FILTERS
  getFtlFilters(id, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${id}/spot-auctions/bids/filters/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getLTLFilters(id, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${id}/spot-auctions/bids/filters/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateSpotBidSobAllocation(orgId, auctionId, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/${auctionId}/result`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  reallocateSpotBidSobAllocation(orgId, auctionId, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/${auctionId}/result`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  updateSpotBidResultStatus(orgId, resultId, body, status: 'approve'| 'reject') {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction-result/${resultId}/${status}`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBidRevisedResults(orgId, id) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/${id}/spot-auction-results`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getSpotBidAuctionSavings(orgId, auctionId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/spot-auctions/${auctionId}/savings`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getSpotAuctionLastPrice(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/last-auction-results`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  updateSpotAuctionResultDocument(orgId, resultId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction-result/${resultId}/documents`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  delteSpotAuctionResultDocument(orgId, resultId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction-result/${resultId}/documents`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractAuctionLaneSummary(orgId, groupId, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/contract-auction/group/${groupId}/top-bidders`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getContractAuctionLaneSummaryFilter(orgId, groupId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/contract-auction/group/${groupId}/top-bidders/filters`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAccepetedCellingPrices(orgId, rfqId, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/rfq/${rfqId}/odvt-ceiling-prices/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAccepetedCellingPricesSummary(orgId, rfqId, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/rfq/${rfqId}/odvt-ceiling-prices/summary`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getAccepetedCellingPricesFilters(orgId, rfqId, body) {

    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/rfq/${rfqId}/odvt-ceiling-prices/filters`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  sendBroadcastMessage(groupId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/contract-auction-group/${groupId}/broadcast-message`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqSummary(body,rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/submission-summary/_search`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRfqGroupLanesSubmissionSummary(rfqId, groupId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/group/${groupId}/submission-summary`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getRfqSubmissionData(rfqId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/submission-timeline`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getLaneAttempted(tspId,rfqId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/transporter/${tspId}/rfq/${rfqId}/attempted-lanes`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getRFQResponseFilters(rfqId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/submission-summary/filters`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getStatesList(){
    
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.octopusUrl}/oculus/v1/truck-cost-profile/states`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTruckProfile(){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.octopusUrl}/oculus/v1/truck-profiles`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  getStateDieselPrice(state){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.octopusUrl}/oculus/v1/truck-cost-profile/${state}/diesel-price`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  calulateCost(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/truck-cost-profile`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getDieselPriceTrendLine(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/truck-cost-profile/diesel-trend`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTruckCostTrendLine(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/truck-cost-profile/cost-trend`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteAuctionTsp(auctionId,tsp){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/contract-auction/${auctionId}/transporter/${tsp}`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteRFQTsp(rfqId,tsp){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/rfq/${rfqId}/transporter/${tsp}`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteSpotAuctionTsp(auctionId,tspId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/spot-auction/${auctionId}/transporter/${tspId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getLocationWiseIndentData(orgId,bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${orgId}/bulk-indent/payload`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getFastTagTollIntensityData(body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/toll-intensity`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTollPlazas(){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.octopusUrl}/oculus/v1/ulip/toll-plazas`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getHopesFromTollPlaza(tollPlazaName, body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/toll-plaza/${tollPlazaName}/nearby`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getFastTagTollMovementData(vehNumber){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.octopusUrl}/oculus/v1/ulip/vehicle/${vehNumber}/toll-waypoints`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getCountersData(bodydata){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/counters`,bodydata, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }


  getTollVehSplitData(bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/vehicle-type-split`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTollVehNormsTypeData(bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/vehicle-norms`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getTollVehAgeSplitData(bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/vehicle-age`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getHourlyVehAgeSplitData(bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/hourly-vehicle-type-split`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getHourlyVehNormsTypeData(bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.octopusUrl}/oculus/v1/ulip/fast-tag/hourly-vehicle-norms`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

getAuctionReportData(orgId,bodyData){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/spot-auctions/bids/report`,bodyData, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addTrip(bodyData,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip`,bodyData, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addLrNomenclature(bodyData,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip_lr_nomenclature`,bodyData, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripsDetails(bodyData,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/_search`,bodyData, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getSecondaryLocalTrips(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/SECONDARY_LOCAL/_search`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getSecondaryTripListingCounters(orgId, payload, type : 'local' | 'up-country'){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/${type}/counters`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalTripGeoFenceReport(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/geofence-report`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getUpcountryTripsReport(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/sec-upc/report`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalWarehouseDrivers(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/drivers`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalWarehouseVehicle(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/vehicles`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalTripDetails(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

deleteLocalTripDropPoint(tripId, dropPointId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/drop-point/${dropPointId}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

markTripDropPointUndelivered(tripId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/drop-point/undelivered`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalTripPointOfInterests(trackingUid){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.takshUrl}/taksh/api/v1/trip/${trackingUid}/point-of-interests`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

revokeSecondaryLocalTrip(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/revoke`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

assignVehicleToLocalTrip(tripId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/attach-vehicle-driver`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

startLocalTrip(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/start`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

changeLocalTripStatus(tripId, type: 'start' | 'end', payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/${type}`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

updateLocalTripDropPointLocation(tripId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .put<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/drop-point/location`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripVehicleWayPoints(vehicleTrackingUuid){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  let gstin = user.organization.id;
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.takshUrl}/taksh/api/v1/trip/${vehicleTrackingUuid}/location-points`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripVehicleRunningStatus(vehicleTrackingUuid, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  let gstin = user.organization.id;
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.takshUrl}/taksh/api/v1/trip/${vehicleTrackingUuid}/running-status`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

refreshTripId(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/refresh-location`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

refreshLocalTripWarehouseAndLocation(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/refresh`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripTollData(trackingUid){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
    .get<any>(`${environment.hopscotchBaseUrl}/hopscotch/api/v1/trip/${trackingUid}/transactions`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripDetails(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}`, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripDropPointWarehouseMapping(payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/ewb-address-warehouse-mapping/warehouses`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addEwbAddressToWarehouseMapping(dropPointId, warehouseId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/drop-point/${dropPointId}/warehouse/${warehouseId}/ewb-address-warehouse-mapping`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addDropPointToWarehouseMapping(ewbWarehouseId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/ewb-address-warehouse-mapping/${ewbWarehouseId}/trip/drop-point`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

createTripWarehouse(mfgId, payload): Observable<string> {
  var sessiontoken = this.storage.getItem('access-token', false);
  if (sessiontoken || sessiontoken != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${mfgId}/ewb-address/warehouse`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getEwayDetails(orgId,ewayBillNo){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${environment.imperiumBaseUrl}/imperium/api/v1/consignor/${orgId}/eway-bill/${ewayBillNo}`, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addDropPoint(tripId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/drop-point`,body, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


submitTrip(tripId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/eway-bills`,body, {
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTrackingOriginWarehouses(payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/warehouses/_search`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

createTripfromEwaybill(orgId,payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/eway-bill`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

donwloadTripLr(tripId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/lorry-receipt/document`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripFilterData(payload,orgId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/_filters`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getLocalTripsFilterData(payload,orgId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/SECONDARY_LOCAL/_filters`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripsDashboardCounters(payload, orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/dashboard-counters`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getNotificationSubGroups(orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/notification/group`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getNotificationSubData(orgId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/notification/subscribers/_search`,body, {
       
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getWhNotificaFilters(orgId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/notification/subscribers/_filters`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

createSubEmail(orgId,destId,groupId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/${destId}/notification/group/${groupId}/subscribers`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

createNotificationGroup(orgId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/notification/group`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getPendingGpsCounters(body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trips/gps-compliance/counters`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getPendingGpsIntegration(body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trips/gps-compliance/tsp-partners`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getGpsFeedNotPresent(body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trips/gps-compliance/vehicles`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getGpsComplianceData(body,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/gps-compliance`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


getGpsComplianceFilters(body,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${environment.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/gps-compliance/filters`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

validateTripfromEwaybill(orgId,payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/eway-bill`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


secondaryTripCreation(orgId,payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let orgId = user.organization.id;
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/eway-bill/drop-point`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getWarehouseDrivers(orgId, payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
  
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/drivers`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getWarehouseVehicles(orgId, payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/vehicles`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

  getOrgFileTemplates(orgId,datasetId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/fields/_search`,{}, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadSecondaryTripsRecords(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}flounder/api/v1/manufacturer/${orgId}/warehouse/records`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  uploadSecondaryTripsRecord(payload,datasetId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/record`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getLocalTripPendingInvoicesCounters(datasetId,bodyData){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/record/_counters`,bodyData, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getFlouderRecordsForDatasetId(orgId, payload, datasetId, enableViewSetting:boolean) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/records/_search?enableViewSetting=${enableViewSetting}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  markSecondaryLocalInvoicesAsDeleted(datasetId,externalRecordId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/record/${externalRecordId}/state`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  secondaryLocalTripCreation(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/SECONDARY_LOCAL`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  secondaryLocalTripUpdate(tripId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getWarehouseExternalIdentifier(orgId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse-external-identifier/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  createSecondaryNotificationGroup(orgId,whCode,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
   
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/${whCode}/notification/group`,body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  addSecondarySubscriberData(orgId, whCode, body) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {

      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/${whCode}/trip/notification-subscriber`, body, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
 
  addSecondaryEtaDealer(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse-transit-eta`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

updateSecondarySubscriberData(orgId,subId,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .put<any>(`${this.prodUrl}ryoko/api/v1/trip/notification-subscriber/${subId}`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

deleteSecondarySubscriberData(subId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}ryoko/api/v1/trip/notification-subscriber/${subId}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getSecondarySubscriberData(orgId,warehouseCode){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/warehouse/${warehouseCode}/trip/notification-subscriber`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

extendContractAuctionGroup(id,time){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
 
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction-group/${id}/extend/${time}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getUpcountryTripFilterData(payload,orgId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/SECONDARY_UP_COUNTRY/_filters`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getUpcountryTripListing(bodyData,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/UP/_search`,bodyData, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}
getUpcountryTripDetails(tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip/UP/${tripId}/_lite`,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getPicDetails(bodyData,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/tsp-partner/pic-contact-numbers/_search`,bodyData,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addPICDetails(orgId,bodyData){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/tsp-partner/pic-contact-number`,bodyData,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


deletePICDetails(id){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}ryoko/api/v1/tsp-partner/pic-contact-number/${id}`,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


addRfqTerms(rfqId,bodyData){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/terms`,bodyData,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

deleteRfqTerms(id,rfqId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/term/${id}`,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getRfqTerms(rfqId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/terms`,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addLrDetails(dropId,bodyData){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/SECONDARY_UP_COUNTRY/drop-point/${dropId}/lorry-receipt`,bodyData,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

refreshEwayBill(dropId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/drop-point/${dropId}/eway-bill/refresh`,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getSecondaryTripDropPointLrDetails(dropId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/trip-drop-point/${dropId}/lorry-receipt`,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

addContractTerms(contractId,bodyData){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${contractId}/terms`,bodyData,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

deleteContractTerms(id,contractId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${contractId}/term/${id}`,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getContractTerms(contractId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}onboarding/api/v1/contract-auction/${contractId}/terms`,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

makeUpcVeh(body,tripId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/tracked-leg-number`,body,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getIndentSavingsReport(orgId, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/report/manufacturer/${orgId}/indent/savings`, payload,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTransitTimeReport(body,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/local/transit-time-report`,body,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

}

getServiceLevelReport(body,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/local/delivery-report`,body,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

}

getSameDayServiceLevelReport(body,orgId){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/local/dispatch-report`,body,{
        
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

}

downloadEwbJson(ewbNo){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}ryoko/api/v1/eway-bill/${ewbNo}`,{
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getNotificationStatus(type: 'spot-auction', id ){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}onboarding/api/v1/${type}/${id}/email-categories`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getNotificationEmailCopy(type: 'spot-auction', id, payload){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/${type}/${id}/email-vendor-events`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

  getVehicleDetails(vehNumber){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${environment.polarisUrl}/polaris/api/v1/vehicle-detail/${vehNumber}/summary?vehicleDetailProvider=vahan`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }
  
  initiateIndentVehicleDriverConsent(indentVehicleId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}lmnop/api/v1/indent-vehicle/${indentVehicleId}/driver/consent`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  validateLane(orgId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/contract-auction-lane/_validate`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  getOdPairData(orgId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .post<any>(`${this.prodUrl}onboarding/api/v1/manufacturer/${orgId}/city-lane/_lite-search`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  addGRN(id,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .post<any>(`${this.turingUrl}/turing-teller/api/v1/indent/invoice/${id}/grn`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  addInvoicePaymentStatus(invoiceId,payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .post<any>(`${this.turingUrl}/turing-teller/api/v1/indent/invoice/${invoiceId}/payment`,payload,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  deleteInvoicePayment(invoicePayId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .delete<any>(`${this.turingUrl}/turing-teller/api/v1/payment/${invoicePayId}`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getDestinationWh(id){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .get<any>(`${this.prodUrl}onboarding/api/v1/warehouse/${id}/lanes`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getSpotAuctionApprovalRulesOfWarehouse(orgId, payload){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .post<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/result/approval-rule/_search`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getOrgLevelApprovers(orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/result/approval/config`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  checkIsApproveResultBtnApplicable(orgId,auctionId,emailId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
      .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/spot-auction/${auctionId}/authorizer?email=${emailId}`,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getVehicleLocation(vehicleNo){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${environment.takshUrl}/taksh/api/v1/vehicle/${vehicleNo}/location`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }
  
  getDispatchReport(body,orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/daily-dispatch-report`,body,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  getSummaryReport(reportType,datasetId,body){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.flounderUrl}/flounder/api/v1/dataset/${datasetId}/record/report/${reportType}`,body,{
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getDashboardUrl(orgId,reportId){
    var sessiontoken = this.storage.getItem('access-token', false);
    if (sessiontoken || sessiontoken != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);

      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/manufacturer/${orgId}/embed-dashboard/${reportId}/generate-url`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }
  
  getVehWiseDispatchReport(body,orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/vehicle-wise-dispatch-report`,body,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  getDealerWiseDispatchReport(body,orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/dealer-wise-dispatch-report`,body,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  getActualVsEstimatedReport(body,orgId){
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.rubixUrl}/rubix/api/v1/manufacturer/${orgId}/trip/local/distance-report`,body,{
          
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  
  }

  getTripMetadata(orgId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/manufacturer/${orgId}/trip/SECONDARY_LOCAL/metadata`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  shareTripDetails(tripId, dropId,payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}ryoko/api/v1/trip/${tripId}/drop-point/${dropId}/notifications`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

    validateRfqBaseRates(rfqId, payload) {
      let sessiontoken = this.storage.getItem('access-token', false);
      let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
      if (sessiontoken && sessiontoken != '' && user && user != '') {
        let options = new HttpHeaders().set('Authorization', sessiontoken);
        return this.http
          .post<any>(`${this.prodUrl}kremlin/api/v1/validate/rfq/${rfqId}/base-quote`, payload, {
            headers: options
          })
          .pipe(
            map(data => {
              let dataToCache = data;
              return dataToCache;
            }),
            catchError(this.handleError.bind(this))
          ) as Observable<any>;
      }
    }

  rfqVersionUpdates(rfqId,versionId, payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .put<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/version/${versionId}`, payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  getBaseQuotes(rfqId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/base-quotes`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  deleteBaseQuote(rfqId,baseQuoteId) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/base-quote/${baseQuoteId}`, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  validateTspInvitation(rfqId,payload) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/validate/rfq/${rfqId}/version-response-parameter`,payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

  uploadtspInvite(rfqId,payload,queryParam?) {
    let sessiontoken = this.storage.getItem('access-token', false);
    let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
    if (sessiontoken && sessiontoken != '' && user && user != '') {
      let options = new HttpHeaders().set('Authorization', sessiontoken);
      return this.http
        .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/version-response-parameter${queryParam}`,payload, {
          headers: options
        })
        .pipe(
          map(data => {
            let dataToCache = data;
            return dataToCache;
          }),
          catchError(this.handleError.bind(this))
        ) as Observable<any>;
    }
  }

getInvitedTsps(rfqId,version,body) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/version/${version}/response-parameters/_search`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

deleteInvitedTransporter(rfqId,recordId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .delete<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/version-response-parameter/${recordId}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

checkAllTspInviteStatus(payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}kremlin/api/v1/long-running-tasks/_search`,payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}


flushAllInvitedTsp(rfqId,version) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .get<any>(`${this.prodUrl}kremlin/api/v1/rfq/${rfqId}/version/${version}/response-parameters/_flush`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getListContractSynopsis(orgId,contractType,payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.papyrusUrl}/papyrus/api/v1/org/${orgId}/${contractType}/synopsis`,payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getCommentsCount(entity,body){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${environment.kiwiUrl}/kiwi/api/v1/comment/${entity}/_search`,body, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

getTripPOIs(trackingUuid){
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}lmnop/api/v1/sim-tracking/${trackingUuid}/point-of-interests`,{}, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

validateBulkUpload(orgId, payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.prodUrl}lmnop/api/v1/manufacturer/${orgId}/payload/indent/_generate`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

refreshEwayBillDetails( payload) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true'?this.storage.getItem('user', false):JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.flounderUrl}/flounder/api/v2/dataset_custom_workflow/8`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

refreshEwayBillWithDataSetId(payload, datasetId) {
  let sessiontoken = this.storage.getItem('access-token', false);
  let user = sessionStorage.getItem('onhibernate') != 'true' ? this.storage.getItem('user', false) : JSON.parse(sessionStorage.getItem('user'));
  if (sessiontoken && sessiontoken != '' && user && user != '') {
    let options = new HttpHeaders().set('Authorization', sessiontoken);
    return this.http
      .post<any>(`${this.flounderUrl}/flounder/api/v2/dataset/${datasetId}/custom_workflow`, payload, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
}

}
