<div class="ff_dialog_box dialogContent">
  <div class="ff_header">
    <div class="ff_title">
      {{pointOfCreation}} Details
    </div>
    <img src="/assets/images/cross-button.svg" alt="Close" (click)="closeDialogBox()" class="ff_close_icon">
  </div>
  <div class="ff_content ff-form-theme">
    <form [formGroup]="addWarehouseForm" autocomplete="off">
      <div class="sub-section sec_topM">
        <div *ngIf="warehouseCallType == 'SPOT-BID'" class="half-width  destinationType">
          <label class="ff-label">
            {{pointOfCreation}} Type <span class="red-color">*</span>
          </label>
          <mat-form-field appearance="outline" class="">
            <mat-select 
              placeholder="Select {{pointOfCreation}} Type" 
              class="select-role" formControlName="type">
              <ng-container *ngFor="let whType of destinationWhTypes">
                <mat-option [value]="whType.value">{{whType.key}}</mat-option>
              </ng-container>
            </mat-select>
            <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
          </mat-form-field>
          <mat-error
            *ngIf="addWarehouseForm.get('type').invalid && addWarehouseForm.get('type').touched && addWarehouseForm.get('type').errors?.required">
            Type is required</mat-error>
        </div>
        <div class="half-width ">
          <label class="ff-label">
            {{pointOfCreation}} Name <span class="red-color">*</span>
          </label>
          <mat-form-field *ngIf="warehouseCallType == 'SPOT-BID'" appearance="outline" class=" search-data">
            <input type="text" matInput placeholder="Enter {{pointOfCreation}} Name" formControlName="name">
            <span matSuffix (mouseenter)="mouseHover('ENTER', $event)" (mouseleave)="mouseHover('LEAVE', $event)"
              class="toolTipHover"><img style="width: 18px;position: relative;"
                src="/assets/images/question-info.svg"></span>
            <span #customTip *ngIf="showInfoData" class="customToolTip">
              <div class="toolTipView">
                <p class="toolTipGroup">Enter an easily identifiable name for this address so it can be searched easily
                  later.<br>e.g. use 'Kalpataru Traders' for 'Kalpataru Traders, Opposite Bhagwan Chowk, Aundh, Pune -
                  411078'
                </p>
              </div>
            </span>
          </mat-form-field>
          <p *ngIf="warehouseCallType == 'TRIP'" class="readonly-value">
            {{addWarehouseForm.get('name').value}} ({{addWarehouseForm.get('externalCode').value}})
          </p>
        </div>
      </div>
      <div class="sub-section sec_topM">
        <div class="lg-field full-width">
          <label class="ff-label">{{pointOfCreation}} Address <span class="red-color">*</span></label>
          <mat-form-field appearance="outline" class="full-width">
            <input id="googleSuggestionsInput" type="text" matInput placeholder="Search {{pointOfCreation}} Address"
              formControlName="address" type="text" (input)="handleSearchInput($event)" autocomplete="new-password">
          </mat-form-field>
          <div class="suggestions" *ngIf="suggestions.length > 0">
            <div class="suggestion" (click)="selectedAddress(suggestion)" *ngFor="let suggestion of suggestions">
              {{ suggestion.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="sub-section sec_topM justify-center">
        <div class="sm-field ">
          <label class="ff-label">Pin code <span class="red-color">*</span></label>
          <mat-form-field appearance="outline" class="full-width">
            <input type="number" formControlName="pincode" matInput placeholder="Pin code" autocomplete="new-password">
          </mat-form-field>
        </div>
        <div class="sm-field" #customMatchingCity>
          <label class="ff-label">City <span class="red-color">*</span></label>
          <mat-form-field appearance="outline" class="full-width">
            <input type="text" matInput formControlName="city" autocomplete="new-password"
              (ngModelChange)="getSimilarCities();" placeholder="City">
          </mat-form-field>
          <div class="similarCities-panel" *ngIf="ShowMatchingCities && similarCitiesData.length > 0">
            <p class="matching-city-text">Matching Cities</p>
            <p class="pointer Results-text" *ngFor="let city of similarCitiesData" (click)="setCityData(city.city)">
              {{city.city}}</p>
          </div>
        </div>
        <div class="sm-field destinationType">
          <label class="ff-label">State <span class="red-color">*</span></label>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select [panelClass]="'customSearchHeight'" placeholder="Select State" formControlName="state"
              (selectionChange)="getSimilarCities()" class="select-role" required #singleSelectTag>
              <mat-option class="customSearch">
                <ngx-mat-select-search class="tagmat search-field" [clearSearchInput]="true"
                  [placeholderLabel]="'Select State'" [noEntriesFoundLabel]="'No matching records found'"
                  [formControl]="stateSearchmattag" (ngModelChange)="destinationModelChangedTags()" ngModel
                  ngDefaultControl>
                  <div class="mat-select-search-custom-header-content">
                    <i class="material-icons">
                      search
                    </i>
                  </div>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option class="customSearchOptions" *ngFor="let state of stateFilteredTags | async" [value]="state">
                {{state}}
              </mat-option>
            </mat-select>
            <img matSuffix class="ff_mat-suffix-icon" src="/assets/images/accordian-1px.svg">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="warehouseCallType == 'TRIP'" class="city-row">
        <div class="half-width">
          <label class="ff-label">Dealer Type <span class="red-color">*</span></label>
          <mat-form-field appearance="outline" class="full-width">
            <mat-select panelClass="ff-mat-search" placeholder="Select Type" formControlName="dealerType"
              (selectionChange)="dealerTypeChange()" class="select-role">
              <mat-option [value]="'LO'">Local</mat-option>
              <mat-option [value]="'UP'">Upcountry</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="half-width">
          <label class="ff-label">Locality <span class="red-color">*</span></label>
          <mat-form-field *ngIf="addWarehouseForm.get('dealerType').value != 'UP'" appearance="outline"
            class="full-width">
            <input class="sentance-case" type="text" matInput formControlName="cityLocality" autocomplete="off"
              placeholder="Locality">
          </mat-form-field>
          <p *ngIf="addWarehouseForm.get('dealerType').value == 'UP'" class="readonly-value">
            {{addWarehouseForm.get('cityLocality').value}}
          </p>
        </div>

      </div>
      <div class="city-row">
        <div class="half-width">
          <label class="ff-label">GSTIN</label>
          <mat-form-field appearance="outline" class="full-width">
            <input class="upper-case" type="text" matInput placeholder="Enter Gstin" formControlName="gstin">
          </mat-form-field>
          <mat-error *ngIf="addWarehouseForm.get('gstin').invalid && addWarehouseForm.get('gstin').touched">
            <mat-error *ngIf="addWarehouseForm.get('gstin').errors?.pattern" class="text-danger">
              Enter valid Gstin</mat-error>
          </mat-error>
        </div>
        <div class="half-width"></div>
      </div>
      <div class="full-width" *ngIf="addWarehouseForm.get('address').value">
        <label class="ff-label">Detailed Address </label>
        <p>{{addWarehouseForm.get('address').value}}</p>
      </div>

    </form>
  </div>
  <div class="ff_footer">
    <div class="ff_action_buttons">
      <button class="ff_button_32_transparent" (click)="closeDialogBox()">
        Cancel
      </button>
      <button class="ff_button_32 ff_leftM_12" [disabled]="addWarehouseForm.invalid" (click)="confirmOperation()">
        Create Warehouse
      </button>
    </div>
  </div>
</div>