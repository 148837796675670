import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  filters: any = [];
  selectedFilter: any = [];
  @Output() filterchanged = new EventEmitter<any>();
  @Input() minifilter: any = false;
  @Input() filtermetadata: Observable<any>;
  /*
    filtermetadata format should be as below
    {
    "Age of the Entity": [
        {
            "dimension": "0-3 Yrs",
            "measure": 6
        },
        {
            "dimension": "3-5 Yrs",
            "measure": 4
        }
      ]
    }
  */

  constructor() { }

  ngOnInit() {
    if(this.filtermetadata) {
      this.transformData(this.filtermetadata);
    }
  }

  ngOnChanges() {
    if(this.filtermetadata) {
      this.transformData(this.filtermetadata);
    }
  }

  

  transformData(data) {
    if (data) {
      let keys = Object.keys(data);
      this.filters = [];
      keys.forEach(item => {
        let values = this.selectedFilter.filter(ele => {return ele.value.name === item });
        values = values.map(ele => {return ele.value.value});
        let filter = {
          name: item,
          value: values,
        };
        filter["values"] = data[item];
        this.filters.push(filter);
      });
    }
  }

  filterSelected(target, filter) {
    if (target) {
      let selectedFilters = this.selectedFilter.filter(item => { return item.value.name === filter.name });
      let addArray = [];
      let found = [];
      let notfound = [];
      if (selectedFilters && selectedFilters.length > 0) {
        target.value.forEach(value => {
          if (!selectedFilters.find(item => item.value.value === value)) {
            addArray.push(value);
          } else {
            found.push(value);
          }
        });
      }

      if(!target.value || target.value.length === 0) {
        selectedFilters.forEach(selected => {
          notfound.push(selected.key);
        });
      }
      else if (found.length === 0) {
        addArray = target.value;
      } else {
        selectedFilters.forEach(selected => {
          let data = target.value.find(item => item === selected.value.value);
          if (!data) {
            notfound.push(selected.key);
          }
        });
      }
      this.addFilter(filter.name, addArray);
      this.removeFilter(notfound, null);
      this.emitFilterChanged();
    }
  }

  clearFilter() {
    this.selectedFilter = [];
    this.filters.forEach(item => {item.value = ""});
    this.emitFilterChanged();
  }

  addFilter(name, filter) {
    filter.forEach(value => {
      this.selectedFilter.push({
        key: name + ":" + value,
        value: {
          name: name,
          displayName: name.split(' ')[0] + " - " + value,
          value: value
        }
      });
    });
  }

  removeFilter(key, applyFilter) {
    if (!Array.isArray(key)) {
      key = [key];
    }
    key.forEach(item => {
      let toDelete = this.selectedFilter.find((item) => {
        if (item.key == key) {
          return item;
        }
      });
      if (toDelete) {
        this.selectedFilter.splice(this.selectedFilter.indexOf(toDelete), 1);
      }
    });
    if(applyFilter) {
      this.emitFilterChanged();
    }
  }

  emitFilterChanged() {
    let filters = {};
    this.selectedFilter.forEach(item => {
      let name = item.value.name;
      if (!filters[name]) {
        filters[name] = [];
      }
      let data = this.filters.find(f => f.name === name);
      data.value = item.value.value;
      filters[name].push(item.value.value);
    });
    this.filters.forEach(item => {
      item.value = filters[item.name];
    });
    this.filterchanged.emit(filters);
  }

  toCamelCase(str) {
    if(str && str.length > 0) {
      let words = str.split(' ');
      let res = [];
      words.forEach(ele => {
        let rest = ele.substr(1).toLowerCase();
        let first = ele.substr(0, 1).toUpperCase();
        res.push(first + rest);
      });
      return res.join(' ');
    }
    return str;
  }
}
