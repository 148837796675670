import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-unloading-observations-view',
  templateUrl: './unloading-observations-view.component.html',
  styleUrls: ['./unloading-observations-view.component.scss']
})
export class UnloadingObservationsViewComponent implements OnInit {

  requestParticular:any = [
    {
      particular: 'ABC',
      sku: 'SBIN009',
      image: '/assets/images/can.svg',
      damage: 500,
      shortage: 100,
      leakage: 100,
    }
  ];
  repackageCharge:number;
  remarks:string;

  constructor(
    public dialogRef: MatDialogRef<UnloadingObservationsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public obsData: any,
  ) { }

  ngOnInit(): void {
    this.repackageCharge = this.obsData.repackagingCharges ? this.obsData.repackagingCharges : 0;
    this.remarks = this.obsData.remarks ? this.obsData.remarks : 'NA';
    this.requestParticular = [];
    for(let material of this.obsData.materials){
      let image = material.packagingUnit === 'Bottles' ? '/assets/images/bottle.svg' : '/assets/images/can.svg';
      let matDetails = {
        particular: material.material,
        sku: material.sku,
        image: image,
        damage: material.breakageQuantity ? material.breakageQuantity : 0,
        shortage: material.shortageQuantity ? material.shortageQuantity : 0,
        leakage: material.leakageQuantity ? material.leakageQuantity : 0,
      }
      this.requestParticular.push(matDetails);
    }
  }

  closeDialogBox(){
    let returnData = {
      status: 'CLOSED',
    }
    this.dialogRef.close(returnData);
  }

}
