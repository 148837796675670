import {
   Pipe,
   PipeTransform
} from '@angular/core';

@Pipe({
   name: 'twoDigitPrecision'
})

export class TwoDigitPrecision implements PipeTransform {
   transform(value: number): string {
      if (value % 1 !== 0) {
         return value.toFixed(2);
       } else {
         return value.toString();
      }
   }
} 