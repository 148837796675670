import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SnackbarService } from 'src/app/snackbarService/snackbar.service';
import { SnackbarComponent } from 'src/app/snackbarService/snackbar/snackbar.component';

@Component({
  selector: 'app-ff-time-picker',
  templateUrl: './ff-time-picker.component.html',
  styleUrls: ['./ff-time-picker.component.scss']
})
export class FfTimePickerComponent {
  
  selectedHour: number;
  selectedMinute: number;
  selectedZone: string;
  timeHour: number[] = [];
  timeMinutes: number[] = [];
  afterNoon: boolean = false;
  timeZone: string[] = ['AM', 'PM'];
  time: any;
  @ViewChild('customTip', { static: false }) menu: ElementRef;
  @Input() dateSelected: string;
  @Input() step: number;
  @Input() restrict90Minutes: boolean;
  @Output() onTimePicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private snackbar: SnackbarService,
  ) { }

  ngOnInit() {
    if(!this.step){
      this.step = 1;
    }
    for (let i = 1; i <= 12; i++) {
      this.timeHour.push(i);
    }
    for (let i = 0; i < 60; i += this.step) {
      this.timeMinutes.push(i);
    }
    const currentDate = new Date();
    const selectedDate = new Date(this.dateSelected);
    if (this.dateSelected) {
      if (selectedDate.toDateString() === currentDate.toDateString()) {
        let hrs = currentDate.getHours();
        let mins = currentDate.getMinutes();
        if(this.restrict90Minutes){
         mins = mins + 90; 
          if (mins >= 60) {
            hrs += Math.floor(mins / 60);
            mins = mins % 60;
          }
        }
        else{
          mins = currentDate.getMinutes();
        }
       

        if (hrs >= 12) {
          this.timeZone = ['PM'];
          this.afterNoon = true;
          this.timeHour = [];
          let totalHrs = hrs - 12;
          for (let i = totalHrs; i < 12; i++) {
            if (i == 0) {
              this.timeHour.push(12);
            } else {
              this.timeHour.push(i);
            }
          }
          this.timeHour.sort((a, b) => a - b);
          if (mins > 59 && hrs != 12) {
            this.timeHour.shift();
          }
          else if (mins + this.step > 59 && hrs != 12) {
            this.timeHour.shift();
          }
           else if (hrs == 12 && mins > 59) {
            this.timeHour.pop();
          }
        } else if (hrs < 12) {
          this.timeZone = ['AM', 'PM'];
        }

      } else {
        // If it's not today's date, show all hours (1-12) and minutes (0-59)
        this.timeHour = [];
        for (let i = 1; i <= 12; i++) {
          this.timeHour.push(i);
        }
        this.timeZone = ['AM', 'PM'];
      }
    }
  }

  selectHour(hour) {
    this.selectedHour = hour;
    let currentTime = new Date();
    let mins = currentTime.getMinutes();
    let hrs = currentTime.getHours();
    if(this.restrict90Minutes){
        mins = mins + 90
      if (mins >= 60) {
        hrs += Math.floor(mins / 60);
        mins = mins % 60;
      }
    }

    if (this.dateSelected) {
      if (!this.afterNoon) {
        if (this.isTodaysDate()) {
          if ((this.selectedHour < hrs && hrs < 12) || (this.selectedHour == 12 && hrs != 0)) {
            if (this.selectedZone == 'AM') {
              this.selectedZone = '';
            }
            this.timeZone = ['PM'];
          }
          else if ((this.selectedHour >= hrs && hrs < 12) || (hrs == 0)) {
            this.timeZone = ['AM', 'PM'];
          }
        }
      }
      if (this.isTodaysDate() && this.dateSelected) {
        if (currentTime.getHours() >= 12) { //for PM
          let hourSelected;
          if (this.selectedHour != 12) {
            hourSelected = 12 + this.selectedHour;
          } else {
            hourSelected = this.selectedHour;
          }
          let newTime = new Date();
          if(this.restrict90Minutes){
            let mins = currentTime.getMinutes() + 90;
            let adjustedHours = currentTime.getHours() + Math.floor(mins / 60);
            let adjustedMinutes = mins % 60;

            if (adjustedHours >= 24) {
                adjustedHours = adjustedHours % 24;
            }
            newTime.setHours(adjustedHours);
            newTime.setMinutes(adjustedMinutes);
          }
         

          if (hourSelected == newTime.getHours()) {
            let currentMin = newTime.getMinutes();

            let startTime = this.timeMinutes.find(element => element > currentMin);
            this.timeMinutes = [];
            for (let i = startTime ; i < 60; i += this.step) {
              this.timeMinutes.push(i);
            }
          } else {
            this.timeMinutes = [];
            for (let i = 0; i < 60; i += this.step) {
              this.timeMinutes.push(i);
            }
          }
        }
        else { 
          let newTime = new Date();

          if(this.restrict90Minutes) {
            let mins = currentTime.getMinutes() + 90;
            let adjustedHours = currentTime.getHours() + Math.floor(mins / 60);
            let adjustedMinutes = mins % 60;
            if (adjustedHours >= 24) {
              adjustedHours = adjustedHours % 24;
            }
          
            newTime.setHours(adjustedHours);
            newTime.setMinutes(adjustedMinutes);
          }

          if (this.selectedHour == newTime.getHours()) {
            let currentMin = newTime.getMinutes();
            let startTime = this.timeMinutes.find(element => element > currentMin);
            this.timeMinutes = [];
            for (let i = startTime ; i < 60; i += this.step) {
              this.timeMinutes.push(i);
            }
          } else {
            this.timeMinutes = [];
            for (let i = 0; i < 60; i += this.step) {
              this.timeMinutes.push(i);
            }
          }

        }
      }
    }

    if (this.selectedMinute) {
      this.selectMin(this.selectedMinute);
    }
    this.checkClose();
  }

  isTodaysDate() {
    let currentTime = new Date();
    let placeDate = new Date(this.dateSelected);
    return ((placeDate.getDate() === currentTime.getDate())
      && (placeDate.getMonth() === currentTime.getMonth())
      && (placeDate.getFullYear() === currentTime.getFullYear()));
  }

  selectMin(minute: number) {
    this.selectedMinute = minute;
  }

  selectTimeZone(zone: string) {
    if (this.selectedHour == null || this.selectedMinute == null) {
      this.snackbar.showInfoSnackBar("Select Hour/Minutes");
      return;
    }
    this.selectedZone = zone;
    this.checkClose();
  }

  submitTime() {
    this.time = {
      hour: this.selectedHour,
      minute: this.selectedMinute,
      zone: this.selectedZone
    };
    this.onTimePicked.emit(this.time);
  }

  checkClose() {
    if (this.selectedHour && (this.selectedMinute || this.selectedMinute == 0) && this.selectedZone) {
      setTimeout(() => {
        this.submitTime();
      }, 150);
    }
  }
}
