import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackbarService } from '../snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  showSnackbar:boolean = false;
  type:String = 'success';
  heading:String = 'Success';
  message:String = 'Success message';
  headerImg:any;
  snackbarSubscription: Subscription;

  constructor(
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.snackbarSubscription = this.snackbarService.snackbarState
    .subscribe( state => {
      this.type = state.type;
      switch(this.type){
        case 'success':
          this.heading = 'Success';
          this.headerImg = '/assets/images/success-snack.svg';
          break;
        case 'error':
          this.heading = 'Error';
          this.headerImg = '/assets/images/error-snackbar.svg';
          break;
        case 'information':
          this.heading = 'Information';
          this.headerImg = '/assets/images/info-snackbar.svg';
          break;
        case 'info-warning':
          this.heading = 'Information';
          this.headerImg = '/assets/images/info-snackbar.svg';
          break;
        case 'warning':
          this.heading = 'Warning';
          this.headerImg = '/assets/images/warning-icon.svg';
          break;
      }
      this.message = state.message;
      this.showSnackbar = state.showSnackbar
      setTimeout(() => {
        this.showSnackbar = false;
      }, 5000);
    })
  }

  ngOnDestroy(){
    this.snackbarSubscription.unsubscribe();
  }

  closeSnackbar(){
    this.showSnackbar = false;
  }

}
