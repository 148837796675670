<div class="ff_dialog_box">
    <div class="ff_header">
        <div class="ff_title">
            Information
        </div>
        <img src="/assets/images/cross-button.svg" alt="Close" (click)="closeDialogBox()" class="ff_close_icon">
    </div>
    <div class="ff_content">
        <div class="readonly-text">
            {{infoMessage}}
        </div>
    </div>
    <div class="ff_footer">
        <div class="ff_action_buttons">
            <button (click)="closeDialogBox()" class="ff_button_32-80 ff_leftM_12">
                Close
            </button>
        </div>
    </div>
</div>