export const environment = {
  production: false,
  mapBoxApi: 'pk.eyJ1Ijoic2FuZHktZmYiLCJhIjoiY2txMjZpdXhvMDBtYjJ3dXN5bTQ2NGNwciJ9.uSv4qSGu2fSOY1YMskyzMw',
  apiBaseUrl: 'https://staging-app.freightfox.ai/',
  rbacBaseUrl: 'https://staging-rbac.freightfox.ai/',
  s3baseUrl: 'https://staging-doraemon.freightfox.ai/',
  takshUrl: 'https://staging-taksh.freightfox.ai',
  uiBaseUrl: 'http://localhost:4200',
  rubixUrl: 'https://dev-rubix.freightfox.ai',
  octopusBaseUrl: 'https://dev-oculus.freightfox.ai',
  polarisUrl: 'https://dev-polaris.freightfox.ai',
  doremonDocKey: 'FYZCG7c6AyJsAFR5+vQLizg9/PuQAV6QMUTyxOqdpEc=',
  kiwiUrl: 'https://dev-kiwi.freightfox.ai',
  greenMilesBaseUrl: 'https://dev-groot.freightfox.ai/',
  imperiumBaseUrl: 'https://dev-imperium.freightfox.ai',
  smartLinkBaseurl: 'https://staging-t.freightfox.ai',
  hopscotchBaseUrl:'https://dev-hopscotch.freightfox.ai',
  cellCuerUrl: 'https://dev-cell-cuer.freightfox.ai',
  flounderUrl: 'https://staging-flounder.freightfox.ai',
  papyrusUrl: 'https://staging-papyrus.freightfox.ai',
  celestialUrl: 'https://dev-celestial-circuit.freightfox.ai/',
  odinUrl: 'https://dev-odin.freightfox.ai',
  conciergeUrl: 'https://staging-concierge.freightfox.ai',
  turingUrl: 'https://staging-turing-teller.freightfox.ai',
  moorageUrl: 'https://staging-moorage.freightfox.ai',
  
  firebaseConfig: {
    apiKey: "AIzaSyBp4aMe6VQ6IerllD04tGGDb5RjnhnFj1w",
    authDomain: "dev-auction-arranger.firebaseapp.com",
    databaseURL: "https://dev-auction-arranger-default-rtdb.firebaseio.com/",
    projectId: "dev-auction-arranger",
    storageBucket: "dev-auction-arranger.appspot.com",
    messagingSenderId: "413193102613",
    appId: "1:413193102613:web:e6202feb990d852048b51c",
    measurementId: "G-995VKTZT57"
  },
  ryokoFirebaseConfig: {
    apiKey: "AIzaSyBV-oSDRufIwDNRZakTy-mt1tzIy5rS7R0",
    authDomain: "dev-ryoko.firebaseapp.com",
    databaseURL: "https://dev-ryoko-default-rtdb.firebaseio.com",
    projectId: "dev-ryoko",
    storageBucket: "dev-ryoko.appspot.com",
    messagingSenderId: "929638025286",
    appId: "1:929638025286:web:af4672b7c650794e496247"
  },
  googleMapsApi: 'AIzaSyB8RQpwAXlGkhgESACXX9kt6PZGeiD8EJs',
};
