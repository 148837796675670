import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public errorMessage: any;
  public formSubmitted: boolean = false;
  public clicked: boolean = false;
  public resetPasswordForm = this.fb.group({
    email: ['', [Validators.required,
      Validators.pattern('^.+@.+([.].+)+$')]],
  });

  constructor(public fb: UntypedFormBuilder, 
    private AuthenticateService: AuthService, 
    private router: Router, 
    private _snackBar: MatSnackBar,) { }

  ngOnInit() {
  }


  sendOtp() {
    this.formSubmitted = true;
    if(!this.resetPasswordForm.valid){
      return ;
    }
    else{
      this.AuthenticateService.sendOTP(this.resetPasswordForm.value.email).subscribe((data) => {
        if (data && data["success"]) {
        this.formSubmitted = true;
        this.router.navigate(['/update-password', {email: this.resetPasswordForm.value.email}]);
         this._snackBar.open(`OTP has been sent successfully to ${this.resetPasswordForm.value.email}`, 'OK', {
            duration: 5000,
            panelClass:['auth-success-snack-bar']
          });
          
        }
      }, error => {
          this._snackBar.open(`${this.resetPasswordForm.value.email} doesn't exist `, 'OK', {
            duration: 5000,
            panelClass:['auth-error-snack-bar']
          });
      });
    }
    
  }

  goTologin(){
    this.router.navigate(['/login']);
  }


  submitResetPassword(event) {
    if (event.keyCode == 13) {
        this.sendOtp();
      }
  }

}

