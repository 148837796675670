import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AdminService } from 'src/app/admin/admin.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SnackbarService } from 'src/app/snackbarService/snackbar.service';
import { TransporterService } from 'src/app/transporter/transporter.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-group-tsp',
  templateUrl: './group-tsp.component.html',
  styleUrls: ['./group-tsp.component.scss']
})
export class PrivateTspGroupComponent implements OnInit {

  showLoader: any = true;
  searchInput: any;
  searchInputGroup: any;
  tspList: any = [];
  completeTspList: any = [];
  leftVisibleTspList: any = [];
  selectedTspList: any = [];
  select_all_setting: any = false;
  select_all: any = false;
  filteredTsp: any = [];
  nonEditiableTsps:any = [];
  hideSelectedTsps: any;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private adminService: AdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrivateTspGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {

    this.searchInput = this._formBuilder.group({
      inputValue: [''],
    });

    this.searchInputGroup = this._formBuilder.group({
      inputValue: [''],
    });
    this.hideSelectedTsps = this.data.editMode == 'HIDE_SELECTED_TSP' ? true : false;
    this.searchInput.get('inputValue').valueChanges.subscribe(value => {
      if (value.length >= 1) {
        this.leftVisibleTspList = []
        this.completeTspList.forEach(tsp => {
          if (tsp['companyName'].toLowerCase().includes(value.toLowerCase())) {
            this.leftVisibleTspList.push(tsp);
          }
        });
      } else {
        this.leftVisibleTspList = this.completeTspList.slice();
      }
    });

    this.searchInputGroup.get('inputValue').valueChanges.subscribe(value => {
      if (value.length >= 1) {
        this.selectedTspList = []
        this.filteredTsp.forEach(tsp => {
          if (tsp['companyName'].toLowerCase().includes(value.toLowerCase())) {
            this.selectedTspList.push(tsp);
          }
        });
      } else {
        this.selectedTspList = this.filteredTsp.slice();
      }
    });

    this.getTSPDetails();
  }


  getTSPDetails() {
    let page_size = 9999;
    let current_page = 1;
    let sortBy = 'TSP_NAME';
    let searchText = '';
    let filters = {
      'isApproved': [true]
    }
    this.adminService.getTransporterFilterData(page_size, current_page, filters, searchText, sortBy)
      .pipe(
        catchError((error: any) => {
          this.showLoader = false;
          return throwError(error);
        })
      )
      .subscribe(data => {
        this.showLoader = false;
        this.completeTspList = data['transporters'];
       
        this.leftVisibleTspList = this.completeTspList.slice();
       
        if (this.data.tspList.length) {
          this.patchInitialValues();
        }
      });
  }

  patchInitialValues() {
    if (this.data.editMode != 'HIDE_SELECTED_TSP') {
      this.selectedTspList = this.data.tspList;
      this.selectedTspList.forEach(tspData => {
        tspData.isSelected = true;
        tspData.alreadyGrouped = true;
      });

      this.data.tspList.forEach(tspData => {
        let result = this.leftVisibleTspList.find(tsp => { return tsp.id === tspData.id });
        if (result) {
          result.isSelected = true;
          result.alreadyGrouped = true;
        }
      });
      this.filteredTsp = this.selectedTspList.slice();
    }
    else{
      this.nonEditiableTsps = [];
      let result = this.completeTspList.filter((elem) => !this.data.tspList.some(({ tspPartner }) => elem.id === tspPartner));
      this.completeTspList = result;
      this.leftVisibleTspList = this.completeTspList.slice();
      this.nonEditiableTsps = this.data.tspList;
      this.filteredTsp = this.selectedTspList.slice();
    }

  }
  onSelectAll(e: any, mode): void {
    if (mode == 'originallistSide') {
      for (let i = 0; i < this.leftVisibleTspList.length; i++) {
        if (!this.leftVisibleTspList[i].alreadyGrouped) {
          const item = this.leftVisibleTspList[i];
          item.isSelected = e;
        }
      }
    }
    else {
      for (let i = 0; i < this.selectedTspList.length; i++) {
        const item = this.selectedTspList[i];
        item.moveToOriginalList = e;
      }
    }
  }

  checkTspDisability() {
    let result = this.leftVisibleTspList.find(tsp => { return !tsp.alreadyGrouped && tsp.isSelected });
    if (result) {
      return false;
    }
    else {
      return true;
    }
  }

  checkShortlistedDisability() {
    let result = this.selectedTspList.find(tsp => { return tsp.moveToOriginalList });
    if (result) {
      return false;
    }
    else {
      return true;
    }
  }

  checkListStatus() {
    let status = true;
    this.leftVisibleTspList.forEach(tsp => {
      if (!tsp.alreadyGrouped) {
        status = false;
      }
    });
    return status;
  }

  checkSelections(): boolean {
    let status = false;
    let list = []
    this.leftVisibleTspList.forEach(tspList => {
      if (tspList.isSelected && !tspList.alreadyGrouped) {
        list.push(tspList)
      }
    });
    if (list.length && (this.leftVisibleTspList.length - this.selectedTspList.length) != list.length) {
      status = true;
    }
    if (list.length && (this.leftVisibleTspList.length - this.selectedTspList.length) == list.length) {
      this.select_all_setting = true;
    }
    else if (!list.length) {
      this.select_all_setting = false;
    }
    return status
  }

  checkShorlistedSelections(): boolean {
    if (this.selectedTspList == null) {
      return false;
    }
    let list = []
    this.selectedTspList.forEach(tspList => {
      if (tspList.moveToOriginalList) {
        list.push(tspList)
      }
    });
    if (list.length && this.selectedTspList.length != list.length) {
      return true;
    }
    if (list.length && this.selectedTspList.length == list.length) {
      this.select_all = true;
    }
    else if (!list.length) {
      this.select_all = false;
    }
    return this.selectedTspList.filter(tsp => tsp.moveToOriginalList).length > 0 && !this.select_all;
  }

  addToGroup() {
    this.select_all = false;
    this.select_all_setting = false;
    this.searchInput.get('inputValue').patchValue('')
    this.filteredTsp = this.leftVisibleTspList.filter(tsp => {
      return tsp.isSelected;
    })
    this.selectedTspList = this.filteredTsp.slice();
    this.setToShortlistedTsp()
  }

  setToShortlistedTsp() {
    this.searchInputGroup.get('inputValue').patchValue('')
    this.selectedTspList.forEach(selectedTsp => {
      let result = this.leftVisibleTspList.find(tsp => { return tsp.id === selectedTsp.id });
      if (result) {
        delete result.moveToOriginalList;
        result.alreadyGrouped = true;
      }
    });
  }

  removeFromGroup() {
    this.select_all = false;
    this.selectedTspList.forEach(tsp => {

      if (tsp.moveToOriginalList) {
        delete tsp.alreadyGrouped;
        delete tsp.isSelected;
        let result = this.leftVisibleTspList.find(tspData => { return tsp.id === tspData.id});
        if (result) {
          delete result.alreadyGrouped;
          delete result.isSelected;
        }
      }
    });
    this.deleteFromShortlistedList();
  }

  deleteFromShortlistedList() {
    let filteredTsp = this.selectedTspList.filter(tsp => {
      return !tsp.moveToOriginalList;
    })
    this.selectedTspList = filteredTsp;
  }

  cancel() {
    this.dialogRef.close();
  }

  cancelSearch(side) {
    if (side == 'LEFT') {
      this.searchInput.get('inputValue').patchValue('');
    } else if (side == 'RIGHT') {
      this.searchInputGroup.get('inputValue').patchValue('');
    }
  }

  submit() {
    this.dialogRef.close(this.selectedTspList);
  }

}
